import React from 'react';
import memoizeOne from 'memoize-one';
import { ApiResponseDataInputErrors } from '../../../../api/api-typed';
import { transFormError } from '../../../base/tools/translate-tools';
import { extractInputErrors } from '../../tools/input-errors-helper';

interface Props {
  path: string;
  inputErrors: ApiResponseDataInputErrors | null;
  render: (hasError: boolean) => React.ReactNode;
  customErrors?: string[];
}

class InputWithErrors extends React.Component<Props> {
  getErrors = memoizeOne(
    (path: string, inputErrors: ApiResponseDataInputErrors | null, customErrors?: string[]): string[] => {
      if (!inputErrors) {
        return customErrors || [];
      }

      const errors = extractInputErrors(path, inputErrors);
      if (!errors) {
        return customErrors || [];
      }

      if (customErrors) {
        return [...customErrors, ...Object.keys(errors)];
      }

      return Object.keys(errors);
    },
  );

  renderErrors = (errors: string[]): React.ReactNode => {
    if (errors.length > 0) {
      const text = transFormError(errors[0]);
      return <p className="invalid-feedback">{text}</p>;
    }

    return null;
  };

  render() {
    const { path, inputErrors, render, customErrors } = this.props;

    const errors = this.getErrors(path, inputErrors, customErrors);

    return (
      <>
        {render(errors.length > 0)}
        {this.renderErrors(errors)}
      </>
    );
  }
}

export default InputWithErrors;
