import React from 'react';
import { UserStateNotNull } from '../../auth/redux/user/user-state';
import { trans } from '../../base/tools/translate-tools';
import { renderCurrency } from '../../base/tools/currency-tools';
import {
  doesAreaSupportCard,
  doesAreaSupportMilesPayment,
  doesAreaSupportTelPayIn,
  doesAreaSupportThirdPartyPayIn,
} from '../../shared/tools/area-tools';
import { handleRadioChangeEvent } from '../../shared/tools/html-events-handler';
import { TradeInfoData } from '../../base/redux/trade/trade-state';
import iconKoosmikBalance from '../../../assets/images/icon-koosmik-balance.svg';
import iconCc from '../../../assets/images/icon-cc.svg';
import iconKoosmiles from '../../../assets/images/icon-koosmiles.svg';
import iconMm from '../../../assets/images/icon-mm.svg';

type PaymentMethod = 'koosmik' | 'miles' | 'card' | 'third-party' | 'tel';

interface Props {
  paymentMethod: PaymentMethod;
  user: UserStateNotNull;
  tradeInfo: TradeInfoData;
  onMethodChange: (method: PaymentMethod) => void;
}

export default class PaymentMethodSelect extends React.PureComponent<Props> {
  handleMethodChange = handleRadioChangeEvent(method => {
    const { onMethodChange } = this.props;
    onMethodChange(method as PaymentMethod);
  });

  renderKoosmikWallet() {
    const { paymentMethod, user, tradeInfo } = this.props;

    const walletAmount = user.wallets[tradeInfo.currency] || 0;

    if (tradeInfo.amount > walletAmount) {
      return (
        <div className="payment-method-item-wrapper">
          <input id="payment-method-koosmik" type="radio" name="paymentMethod" disabled />
          <label htmlFor="payment-method-koosmik" className="payment-method-item mb-3">
            <div className="icon">
              <img src={iconKoosmikBalance} alt="Koosmik balance" height="30" />
            </div>
            <div className="details">
              <div className="fs-7 text-kblue">
                {trans('dashboard.PaymentMethodSelect.myWallet')}
                <span className="text-danger ml-text">{trans('dashboard.PaymentMethodSelect.myWalletNotEnough')}</span>
              </div>
              <div className="fw-bolder fs-5">{renderCurrency(walletAmount, tradeInfo.currency)}</div>
            </div>
          </label>
        </div>
      );
    }

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-method-koosmik"
          type="radio"
          name="paymentMethod"
          value="koosmik"
          checked={paymentMethod === 'koosmik'}
          onChange={this.handleMethodChange}
        />
        <label htmlFor="payment-method-koosmik" className="payment-method-item mb-3">
          <div className="icon">
            <img src={iconKoosmikBalance} alt="Koosmik balance" height="30" />
          </div>
          <div className="details">
            <div className="fs-7 text-kblue">{trans('dashboard.PaymentMethodSelect.myWallet')}</div>
            <div className="fw-bolder fs-5">{renderCurrency(walletAmount, tradeInfo.currency)}</div>
          </div>
        </label>
      </div>
    );
  }

  renderKoosmiles() {
    const { paymentMethod, user, tradeInfo } = this.props;
    const { currency } = tradeInfo;

    if (!doesAreaSupportMilesPayment(tradeInfo.area)) {
      return null;
    }

    if (!user.miles || !user.miles.equivalent[currency]) {
      return null;
    }

    const equivalent = user.miles.equivalent[currency];
    if (tradeInfo.amount > equivalent) {
      return null;
    }

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-method-miles"
          type="radio"
          name="paymentMethod"
          value="miles"
          checked={paymentMethod === 'miles'}
          onChange={this.handleMethodChange}
        />
        <label htmlFor="payment-method-miles" className="payment-method-item mb-3">
          <div className="icon">
            <img src={iconKoosmiles} alt="Koosmik miles" height="30" />
          </div>
          <div className="details">
            <div className="fs-7 text-kblue">{trans('dashboard.PaymentMethodSelect.miles')}</div>
            <div className="fw-bolder fs-5">{user.miles.amount}</div>
          </div>
        </label>
      </div>
    );
  }

  renderCard() {
    const { tradeInfo, paymentMethod } = this.props;

    if (!doesAreaSupportCard(tradeInfo.area)) {
      return null;
    }

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-method-card"
          type="radio"
          name="paymentMethod"
          value="card"
          checked={paymentMethod === 'card'}
          onChange={this.handleMethodChange}
        />
        <label htmlFor="payment-method-card" className="payment-method-item mb-3">
          <div className="icon">
            <img src={iconCc} alt="Credit Card" width="30" />
          </div>
          <div className="details">
            <div className="fs-6 text-kblue">{trans('dashboard.PaymentMethodSelect.card')}</div>
          </div>
        </label>
      </div>
    );
  }

  renderThirdPartyPayIn() {
    const { tradeInfo, paymentMethod } = this.props;

    if (!doesAreaSupportThirdPartyPayIn(tradeInfo.area)) {
      return null;
    }

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-method-third-party"
          type="radio"
          name="paymentMethod"
          value="third-party"
          checked={paymentMethod === 'third-party'}
          onChange={this.handleMethodChange}
        />
        <label htmlFor="payment-method-third-party" className="payment-method-item mb-3">
          <div className="icon">
            <img src={iconCc} alt="Credit Card" width="30" />
          </div>
          <div className="details">
            <div className="fs-6 text-kblue">{trans('dashboard.PaymentMethodSelect.thirdParty')}</div>
          </div>
        </label>
      </div>
    );
  }

  renderTelPayIn() {
    const { tradeInfo, paymentMethod } = this.props;

    if (!doesAreaSupportTelPayIn(tradeInfo.area, tradeInfo.type)) {
      return null;
    }

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-method-tel"
          type="radio"
          name="paymentMethod"
          value="tel"
          checked={paymentMethod === 'tel'}
          onChange={this.handleMethodChange}
        />
        <label htmlFor="payment-method-tel" className="payment-method-item mb-3">
          <div className="icon">
            <img src={iconMm} alt="Mobile money" height="30" />
          </div>
          <div className="details">
            <div className="fs-6 text-kblue">{trans('dashboard.PaymentMethodSelect.tel')}</div>
          </div>
        </label>
      </div>
    );
  }

  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-12 my-3 text-center fw-bolder">{trans('dashboard.PaymentMethodSelect.chooseMethod')}</div>
        <div className="col-12">
          {this.renderKoosmikWallet()}

          {this.renderKoosmiles()}

          {this.renderCard()}

          {this.renderThirdPartyPayIn()}

          {this.renderTelPayIn()}
        </div>
      </div>
    );
  }
}
