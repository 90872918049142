import axios, { AxiosRequestConfig } from 'axios';

let authToken = '';
export const setAuthToken = (token: string) => {
  authToken = token;
};

const addAuth = (config: AxiosRequestConfig): AxiosRequestConfig => {
  let headers: { [key: string]: string } = {
    Authorization: authToken,
  };
  if (config.headers) {
    headers = {
      ...config.headers,
      headers,
    };
  }
  return {
    ...config,
    headers,
  };
};

const prependUrl = (path: string) => `${process.env.REACT_APP_KOOSMIK_API_URL}${path}`;

export const apiGet = <T>(path: string, config: AxiosRequestConfig = {}) =>
  axios.get<T>(prependUrl(path), config).then(response => response.data);

export const apiPost = <T>(path: string, data: object | null, config: AxiosRequestConfig = {}) =>
  axios.post<T>(prependUrl(path), data, config).then(response => response.data);

export const apiAuthGet = <T>(path: string, config: AxiosRequestConfig = {}) =>
  axios.get<T>(prependUrl(path), addAuth(config)).then(response => response.data);

export const apiAuthPost = <T>(path: string, data: object | null, config: AxiosRequestConfig = {}) =>
  axios.post<T>(prependUrl(path), data, addAuth(config)).then(response => response.data);
