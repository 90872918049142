import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import { handleFormSubmitEvent, handleInputChangeEvent } from '../../shared/tools/html-events-handler';
import { trans } from '../../base/tools/translate-tools';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import AuthState from '../redux/auth/auth-state';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import RouteNames from '../../../routes/RouteNames';
import { renderPhoneNumber } from '../../shared/tools/phone-tools';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import {
  authActionDirectLoginEmail,
  AuthActionDirectLoginEmailPayload,
  AuthActionDirectLoginEmailResolvePayload,
  authActionDirectLoginPhone,
  AuthActionDirectLoginPhonePayload,
  AuthActionDirectLoginPhoneResolvePayload,
} from '../redux/auth/auth-direct-actions';
import RootState from '../../../redux/root-state';
import { authSelect } from '../redux/auth/auth-selectors';
import {
  authActionClear,
  authActionLoginEmail,
  AuthActionLoginEmailPayload,
  AuthActionLoginEmailResolvePayload,
  authActionLoginPhone,
  AuthActionLoginPhonePayload,
  AuthActionLoginPhoneResolvePayload,
} from '../redux/auth/auth-actions';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

type AuthDirectLoginEmail = (
  payload: AuthActionDirectLoginEmailPayload,
) => Promise<AuthActionDirectLoginEmailResolvePayload>;
type AuthDirectLoginPhone = (
  payload: AuthActionDirectLoginPhonePayload,
) => Promise<AuthActionDirectLoginPhoneResolvePayload>;
type AuthLoginEmail = (payload: AuthActionLoginEmailPayload) => Promise<AuthActionLoginEmailResolvePayload>;
type AuthLoginPhone = (payload: AuthActionLoginPhonePayload) => Promise<AuthActionLoginPhoneResolvePayload>;
type AuthClear = () => void;

interface Props extends RouteComponentProps {
  authDirectLoginEmail: AuthDirectLoginEmail;
  authDirectLoginPhone: AuthDirectLoginPhone;
  authLoginEmail: AuthLoginEmail;
  authLoginPhone: AuthLoginPhone;
  auth: AuthState;
}

interface State {
  // form
  password: string;
  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
}

class AuthPasswordScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      password: '',
      inputErrors: null,
      isProcessing: false,
    };
  }

  handlePassword = handleInputChangeEvent((password: string) => this.setState({ password }));

  handleSubmit = handleFormSubmitEvent(() => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ isProcessing: true, inputErrors: null });

    const { auth, authDirectLoginEmail, authDirectLoginPhone } = this.props;
    const { password } = this.state;

    if (auth.type === 'email') {
      if (auth.email) {
        authDirectLoginEmail({ email: auth.email, password }).catch(
          actionRejectHandler(arg => {
            this.setState({
              isProcessing: false,
              inputErrors: arg.inputErrors || null,
            });
          }),
        );
      }
    } else if (auth.phone) {
      authDirectLoginPhone({ phone: auth.phone, password }).catch(
        actionRejectHandler(arg => {
          this.setState({
            isProcessing: false,
            inputErrors: arg.inputErrors || null,
          });
        }),
      );
    }
  });

  handleSendCode = handleFormSubmitEvent(() => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ isProcessing: true, inputErrors: null });

    const { auth, authLoginEmail, authLoginPhone } = this.props;

    if (auth.type === 'email') {
      if (auth.email) {
        authLoginEmail({ email: auth.email }).catch(
          actionRejectHandler(arg => {
            this.setState({
              isProcessing: false,
              inputErrors: arg.inputErrors || null,
            });
          }),
        );
      }
    } else if (auth.phone) {
      authLoginPhone({ phone: auth.phone }).catch(
        actionRejectHandler(arg => {
          this.setState({
            isProcessing: false,
            inputErrors: arg.inputErrors || null,
          });
        }),
      );
    }
  });

  handleBackClick = () => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    const { auth, history } = this.props;
    if (auth.type === 'email') {
      history.replace(RouteNames.authEmail);
    } else {
      history.replace(RouteNames.authPhone);
    }
  };

  renderPassword = (hasError: boolean) => {
    const { password, isProcessing } = this.state;

    return (
      <input
        type="password"
        id="password"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={password}
        onChange={this.handlePassword}
        disabled={isProcessing}
        required
      />
    );
  };

  render() {
    const { auth } = this.props;
    const { isProcessing, inputErrors } = this.state;

    let user = '';
    let sendText = '';
    if (auth.type === 'email') {
      user = auth.email || '';
      sendText = trans('auth.AuthPasswordScreen.sendCodeByMail');
    } else {
      if (auth.phone) {
        user = renderPhoneNumber(auth.phone.country, auth.phone.number);
      }
      sendText = trans('auth.AuthPasswordScreen.sendCodeBySms');
    }

    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="account" />

        <div className="modal-body">
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                <div className="d-flex flex-column align-items-center help-text mb-3">
                  <div className="text-center">
                    {trans('auth.AuthPasswordScreen.connectedAs')}
                    <strong>{user}</strong>
                  </div>
                  <button type="button" className="fs-7 link-kblue btn btn-link p-1" onClick={this.handleBackClick}>
                    {trans('auth.AuthPasswordScreen.notYou')}
                  </button>
                </div>
                <form id="auth-password" onSubmit={this.handleSubmit}>
                  <label htmlFor="password" className="form-label">
                    {trans('auth.AuthPasswordScreen.password')}
                  </label>
                  <InputWithErrors path="password" inputErrors={inputErrors} render={this.renderPassword} />
                </form>
                <div className="d-flex flex-column mt-2 help-text">
                  <button
                    type="button"
                    className="fs-7 link-kblue btn btn-link p-1"
                    onClick={this.handleSendCode}
                    disabled={isProcessing}
                  >
                    {sendText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button type="button" className="btn btn-link w-100" onClick={this.handleBackClick}>
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button type="submit" className="btn btn-koosmik w-100" disabled={isProcessing} form="auth-password">
                  <ButtonSpinner showSpinner={isProcessing}>{trans('auth.AuthPasswordScreen.submit')}</ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: authSelect(state),
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  authDirectLoginEmail: AuthDirectLoginEmail;
  authDirectLoginPhone: AuthDirectLoginPhone;
  authLoginEmail: AuthLoginEmail;
  authLoginPhone: AuthLoginPhone;
  authClear: AuthClear;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  authDirectLoginEmail: payload =>
    new Promise((resolve, reject) => dispatch(authActionDirectLoginEmail(payload, resolve, reject))),
  authDirectLoginPhone: payload =>
    new Promise((resolve, reject) => dispatch(authActionDirectLoginPhone(payload, resolve, reject))),
  authLoginEmail: payload => new Promise((resolve, reject) => dispatch(authActionLoginEmail(payload, resolve, reject))),
  authLoginPhone: payload => new Promise((resolve, reject) => dispatch(authActionLoginPhone(payload, resolve, reject))),
  authClear: () => authActionClear(),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthPasswordScreen);
