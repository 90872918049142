const RouteNames = {
  none: '/',

  redirectSuccess: '/redirectSuccess',
  redirectFailure: '/redirectFailure',
  redirect3dSecure: '/redirect3dSecure',
  redirect3rdParty: '/redirect3rdParty',

  cart: '/cart',

  authEmail: '/authEmail',
  authPhone: '/authPhone',
  authValidation: '/authValidation',
  authPassword: '/authPassword',

  dashboardHome: '/dashboardHome',

  directPay: '/directPay',

  register: '/register',
  registerPhoneValidation: '/register/phone-code',
  registerAfri: '/register/afri',

  noAccount: '/no-account',
};

export default RouteNames;
