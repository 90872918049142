import { ApiDefaultResponse } from './api-typed';
import { apiAuthGet } from './api-call';

// Get info data

interface ApiInfoResponseData {
  displayName: string;
  image: {
    small: string;
    medium: string;
    large: string;
  } | null;
  wallets: Array<{ currency: string; amount: string }>;
  email: string;
  miles?: {
    amount: number;
    equivalent: {
      [key: string]: number;
    };
    conversion: {
      [key: string]: [number, number];
    };
  };
}

export interface ApiInfoResponse extends ApiDefaultResponse<ApiInfoResponseData> {}

export const apiInfo = (): Promise<ApiInfoResponse> => apiAuthGet<ApiInfoResponse>('embedded/v1/info');
