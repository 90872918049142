import React from 'react';

export const handleInputChangeEvent = (callback: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
  callback(e.target.value);

export const handleSelectChangeEvent = (callback: (value: string) => void) => (
  e: React.ChangeEvent<HTMLSelectElement>,
) => callback(e.target.value);

export const handleRadioChangeEvent = (callback: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) =>
  callback(e.target.value);

export const handleFormSubmitEvent = (callback: () => void) => (e: React.FormEvent) => {
  e.preventDefault();
  callback();
};
