import { ApiDefaultResponse, ApiOtherMember } from './api-typed';
import { apiAuthPost, apiGet } from './api-call';
import { TYPE_AREA } from '../modules/shared/tools/area-tools';

// payment details

export interface ApiPaymentDetailsResponseData {
  id: string;
  amount: string;
  currency: string;
  area: TYPE_AREA;
  label: string;
  otherMember: ApiOtherMember;
  allowNoAccount: boolean;
  payerPrefillInfo: {
    birthday?: string;
    countryOfResidence?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    nationality?: string;
  };
  status: 'paid' | 'failed' | 'pending' | 'processing' | 'expired';

  // payment successful
  successRedirectUrl?: string;

  // payment failed
  failureRedirectUrl?: string;

  // payment cancel
  cancelRedirectUrl?: string;
}

export interface ApiPaymentDetailsResponse extends ApiDefaultResponse<ApiPaymentDetailsResponseData> {}

export const apiPaymentDetails = (id: string): Promise<ApiPaymentDetailsResponse> =>
  apiGet<ApiPaymentDetailsResponse>(`embedded/v1/payments/payment/${id}`);

// pay with wallet

export interface ApiPaymentPayResponseData {
  // payment successful
  successRedirectUrl?: string;

  // payment failed
  failureRedirectUrl?: string;

  // payment cancel
  cancelRedirectUrl?: string;

  // error Params for limit
  limitParams?: {
    amount?: number;
    currency?: string;
    limit?: number;
  };
}

export interface ApiPaymentPayResponse extends ApiDefaultResponse<ApiPaymentPayResponseData> {}

export const apiPaymentPay = (id: string): Promise<ApiPaymentPayResponse> =>
  apiAuthPost<ApiPaymentPayResponse>(`embedded/v1/payments/pay/${id}`, {});

// pay with miles

export interface ApiPaymentPayMilesResponseData {
  // payment successful
  successRedirectUrl?: string;

  // payment failed
  failureRedirectUrl?: string;

  // payment cancel
  cancelRedirectUrl?: string;

  // error Params for limit
  limitParams?: {
    amount?: number;
    currency?: string;
    limit?: number;
  };
}

export interface ApiPaymentPayMilesResponse extends ApiDefaultResponse<ApiPaymentPayMilesResponseData> {}

export const apiPaymentPayMiles = (id: string): Promise<ApiPaymentPayMilesResponse> =>
  apiAuthPost<ApiPaymentPayMilesResponse>(`embedded/v1/payments/pay-miles/${id}`, {});

// pay with a card

export interface ApiPaymentPayWithCardPayload {
  id: string;
  card: string;
  cardPayInAmount: string;
}

export interface ApiPaymentPayWithCardResponseData {
  // payment successful
  successRedirectUrl?: string;

  // payment failed
  failureRedirectUrl?: string;

  // payment cancel
  cancelRedirectUrl?: string;

  // 3d secure
  redirectUrl?: string;
}

export interface ApiPaymentPayWithCardResponse extends ApiDefaultResponse<ApiPaymentPayWithCardResponseData> {}

export const apiPaymentPayWithCard = ({
  id,
  ...data
}: ApiPaymentPayWithCardPayload): Promise<ApiPaymentPayWithCardResponse> =>
  apiAuthPost<ApiPaymentPayWithCardResponse>(`embedded/v1/payments/pay-in/${id}`, data);

// pay third party

export interface ApiPaymentPayWithThirdPartyPayload {
  id: string;
}

export interface ApiPaymentPayWithThirdPartyResponseData {
  redirectUrl: string;
}

export interface ApiPaymentPayWithThirdPartyResponse
  extends ApiDefaultResponse<ApiPaymentPayWithThirdPartyResponseData> {}

export const apiPaymentPayWithThirdParty = ({
  id,
  ...data
}: ApiPaymentPayWithThirdPartyPayload): Promise<ApiPaymentPayWithThirdPartyResponse> =>
  apiAuthPost<ApiPaymentPayWithThirdPartyResponse>(`embedded/v1/payments/pay-in/3rd-party/${id}`, data);

// pay with tel

export interface ApiPaymentPayWithTelPayload {
  id: string;
}

export interface ApiPaymentPayWithTelResponseData {
  redirectUrl: string;
}

export interface ApiPaymentPayWithTelResponse extends ApiDefaultResponse<ApiPaymentPayWithTelResponseData> {}

export const apiPaymentPayWithTel = ({
  id,
  ...data
}: ApiPaymentPayWithTelPayload): Promise<ApiPaymentPayWithTelResponse> =>
  apiAuthPost<ApiPaymentPayWithTelResponse>(`embedded/v1/payments/pay-in/tel/${id}`, data);
