import { AppInputStateNotNull } from './app-input-state';
import { ActionReject, ActionResolve } from '../../tools/action-promise-types';
import { TradeStateNotNull } from '../trade/trade-state';

// Action Types

export const APP_INPUT_ACTION_SET = 'APP_INPUT_ACTION_SET';
export const APP_INPUT_ACTION_CLEAR = 'APP_INPUT_ACTION_CLEAR';

// Action creators definitions

export interface AppInputActionSet {
  type: typeof APP_INPUT_ACTION_SET;
  payload: AppInputStateNotNull;
  resolve: ActionResolve<TradeStateNotNull>;
  reject: ActionReject;
}

export interface AppInputActionClear {
  type: typeof APP_INPUT_ACTION_CLEAR;
}

export type AppInputActions = AppInputActionSet | AppInputActionClear;

// Action creators

export const appInputActionSet = (
  payload: AppInputStateNotNull,
  resolve: ActionResolve<TradeStateNotNull>,
  reject: ActionReject,
): AppInputActionSet => ({
  type: APP_INPUT_ACTION_SET,
  payload,
  resolve,
  reject,
});

export const appInputActionClear = (): AppInputActionClear => ({
  type: APP_INPUT_ACTION_CLEAR,
});
