import { addMessageListener, MessagingEvents, postMessage } from '../tools/messaging';

type InitializeData = { id: string; locale: string; type: 'request' | 'payment'; options: string };
type InitializeListener = (data: InitializeData) => void;

/**
 * Class to receive and send pre-processed post messages
 */
export class MessagingService {
  constructor() {
    addMessageListener(message => {
      if (message.key === MessagingEvents.initialize) {
        this.initializeListeners.forEach(listener => {
          listener(message.data as InitializeData);
        });
      }
    });
  }

  initializeListeners: InitializeListener[] = [];

  onInitialize = (fn: InitializeListener) => this.initializeListeners.push(fn);

  notifyAppIsReady = () => {
    postMessage({
      key: 'ready',
      success: true,
      data: '',
    });
  };

  notifyAppClose = () => {
    postMessage({
      key: 'close',
      success: true,
      data: {},
    });
  };

  notifyRedirect = (url: string, to: 'merchant' | '3Dsecure' | '3rd-party') => {
    postMessage({
      key: 'redirect',
      success: true,
      data: {
        url,
        to,
      },
    });
  };
}
