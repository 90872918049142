import { ApiDefaultResponse, ApiResponseDataInputErrors } from './api-typed';
import { apiPost } from './api-call';

export interface ApiAuthDirectCheckResponseData {
  inputErrors?: ApiResponseDataInputErrors;
}

export interface ApiAuthDirectCheckResponse extends ApiDefaultResponse<ApiAuthDirectCheckResponseData> {}

export interface ApiAuthDirectLoginResponseData {
  token: string;
  inputErrors?: ApiResponseDataInputErrors;
}

export interface ApiAuthDirectLoginResponse extends ApiDefaultResponse<ApiAuthDirectLoginResponseData> {}

// Check email

interface ApiAuthDirectCheckEmailPayload {
  email: string;
}

export const apiAuthDirectCheckEmail = (payload: ApiAuthDirectCheckEmailPayload): Promise<ApiAuthDirectCheckResponse> =>
  apiPost<ApiAuthDirectCheckResponse>('embedded/v1/login-direct/email-check', payload);

// Login email + password

interface ApiAuthDirectLoginEmailPayload {
  email: string;
  password: string;
}

export const apiAuthDirectLoginEmail = (payload: ApiAuthDirectLoginEmailPayload): Promise<ApiAuthDirectLoginResponse> =>
  apiPost<ApiAuthDirectLoginResponse>('embedded/v1/login-direct/email', payload);

// Check phone

interface ApiAuthDirectCheckPhonePayload {
  phoneCountry: string;
  phoneNumber: string;
}

export const apiAuthDirectCheckPhone = (payload: ApiAuthDirectCheckPhonePayload): Promise<ApiAuthDirectCheckResponse> =>
  apiPost<ApiAuthDirectCheckResponse>('embedded/v1/login-direct/phone-check', payload);

// Login phone + password

interface ApiAuthDirectLoginPhonePayload {
  phoneCountry: string;
  phoneNumber: string;
  password: string;
}

export const apiAuthDirectLoginPhone = (payload: ApiAuthDirectLoginPhonePayload): Promise<ApiAuthDirectLoginResponse> =>
  apiPost<ApiAuthDirectLoginResponse>('embedded/v1/login-direct/phone', payload);
