import { TradeStateNotNull } from './trade-state';

// Action Types

export const TRADE_ACTION_SET = 'TRADE_ACTION_SET';
export const TRADE_ACTION_CLEAR = 'TRADE_ACTION_CLEAR';

// Action creators definitions

export interface TradeActionSet {
  type: typeof TRADE_ACTION_SET;
  payload: TradeStateNotNull;
}

export interface TradeActionClear {
  type: typeof TRADE_ACTION_CLEAR;
}

export type TradeActions = TradeActionSet | TradeActionClear;

// Action creators

export const tradeActionSet = (payload: TradeStateNotNull): TradeActionSet => ({
  type: TRADE_ACTION_SET,
  payload,
});

export const tradeActionClear = (): TradeActionClear => ({
  type: TRADE_ACTION_CLEAR,
});
