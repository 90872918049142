import { ActionReject, ActionResolve } from '../../../base/tools/action-promise-types';
import { ApiAuthDirectLoginResponseData } from '../../../../api/auth-direct';

// Action Types

export const AUTH_ACTION_DIRECT_CHECK_EMAIL = 'AUTH_ACTION_DIRECT_CHECK_EMAIL';
export const AUTH_ACTION_DIRECT_LOGIN_EMAIL = 'AUTH_ACTION_DIRECT_LOGIN_EMAIL';
export const AUTH_ACTION_DIRECT_CHECK_PHONE = 'AUTH_ACTION_DIRECT_CHECK_PHONE';
export const AUTH_ACTION_DIRECT_LOGIN_PHONE = 'AUTH_ACTION_DIRECT_LOGIN_PHONE';

// Action creators definitions

// -- check email

export interface AuthActionDirectCheckEmailPayload {
  email: string;
}

export interface AuthActionDirectCheckEmail {
  type: typeof AUTH_ACTION_DIRECT_CHECK_EMAIL;
  payload: AuthActionDirectCheckEmailPayload;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- login email

export interface AuthActionDirectLoginEmailPayload {
  email: string;
  password: string;
}

export interface AuthActionDirectLoginEmailResolvePayload {
  data: ApiAuthDirectLoginResponseData;
}

export interface AuthActionDirectLoginEmail {
  type: typeof AUTH_ACTION_DIRECT_LOGIN_EMAIL;
  payload: AuthActionDirectLoginEmailPayload;
  resolve: ActionResolve<AuthActionDirectLoginEmailResolvePayload>;
  reject: ActionReject;
}

// -- check phone

export interface AuthActionDirectCheckPhonePayload {
  phone: {
    country: string;
    number: string;
  };
}

export interface AuthActionDirectCheckPhone {
  type: typeof AUTH_ACTION_DIRECT_CHECK_PHONE;
  payload: AuthActionDirectCheckPhonePayload;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- login phone

export interface AuthActionDirectLoginPhonePayload {
  phone: {
    country: string;
    number: string;
  };
  password: string;
}

export interface AuthActionDirectLoginPhoneResolvePayload {
  data: ApiAuthDirectLoginResponseData;
}

export interface AuthActionDirectLoginPhone {
  type: typeof AUTH_ACTION_DIRECT_LOGIN_PHONE;
  payload: AuthActionDirectLoginPhonePayload;
  resolve: ActionResolve<AuthActionDirectLoginPhoneResolvePayload>;
  reject: ActionReject;
}

// -- enum

export type AuthActionsDirect =
  | AuthActionDirectCheckEmail
  | AuthActionDirectLoginEmail
  | AuthActionDirectCheckPhone
  | AuthActionDirectLoginPhone;

// Action creators

export const authActionDirectCheckEmail = (
  payload: AuthActionDirectCheckEmailPayload,
  resolve: ActionResolve,
  reject: ActionReject,
): AuthActionDirectCheckEmail => ({
  type: AUTH_ACTION_DIRECT_CHECK_EMAIL,
  payload,
  resolve,
  reject,
});

export const authActionDirectLoginEmail = (
  payload: AuthActionDirectLoginEmailPayload,
  resolve: ActionResolve<AuthActionDirectLoginEmailResolvePayload>,
  reject: ActionReject,
): AuthActionDirectLoginEmail => ({
  type: AUTH_ACTION_DIRECT_LOGIN_EMAIL,
  payload,
  resolve,
  reject,
});

export const authActionDirectCheckPhone = (
  payload: AuthActionDirectCheckPhonePayload,
  resolve: ActionResolve,
  reject: ActionReject,
): AuthActionDirectCheckPhone => ({
  type: AUTH_ACTION_DIRECT_CHECK_PHONE,
  payload,
  resolve,
  reject,
});

export const authActionDirectLoginPhone = (
  payload: AuthActionDirectLoginPhonePayload,
  resolve: ActionResolve<AuthActionDirectLoginPhoneResolvePayload>,
  reject: ActionReject,
): AuthActionDirectLoginPhone => ({
  type: AUTH_ACTION_DIRECT_LOGIN_PHONE,
  payload,
  resolve,
  reject,
});
