import React from 'react';
import { trans } from '../../base/tools/translate-tools';
import { doesAreaSupportTelPayIn, doesAreaSupportThirdPartyPayIn } from '../../shared/tools/area-tools';
import { handleRadioChangeEvent } from '../../shared/tools/html-events-handler';
import { TradeInfoData } from '../../base/redux/trade/trade-state';
import iconCc from '../../../assets/images/icon-cc.svg';
import iconMm from '../../../assets/images/icon-mm.svg';

interface Props {
  paymentMethod: 'third-party' | 'tel';
  tradeInfo: TradeInfoData;
  onMethodChange: (method: 'third-party' | 'tel') => void;
}

export default class PaymentMethodSelect extends React.PureComponent<Props> {
  handleMethodChange = handleRadioChangeEvent(method => {
    const { onMethodChange } = this.props;
    if (method === 'third-party' || method === 'tel') {
      onMethodChange(method);
    }
  });

  renderThirdPartyPayIn() {
    const { tradeInfo, paymentMethod } = this.props;

    if (!doesAreaSupportThirdPartyPayIn(tradeInfo.area)) {
      return null;
    }

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-method-third-party"
          type="radio"
          name="paymentMethod"
          value="third-party"
          checked={paymentMethod === 'third-party'}
          onChange={this.handleMethodChange}
        />
        <label htmlFor="payment-method-third-party" className="payment-method-item mb-3">
          <div className="icon">
            <img src={iconCc} alt="Credit Card" width="30" />
          </div>
          <div className="details">
            <div className="fs-6 text-kblue">{trans('dashboard.PaymentMethodSelect.thirdParty')}</div>
          </div>
        </label>
      </div>
    );
  }

  renderTelPayIn() {
    const { tradeInfo, paymentMethod } = this.props;

    if (!doesAreaSupportTelPayIn(tradeInfo.area, tradeInfo.type)) {
      return null;
    }

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-method-tel"
          type="radio"
          name="paymentMethod"
          value="tel"
          checked={paymentMethod === 'tel'}
          onChange={this.handleMethodChange}
        />
        <label htmlFor="payment-method-tel" className="payment-method-item mb-3">
          <div className="icon">
            <img src={iconMm} alt="Mobile money" height="30" />
          </div>
          <div className="details">
            <div className="fs-6 text-kblue">{trans('dashboard.PaymentMethodSelect.tel')}</div>
          </div>
        </label>
      </div>
    );
  }

  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-12 my-3 text-center fw-bolder">{trans('dashboard.PaymentMethodSelect.chooseMethod')}</div>
        <div className="col-12">
          {this.renderThirdPartyPayIn()}

          {this.renderTelPayIn()}
        </div>
      </div>
    );
  }
}
