import React from 'react';
import SuccessEmbedded from '../components/SuccessEmbedded';
import { trans } from '../tools/translate-tools';

export default class Redirect3dSecureScreen extends React.PureComponent {
  render() {
    return (
      <SuccessEmbedded
        title={trans('base.Redirect3dSecureScreen.title')}
        message={trans('base.Redirect3dSecureScreen.message')}
      />
    );
  }
}
