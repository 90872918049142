import { fork } from 'redux-saga/effects';
import routerSaga from '../modules/base/redux/router/router-saga';
import appInputSaga from '../modules/base/redux/app-input/app-input-saga';
import authSaga from '../modules/auth/redux/auth/auth-saga';
import registerSaga from '../modules/register/redux/register/register-saga';
import directPaySaga from '../modules/direct-pay/redux/direct-pay/direct-pay-saga';
import paySaga from '../modules/dashboard/redux/pay/pay-saga';
import noAccountPaySaga from '../modules/no-account/redux/pay/no-account-pay-saga';
import cardsSaga from '../modules/auth/redux/cards/cards-saga';

export default function* rootSagas() {
  yield fork(routerSaga);
  yield fork(appInputSaga);
  yield fork(authSaga);
  yield fork(registerSaga);
  yield fork(directPaySaga);
  yield fork(paySaga);
  yield fork(noAccountPaySaga);
  yield fork(cardsSaga);
}
