import TradeState from './trade-state';
import { TRADE_ACTION_CLEAR, TRADE_ACTION_SET, TradeActions } from './trade-actions';

const initialState: TradeState = null;

export default function tradeReducer(state: TradeState = initialState, action: TradeActions): TradeState {
  switch (action.type) {
    case TRADE_ACTION_SET:
      return action.payload;
    case TRADE_ACTION_CLEAR:
      return initialState;
    default:
      return state;
  }
}
