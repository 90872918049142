import AuthState from './auth-state';
import {
  AUTH_ACTION_CLEAR,
  AUTH_ACTION_LOGIN_EMAIL,
  AUTH_ACTION_LOGIN_PHONE,
  AUTH_ACTION_LOGIN_SET_VALIDATE_DATA,
  AUTH_ACTION_SET_TOKEN,
  AuthActions,
} from './auth-actions';
import { AUTH_ACTION_DIRECT_CHECK_EMAIL, AUTH_ACTION_DIRECT_CHECK_PHONE } from './auth-direct-actions';

const initialState: AuthState = { type: null, email: null, phone: null, validation: null, token: null };

export default function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AUTH_ACTION_LOGIN_EMAIL:
      return {
        ...initialState,
        type: 'email',
        ...action.payload,
      };
    case AUTH_ACTION_LOGIN_PHONE:
      return {
        ...initialState,
        type: 'phone',
        ...action.payload,
      };
    case AUTH_ACTION_LOGIN_SET_VALIDATE_DATA:
      return {
        ...state,
        validation: action.payload,
      };
    case AUTH_ACTION_SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case AUTH_ACTION_DIRECT_CHECK_EMAIL:
      return {
        ...state,
        type: 'email',
        ...action.payload,
      };
    case AUTH_ACTION_DIRECT_CHECK_PHONE:
      return {
        ...state,
        type: 'phone',
        ...action.payload,
      };
    case AUTH_ACTION_CLEAR:
      return initialState;
    default:
      return state;
  }
}
