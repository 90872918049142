import React from 'react';
import classNames from 'classnames';
import { trans } from '../../base/tools/translate-tools';
import { handleFormSubmitEvent, handleInputChangeEvent } from '../../shared/tools/html-events-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import { filterNotDigit } from '../../shared/tools/input-filters';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import iconMastercard from '../../../assets/images/icon-mastercard.svg';
import iconVisa from '../../../assets/images/icon-visa.svg';
import { isCardNumberLikeMastercard, isCardNumberLikeVisa } from '../../shared/tools/card-tools';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

export interface CardInfoData {
  cardNumber: string;
  cardCvx: string;
  cardExpirationDate: string;
}

interface Props {
  isProcessing: boolean;
  initialValues: CardInfoData | null;
  inputErrors: ApiResponseDataInputErrors | null;
  onSubmit: (data: CardInfoData) => void;
  onBackClick: (data: CardInfoData) => void;
}

interface State extends CardInfoData {}

export default class CardInfoForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cardNumber: '',
      cardCvx: '',
      cardExpirationDate: '',
    };

    const { initialValues } = this.props;
    if (initialValues) {
      this.state = {
        ...this.state,
        ...initialValues,
      };
    }
  }

  handleCardNumber = handleInputChangeEvent((cardNumber: string) =>
    this.setState({ cardNumber: filterNotDigit(cardNumber) }),
  );

  handleCardCvx = handleInputChangeEvent((cardCvx: string) => this.setState({ cardCvx: filterNotDigit(cardCvx) }));

  handleCardExpirationDate = handleInputChangeEvent((cardExpirationDate: string) =>
    this.setState({ cardExpirationDate: filterNotDigit(cardExpirationDate) }),
  );

  handleSubmit = handleFormSubmitEvent(() => {
    const { onSubmit } = this.props;
    const { cardCvx, cardExpirationDate, cardNumber } = this.state;
    onSubmit({
      cardCvx,
      cardExpirationDate,
      cardNumber,
    });
  });

  handleBack = () => {
    const { onBackClick } = this.props;
    const { cardCvx, cardExpirationDate, cardNumber } = this.state;
    onBackClick({ cardCvx, cardExpirationDate, cardNumber });
  };

  renderCardNumber = (hasError: boolean) => {
    const { cardNumber } = this.state;

    let cardIcon = null;
    if (isCardNumberLikeVisa(cardNumber)) {
      cardIcon = <img className="current" src={iconVisa} height="11" alt="Visa" />;
    } else if (isCardNumberLikeMastercard(cardNumber)) {
      cardIcon = <img className="current" src={iconMastercard} height="13" alt="Mastercard" />;
    }

    return (
      <>
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          id="card-number"
          className={classNames('form-control', { 'is-invalid': hasError })}
          value={cardNumber}
          onChange={this.handleCardNumber}
          required
        />
        <div className="cc-input-brands d-flex align-items-center">{cardIcon}</div>
      </>
    );
  };

  renderCardExpirationDate = (hasError: boolean) => {
    const { cardExpirationDate } = this.state;

    return (
      <input
        type="text"
        placeholder={trans('directPay.CardInfoForm.cardExpirationDatePlaceholder')}
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={cardExpirationDate}
        onChange={this.handleCardExpirationDate}
        maxLength={4}
        required
      />
    );
  };

  renderCardCvx = (hasError: boolean) => {
    const { cardCvx } = this.state;

    return (
      <input
        type="text"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={cardCvx}
        onChange={this.handleCardCvx}
        maxLength={3}
        required
      />
    );
  };

  render() {
    const { isProcessing, inputErrors } = this.props;

    return (
      <>
        <div className="modal-body">
          <h2 className="modal-heading text-center p-2 mb-3">{trans('directPay.CardInfoForm.title')}</h2>
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />

                <form onSubmit={this.handleSubmit} id="direct-pay-card-info">
                  <div className="mb-3 cc-input-wrapper">
                    <label htmlFor="card-number" className="form-label">
                      {trans('directPay.CardInfoForm.cardNumber')}
                    </label>
                    <InputWithErrors path="cardNumber" inputErrors={inputErrors} render={this.renderCardNumber} />
                  </div>

                  <div className="row g-3 mb-3">
                    <div className="col12 col-sm-6">
                      <label htmlFor="card-expiration" className="form-label">
                        {trans('directPay.CardInfoForm.cardExpirationDate')}
                      </label>
                      <InputWithErrors
                        path="cardExpirationDate"
                        inputErrors={inputErrors}
                        render={this.renderCardExpirationDate}
                      />
                    </div>
                    <div className="col12 col-sm-6">
                      <label htmlFor="card-code" className="form-label">
                        {trans('directPay.CardInfoForm.cardCvx')}
                      </label>
                      <InputWithErrors path="cardCvx" inputErrors={inputErrors} render={this.renderCardCvx} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button type="button" className="btn btn-link w-100" onClick={this.handleBack}>
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button
                  type="submit"
                  className="btn btn-koosmik w-100"
                  disabled={isProcessing}
                  form="direct-pay-card-info"
                >
                  <ButtonSpinner showSpinner={isProcessing}>{trans('directPay.CardInfoForm.submit')}</ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
