import React from 'react';
import classNames from 'classnames';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import { handleFormSubmitEvent, handleInputChangeEvent } from '../../shared/tools/html-events-handler';
import { trans } from '../../base/tools/translate-tools';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import AuthState from '../redux/auth/auth-state';
import RootState from '../../../redux/root-state';
import { authSelect } from '../redux/auth/auth-selectors';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import RouteNames from '../../../routes/RouteNames';
import { TradeStateNotNull } from '../../base/redux/trade/trade-state';
import { tradeSelect } from '../../base/redux/trade/trade-selectors';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import { doesAreaSupportNoAccount } from '../../shared/tools/area-tools';
import { authActionDirectCheckEmail, AuthActionDirectCheckEmailPayload } from '../redux/auth/auth-direct-actions';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

type AuthDirectCheckEmail = (payload: AuthActionDirectCheckEmailPayload) => Promise<{}>;

interface Props {
  authDirectCheckEmail: AuthDirectCheckEmail;
  auth: AuthState;
  trade: TradeStateNotNull;
}

interface State {
  // form
  email: string;
  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
}

class AuthEmailScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { auth, trade } = this.props;

    let email = '';
    if (trade.type === 'payment') {
      const { payerPrefillInfo } = trade.payment;
      if (payerPrefillInfo.email) {
        email = payerPrefillInfo.email;
      }
    }

    this.state = {
      email: auth.email || email,
      inputErrors: null,
      isProcessing: false,
    };
  }

  handleEmail = handleInputChangeEvent((email: string) => this.setState({ email }));

  handleSubmit = handleFormSubmitEvent(() => {
    const { authDirectCheckEmail } = this.props;
    const { email, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ isProcessing: true, inputErrors: null });

    authDirectCheckEmail({ email }).catch(
      actionRejectHandler(arg => {
        this.setState({
          isProcessing: false,
          inputErrors: arg.inputErrors || null,
        });
      }),
    );
  });

  renderEmail = (hasError: boolean) => {
    const { email, isProcessing } = this.state;

    return (
      <input
        type="email"
        id="email"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={email}
        disabled={isProcessing}
        onChange={this.handleEmail}
        required
      />
    );
  };

  renderNoAccount() {
    const { trade } = this.props;

    if (trade.type === 'payment' && trade.payment.allowNoAccount && doesAreaSupportNoAccount(trade.payment.area)) {
      return (
        <div className="no-account modal-body">
          <div className="no-account-divider">
            <span className="divider-line" />
            <span className="divider-text">{trans('auth.AuthEmailScreen.noAccountOr')}</span>
            <span className="divider-line" />
          </div>
          <Link to={RouteNames.noAccount} className="btn w-100 btn-primary">
            {trans('auth.AuthPhoneScreen.noAccount')}
          </Link>
        </div>
      );
    }

    return null;
  }

  render() {
    const { isProcessing, inputErrors } = this.state;

    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="account" />
        <div className="modal-body">
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                <div className="d-flex justify-content-end">
                  <Link to={RouteNames.authPhone} replace className="fs-7 link-kblue">
                    {trans('auth.AuthEmailScreen.byPhone')}
                  </Link>
                </div>
                <form onSubmit={this.handleSubmit} id="auth-email-form">
                  <label className="form-label" htmlFor="email">
                    {trans('auth.AuthEmailScreen.email')}
                  </label>
                  <InputWithErrors path="email" inputErrors={inputErrors} render={this.renderEmail} />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <Link className="btn btn-link w-100" to={RouteNames.cart}>
                  {trans('global.back')}
                </Link>
              </div>
              <div className="col-7 col-sm-6">
                <button type="submit" className="btn btn-koosmik w-100" disabled={isProcessing} form="auth-email-form">
                  <ButtonSpinner showSpinner={isProcessing}>{trans('auth.AuthPhoneScreen.next')}</ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.renderNoAccount()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: authSelect(state),
  trade: tradeSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  authDirectCheckEmail: AuthDirectCheckEmail;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  authDirectCheckEmail: payload =>
    new Promise((resolve, reject) => dispatch(authActionDirectCheckEmail(payload, resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthEmailScreen);
