import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import RootState from '../../../redux/root-state';
import { TradeInfoData } from '../redux/trade/trade-state';
import { tradeInfoSelect } from '../redux/trade/trade-selectors';
import PaymentStepsComponent from '../components/PaymentStepsComponent';
import { renderCurrency } from '../tools/currency-tools';
import RouteNames from '../../../routes/RouteNames';
import { prioritizeEmailLogin } from '../../shared/tools/area-tools';
import { trans } from '../tools/translate-tools';
import { AppInputStateNotNull } from '../redux/app-input/app-input-state';
import { appInputActionClear } from '../redux/app-input/app-input-actions';
import { appInputSelect } from '../redux/app-input/app-input-selectors';

type AppInputClear = () => void;

interface Props extends RouteComponentProps {
  appInputState: AppInputStateNotNull;
  appInputClear: AppInputClear;
  tradeInfo: TradeInfoData;
}

class CartScreen extends React.PureComponent<Props> {
  onClose = () => {
    const { appInputClear } = this.props;
    appInputClear();
  };

  goNext = () => {
    const { history, tradeInfo } = this.props;

    if (prioritizeEmailLogin(tradeInfo.area)) {
      history.push(RouteNames.authEmail);
    } else {
      history.push(RouteNames.authPhone);
    }
  };

  renderClose() {
    const { appInputState } = this.props;

    if (appInputState.options.noClose) {
      return null;
    }

    return (
      <button type="button" className="btn btn-link w-100" data-bs-dismiss="modal" onClick={this.onClose}>
        {trans('base.CartScreen.cancel')}
      </button>
    );
  }

  render() {
    const { tradeInfo } = this.props;

    return (
      <>
        <PaymentStepsComponent step="cart" />
        <div className="modal-body">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-8">
                <div className="panel text-center">
                  <div className="cart">
                    <div className="label">{trans('base.CartScreen.amountToPay')}</div>
                    <div className="amount">{renderCurrency(tradeInfo.amount, tradeInfo.currency)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">{this.renderClose()}</div>
              <div className="col-7 col-sm-6">
                <button type="button" className="btn btn-koosmik w-100" onClick={this.goNext}>
                  {trans('base.CartScreen.submit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  appInputState: appInputSelect(state)!,
  tradeInfo: tradeInfoSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  appInputClear: AppInputClear;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  appInputClear: () => dispatch(appInputActionClear()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartScreen);
