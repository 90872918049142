export default {
  isEmpty: 'Une valeur est requise ici',

  emailAddressInvalid: "L'adresse email n'est pas valide",

  dateInvalidDate: 'La date est invalide',
  dateTooYoung: 'Vous devez être majeur pour pouvoir vous inscrire',

  creditcardPrefix: "L'entrée contient un nombre invalide de chiffres",
  creditcardLength: 'Le numero de carte est trop court',
  creditcardContent: "L'entrée semble être un numéro de carte bancaire invalide",
  cardExpirationDateInvalid: "La date d'expiration est invalide",
  notDigits: 'Ce champ ne doit contenir que des chiffres',

  passwordTooSimple: 'Le mot de passe est trop simple. Veillez utiliser des chiffres et au moins un caractère spécial',
  passwordTooShort: 'Le mot de passe est trop court',

  stringLengthTooShort: 'Trop court',
};
