import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { trans } from '../../base/tools/translate-tools';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import RouteNames from '../../../routes/RouteNames';
import { registerActionAddPhoneNumber } from '../redux/register/register-actions';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import RootState from '../../../redux/root-state';
import { registerSelect } from '../redux/register/register-selectors';
import RegisterState from '../redux/register/register-state';
import { renderPhoneNumberObstructed } from '../../shared/tools/phone-tools';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

type RegisterAddPhoneNumber = () => Promise<{}>;

interface Props extends RouteComponentProps {
  register: RegisterState;
  registerAddPhoneNumber: RegisterAddPhoneNumber;
}

interface State {
  // processing
  isProcessing: boolean;
}

class RegisterInfoScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isProcessing: false,
    };
  }

  handleOkClick = () => {
    const { registerAddPhoneNumber } = this.props;
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ isProcessing: true });

    registerAddPhoneNumber().catch(
      actionRejectHandler(() => {
        this.setState({
          isProcessing: false,
        });
      }),
    );
  };

  handleBackClick = () => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    const { history } = this.props;
    history.replace(RouteNames.authPhone);
  };

  render() {
    const { isProcessing } = this.state;
    const { register } = this.props;

    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="account" />
        <div className="modal-body">
          <h2 className="modal-heading text-center p-2 mb-3">{trans('register.RegisterInfoScreen.title')}</h2>
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <div className="text-center">
                  {trans('register.RegisterInfoScreen.text')}
                  <strong>{renderPhoneNumberObstructed(register.country || '', register.value || '')}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button
                  type="button"
                  className="btn btn-link w-100"
                  data-bs-dismiss="modal"
                  onClick={this.handleBackClick}
                >
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button
                  type="button"
                  className="btn btn-koosmik w-100"
                  disabled={isProcessing}
                  onClick={this.handleOkClick}
                >
                  <ButtonSpinner showSpinner={isProcessing}>
                    {trans('register.RegisterInfoScreen.submit')}
                  </ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  register: registerSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  registerAddPhoneNumber: RegisterAddPhoneNumber;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  registerAddPhoneNumber: () =>
    new Promise((resolve, reject) => dispatch(registerActionAddPhoneNumber(resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterInfoScreen);
