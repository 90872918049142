import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faReceipt, faShoppingCart, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { trans } from '../tools/translate-tools';

type STEP = 'cart' | 'account' | 'payment' | 'confirmation';

interface Props {
  step: STEP;
}

const steps: Array<{ icon: JSX.Element; name: STEP; label: string }> = [
  {
    icon: <FontAwesomeIcon icon={faShoppingCart} />,
    name: 'cart',
    label: 'base.PaymentStepsComponent.cart',
  },
  {
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    name: 'account',
    label: 'base.PaymentStepsComponent.account',
  },
  {
    icon: <FontAwesomeIcon icon={faCoins} />,
    name: 'payment',
    label: 'base.PaymentStepsComponent.payment',
  },
  {
    icon: <FontAwesomeIcon icon={faReceipt} />,
    name: 'confirmation',
    label: 'base.PaymentStepsComponent.confirmation',
  },
];

const PaymentStepsComponent = (props: Props) => {
  const { step } = props;

  const children = steps.map(({ icon, name, label }) => {
    if (name === step) {
      return (
        <li className="step current" key={name}>
          <div className="step-inner">
            {icon}
            <span className="title">{trans(label)}</span>
          </div>
        </li>
      );
    }

    return (
      <li className="step" key={name}>
        {icon}
      </li>
    );
  });

  return <ul className="modal-steps">{children}</ul>;
};

export default PaymentStepsComponent;
