import React from 'react';
import classNames from 'classnames';
import { trans } from '../../base/tools/translate-tools';
import { handleFormSubmitEvent, handleInputChangeEvent } from '../../shared/tools/html-events-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

export interface RegisterPasswordInfoData {
  password: string;
  passwordVerify: string;
}

interface Props {
  isProcessing: boolean;
  initialValues: RegisterPasswordInfoData | null;
  inputErrors: ApiResponseDataInputErrors | null;
  onSubmit: (data: RegisterPasswordInfoData) => void;
  onBackClick: (data: RegisterPasswordInfoData) => void;
}

interface State extends RegisterPasswordInfoData {
  customError: string | null;
}

export default class RegisterPasswordInfoForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    let password = '';
    let passwordVerify = '';

    const { initialValues } = this.props;
    if (initialValues) {
      password = initialValues.password;
      passwordVerify = initialValues.passwordVerify;
    }

    this.state = {
      password,
      passwordVerify,
      customError: null,
    };
  }

  handlePassword = handleInputChangeEvent((password: string) => this.setState({ password }));

  handlePasswordVerify = handleInputChangeEvent((passwordVerify: string) => this.setState({ passwordVerify }));

  handleSubmit = handleFormSubmitEvent(() => {
    const { onSubmit } = this.props;
    const { password, passwordVerify } = this.state;

    if (password !== passwordVerify) {
      this.setState({
        customError: trans('register.RegisterPasswordInfoForm.passwordDoNotMatch'),
      });
      return;
    }

    onSubmit({
      password,
      passwordVerify,
    });
  });

  handleBack = () => {
    const { onBackClick } = this.props;
    const { password, passwordVerify } = this.state;
    onBackClick({ password, passwordVerify });
  };

  renderPassword = (hasError: boolean) => {
    const { password } = this.state;
    return (
      <input
        type="password"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={password}
        onChange={this.handlePassword}
        minLength={8}
        id="password"
        required
      />
    );
  };

  renderPasswordVerify = () => {
    const { passwordVerify, customError } = this.state;

    let error = null;
    if (customError) {
      error = <p className="invalid-feedback">{customError}</p>;
    }
    return (
      <>
        <input
          type="password"
          className={classNames('form-control', { 'is-invalid': !!customError })}
          value={passwordVerify}
          onChange={this.handlePasswordVerify}
          id="password-confirm"
          required
        />
        {error}
      </>
    );
  };

  render() {
    const { isProcessing, inputErrors } = this.props;

    return (
      <>
        <div className="modal-body">
          <h2 className="modal-heading text-center p-2 mb-3">{trans('register.RegisterPasswordInfoForm.title')}</h2>
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                <form id="register-password-info" onSubmit={this.handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      {trans('register.RegisterPasswordInfoForm.password')}
                    </label>
                    <InputWithErrors path="password" inputErrors={inputErrors} render={this.renderPassword} />
                  </div>

                  <div>
                    <label htmlFor="password-confirm" className="form-label">
                      {trans('register.RegisterPasswordInfoForm.passwordVerify')}
                    </label>
                    {this.renderPasswordVerify()}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button type="button" className="btn btn-link w-100" onClick={this.handleBack}>
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button
                  type="submit"
                  form="register-password-info"
                  className="btn btn-koosmik w-100"
                  disabled={isProcessing}
                >
                  <ButtonSpinner showSpinner={isProcessing}>
                    {trans('register.RegisterPasswordInfoForm.submit')}
                  </ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
