import { ApiDefaultResponse, ApiResponseDataInputErrors } from './api-typed';
import { apiPost } from './api-call';

// Login by email

interface ApiAuthLoginEmailPayload {
  email: string;
  paymentId?: string | null;
}

interface BySms {
  sendBy: 'sms';
  phoneNumber: {
    id: string;
    obstructedValue: string;
    countryCode: string;
  };
  inputErrors?: ApiResponseDataInputErrors;
}

interface ByMail {
  sendBy: 'mail';
  mail: string;
  inputErrors?: ApiResponseDataInputErrors;
}

export type ApiAuthLoginEmailResponseData = BySms | ByMail;

export interface ApiAuthLoginEmailResponse extends ApiDefaultResponse<ApiAuthLoginEmailResponseData> {}

export const apiAuthLoginEmail = (payload: ApiAuthLoginEmailPayload): Promise<ApiAuthLoginEmailResponse> =>
  apiPost<ApiAuthLoginEmailResponse>('embedded/v1/login', payload);

// Login by email validate

interface ApiAuthLoginEmailValidatePayload {
  email: string;
  code: string;
}

export interface ApiAuthLoginValidateResponseData {
  token: string;
  inputErrors?: ApiResponseDataInputErrors;
}

export interface ApiAuthLoginValidateResponse extends ApiDefaultResponse<ApiAuthLoginValidateResponseData> {}

export const apiAuthLoginEmailValidate = (
  payload: ApiAuthLoginEmailValidatePayload,
): Promise<ApiAuthLoginValidateResponse> =>
  apiPost<ApiAuthLoginValidateResponse>('embedded/v1/login/validate', payload);

// Login by phone

interface ApiAuthLoginPhonePayload {
  phoneCountry: string;
  phoneNumber: string;
  paymentId?: string | null;
}

export type ApiAuthLoginPhoneResponseData = BySms;

export interface ApiAuthLoginPhoneResponse extends ApiDefaultResponse<ApiAuthLoginPhoneResponseData> {}

export const apiAuthLoginPhone = (payload: ApiAuthLoginPhonePayload): Promise<ApiAuthLoginPhoneResponse> =>
  apiPost<ApiAuthLoginPhoneResponse>('embedded/v1/phone-login', payload);

// Login by phone validate

interface ApiAuthLoginPhoneValidatePayload {
  phoneCountry: string;
  phoneNumber: string;
  code: string;
}

export const apiAuthLoginPhoneValidate = (
  payload: ApiAuthLoginPhoneValidatePayload,
): Promise<ApiAuthLoginValidateResponse> =>
  apiPost<ApiAuthLoginValidateResponse>('embedded/v1/phone-login/validate', payload);
