import { call, delay, put, select, takeLeading } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import {
  NO_ACCOUNT_PAY_ACTION_WITH_TEL,
  NO_ACCOUNT_PAY_ACTION_WITH_THIRD_PARTY,
  NoAccountPayActionWithTel,
  NoAccountPayActionWithThirdParty,
} from './no-account-pay-actions';
import { notificationsActionAddDanger } from '../../../base/redux/notifications/notifications-actions';
import { transErrorCode } from '../../../base/tools/translate-tools';
import { messagingService } from '../../../base/services';
import RouteNames from '../../../../routes/RouteNames';
import { tradeSelect } from '../../../base/redux/trade/trade-selectors';
import { TradeStateNotNull } from '../../../base/redux/trade/trade-state';
import {
  ApiNoAccountPaymentTel,
  ApiNoAccountPaymentTelResponse,
  ApiNoAccountPaymentThirdParty,
  ApiNoAccountPaymentThirdPartyResponse,
} from '../../../../api/no-account';

function* payWithThirdParty({ resolve, reject }: NoAccountPayActionWithThirdParty) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);

    if (trade.type === 'payment') {
      // if type is payment
      const { success, data, errorCode }: ApiNoAccountPaymentThirdPartyResponse = yield call(
        ApiNoAccountPaymentThirdParty,
        {
          id: trade.payment.id,
        },
      );
      if (success) {
        resolve({ data });

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3rdParty));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3rd-party');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* payWithTel({ resolve, reject }: NoAccountPayActionWithTel) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);

    if (trade.type === 'payment') {
      // if type is payment
      const { success, data, errorCode }: ApiNoAccountPaymentTelResponse = yield call(ApiNoAccountPaymentTel, {
        id: trade.payment.id,
      });
      if (success) {
        resolve({ data });

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3rdParty));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3rd-party');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export default function* noAccountPaySaga() {
  yield takeLeading(NO_ACCOUNT_PAY_ACTION_WITH_THIRD_PARTY, payWithThirdParty);
  yield takeLeading(NO_ACCOUNT_PAY_ACTION_WITH_TEL, payWithTel);
}
