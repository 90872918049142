import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  showSpinner: boolean;
  children: React.ReactNode;
}

const ButtonSpinner: React.FC<Props> = ({ showSpinner, children }: Props): React.ReactElement => {
  if (showSpinner) {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }
  return <span>{children}</span>;
};

export default ButtonSpinner;
