// Types

import { ActionReject, ActionResolve } from '../../../base/tools/action-promise-types';

export const NO_ACCOUNT_PAY_ACTION_WITH_THIRD_PARTY = 'NO_ACCOUNT_PAY_ACTION_WITH_THIRD_PARTY';
export const NO_ACCOUNT_PAY_ACTION_WITH_TEL = 'NO_ACCOUNT_PAY_ACTION_WITH_TEL';

// Actions creators definitions

// -- Pay with third party

export interface NoAccountPayActionWithThirdParty {
  type: typeof NO_ACCOUNT_PAY_ACTION_WITH_THIRD_PARTY;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- Pay with tel

export interface NoAccountPayActionWithTel {
  type: typeof NO_ACCOUNT_PAY_ACTION_WITH_TEL;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- enum

export type NoAccountPayActions = NoAccountPayActionWithThirdParty | NoAccountPayActionWithTel;

// Action creators

export const noAccountPayActionWithThirdParty = (
  resolve: ActionResolve,
  reject: ActionReject,
): NoAccountPayActionWithThirdParty => ({
  type: NO_ACCOUNT_PAY_ACTION_WITH_THIRD_PARTY,
  resolve,
  reject,
});

export const noAccountPayActionWithTel = (resolve: ActionResolve, reject: ActionReject): NoAccountPayActionWithTel => ({
  type: NO_ACCOUNT_PAY_ACTION_WITH_TEL,
  resolve,
  reject,
});
