import CardsState from './cards-state';
import { ApiCardAddPayload, ApiCardAddResponseData } from '../../../../api/cards';
import { ActionReject, ActionResolve } from '../../../base/tools/action-promise-types';

// Action Types

export const CARDS_ACTION_ADD = 'CARDS_ACTION_ADD';
export const CARDS_ACTION_SET = 'CARDS_ACTION_SET';
export const CARDS_ACTION_CLEAR = 'CARDS_ACTION_CLEAR';

// Action creators definitions

// -- add

export interface CardsActionAddPayload extends ApiCardAddPayload {}

export interface CardsActionAddResolvePayload {
  data: ApiCardAddResponseData;
}

export interface CardsActionAdd {
  type: typeof CARDS_ACTION_ADD;
  payload: CardsActionAddPayload;
  resolve: ActionResolve<CardsActionAddResolvePayload>;
  reject: ActionReject;
}

// -- set

export interface CardsActionSet {
  type: typeof CARDS_ACTION_SET;
  payload: CardsState;
}

// - cards clear

export interface CardsActionClear {
  type: typeof CARDS_ACTION_CLEAR;
}

// -- enum

export type CardsActions = CardsActionAdd | CardsActionSet | CardsActionClear;

// Action creators

export const cardsActionAdd = (
  payload: CardsActionAddPayload,
  resolve: ActionResolve<CardsActionAddResolvePayload>,
  reject: ActionReject,
): CardsActionAdd => ({
  type: CARDS_ACTION_ADD,
  payload,
  resolve,
  reject,
});

export const cardsActionSet = (payload: CardsState): CardsActionSet => ({
  type: CARDS_ACTION_SET,
  payload,
});

export const cardsActionClear = (): CardsActionClear => ({
  type: CARDS_ACTION_CLEAR,
});
