import { call, delay, put, select, takeLeading } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import {
  PAY_ACTION_WITH_CARD,
  PAY_ACTION_WITH_MILES,
  PAY_ACTION_WITH_TEL,
  PAY_ACTION_WITH_THIRD_PARTY,
  PAY_ACTION_WITH_WALLET,
  PayActionWithCard,
  PayActionWithTel,
  PayActionWithThirdParty,
  PayActionWithWallet,
} from './pay-actions';
import { notificationsActionAddDanger } from '../../../base/redux/notifications/notifications-actions';
import { transErrorCode } from '../../../base/tools/translate-tools';
import { messagingService } from '../../../base/services';
import RouteNames from '../../../../routes/RouteNames';
import {
  apiPaymentPay,
  apiPaymentPayMiles,
  ApiPaymentPayMilesResponse,
  ApiPaymentPayResponse,
  apiPaymentPayWithCard,
  ApiPaymentPayWithCardResponse,
  apiPaymentPayWithTel,
  ApiPaymentPayWithTelResponse,
  apiPaymentPayWithThirdParty,
  ApiPaymentPayWithThirdPartyResponse,
} from '../../../../api/payments';
import { convertCentToUnit, renderCurrency } from '../../../base/tools/currency-tools';
import { tradeSelect } from '../../../base/redux/trade/trade-selectors';
import { TradeStateNotNull } from '../../../base/redux/trade/trade-state';
import {
  apiRequestPay,
  ApiRequestPayResponse,
  apiRequestPayWithCard,
  ApiRequestPayWithCardResponse,
  apiRequestPayWithThirdParty,
  ApiRequestPayWithThirdPartyResponse,
} from '../../../../api/requests';

function* payWithWallet({ resolve, reject }: PayActionWithWallet) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);

    if (trade.type === 'payment') {
      // if type is payment
      const { success, data, errorCode }: ApiPaymentPayResponse = yield call(apiPaymentPay, trade.payment.id);
      if (success) {
        resolve({});

        if (data.successRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectSuccess));
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        if (data.cancelRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.cancelRedirectUrl, 'merchant');
          return;
        }

        return;
      }

      let options: { [key: string]: string } = {};
      if (data.limitParams) {
        if (errorCode === 'LIMIT_WALLET_MILES_AMOUNT' && data.limitParams.amount && data.limitParams.currency) {
          options = {
            amount: renderCurrency(data.limitParams.amount, data.limitParams.currency),
          };
        }
        if (
          (errorCode === 'LIMIT_WALLET_MILES_WEEK_LIMIT' || errorCode === 'LIMIT_WALLET_MILES_MONTH_LIMIT') &&
          data.limitParams.limit
        ) {
          options = {
            limit: String(data.limitParams.limit),
          };
        }
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode, options)));
      reject({
        errorCode,
      });
    } else {
      // if type is request
      const { success, data, errorCode }: ApiRequestPayResponse = yield call(apiRequestPay, trade.request.id);
      if (success) {
        resolve({});

        if (data.successRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectSuccess));
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* payWithMiles({ resolve, reject }: PayActionWithWallet) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);

    if (trade.type === 'payment') {
      // if type is payment
      const { success, data, errorCode }: ApiPaymentPayMilesResponse = yield call(apiPaymentPayMiles, trade.payment.id);
      if (success) {
        resolve({});

        if (data.successRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectSuccess));
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        if (data.cancelRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.cancelRedirectUrl, 'merchant');
          return;
        }

        return;
      }

      let options: { [key: string]: string } = {};
      if (data.limitParams) {
        if (errorCode === 'LIMIT_WALLET_MILES_AMOUNT' && data.limitParams.amount && data.limitParams.currency) {
          options = {
            amount: renderCurrency(data.limitParams.amount, data.limitParams.currency),
          };
        }
        if (
          (errorCode === 'LIMIT_WALLET_MILES_WEEK_LIMIT' || errorCode === 'LIMIT_WALLET_MILES_MONTH_LIMIT') &&
          data.limitParams.limit
        ) {
          options = {
            limit: String(data.limitParams.limit),
          };
        }
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode, options)));
      reject({
        errorCode,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* payWithCard({ payload, resolve, reject }: PayActionWithCard) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);

    if (trade.type === 'payment') {
      // if type is payment
      const { success, data, errorCode }: ApiPaymentPayWithCardResponse = yield call(apiPaymentPayWithCard, {
        id: trade.payment.id,
        card: payload.card,
        cardPayInAmount: convertCentToUnit(payload.cardPayInAmount),
      });
      if (success) {
        resolve({ data });

        if (data.successRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectSuccess));
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        if (data.cancelRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.cancelRedirectUrl, 'merchant');
          return;
        }

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3dSecure));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3Dsecure');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    } else {
      // if type is request
      const { success, data, errorCode }: ApiRequestPayWithCardResponse = yield call(apiRequestPayWithCard, {
        id: trade.request.id,
        card: payload.card,
        cardPayInAmount: convertCentToUnit(payload.cardPayInAmount),
      });
      if (success) {
        resolve({ data });

        if (data.successRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectSuccess));
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3dSecure));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3Dsecure');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* payWithThirdParty({ resolve, reject }: PayActionWithThirdParty) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);

    if (trade.type === 'payment') {
      // if type is payment
      const { success, data, errorCode }: ApiPaymentPayWithThirdPartyResponse = yield call(
        apiPaymentPayWithThirdParty,
        {
          id: trade.payment.id,
        },
      );
      if (success) {
        resolve({ data });

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3rdParty));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3rd-party');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    } else {
      // if type is request
      const { success, data, errorCode }: ApiRequestPayWithThirdPartyResponse = yield call(
        apiRequestPayWithThirdParty,
        {
          id: trade.request.id,
        },
      );
      if (success) {
        resolve({ data });

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3rdParty));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3rd-party');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* payWithTel({ resolve, reject }: PayActionWithTel) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);

    if (trade.type === 'payment') {
      // if type is payment
      const { success, data, errorCode }: ApiPaymentPayWithTelResponse = yield call(apiPaymentPayWithTel, {
        id: trade.payment.id,
      });
      if (success) {
        resolve({ data });

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3rdParty));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3rd-party');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export default function* paySaga() {
  yield takeLeading(PAY_ACTION_WITH_WALLET, payWithWallet);
  yield takeLeading(PAY_ACTION_WITH_MILES, payWithMiles);
  yield takeLeading(PAY_ACTION_WITH_CARD, payWithCard);
  yield takeLeading(PAY_ACTION_WITH_THIRD_PARTY, payWithThirdParty);
  yield takeLeading(PAY_ACTION_WITH_TEL, payWithTel);
}
