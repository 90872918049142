import { call, put, select, takeLeading } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import RouteNames from '../../../../routes/RouteNames';
import { notificationsActionAddDanger } from '../../../base/redux/notifications/notifications-actions';
import { transErrorCode } from '../../../base/tools/translate-tools';
import {
  REGISTER_ACTION_ADD_PHONE_NUMBER,
  REGISTER_ACTION_SUBMIT_AFRI,
  REGISTER_ACTION_VALIDATE_PHONE_NUMBER,
  RegisterActionAddPhoneNumber,
  registerActionSetPhoneNumberId,
  RegisterActionSubmitAfri,
  RegisterActionValidatePhoneNumber,
} from './register-actions';
import { registerSelect } from './register-selectors';
import RegisterState from './register-state';
import {
  apiRegisterAfri,
  ApiRegisterAfriResponse,
  apiRegisterPhoneNumberAdd,
  ApiRegisterPhoneNumberAddResponse,
  apiRegisterPhoneNumberVerify,
  ApiRegisterPhoneNumberVerifyResponse,
} from '../../../../api/register';
import { getLocale } from '../../../../config/init-locale';
import { authActionSetToken } from '../../../auth/redux/auth/auth-actions';

function* registerAddPhoneNumber({ resolve, reject }: RegisterActionAddPhoneNumber) {
  try {
    const registerState: RegisterState = yield select(registerSelect);

    const { success, data, errorCode }: ApiRegisterPhoneNumberAddResponse = yield call(apiRegisterPhoneNumberAdd, {
      country: registerState.country!,
      value: registerState.value!,
    });

    if (success) {
      yield put(registerActionSetPhoneNumberId({ phoneNumberId: data.id }));
      resolve({});
      yield put(routerActions.replace(RouteNames.registerPhoneValidation));
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.errors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* registerValidatePhoneNumber({ payload, resolve, reject }: RegisterActionValidatePhoneNumber) {
  try {
    const registerState: RegisterState = yield select(registerSelect);

    const { success, errorCode }: ApiRegisterPhoneNumberVerifyResponse = yield call(apiRegisterPhoneNumberVerify, {
      phoneNumberId: registerState.phoneNumberId!,
      ...payload,
    });

    if (success) {
      resolve({});
      yield put(routerActions.replace(RouteNames.registerAfri));
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* registerSubmitAfri({ payload, resolve, reject }: RegisterActionSubmitAfri) {
  try {
    const registerState: RegisterState = yield select(registerSelect);

    const { success, data, errorCode }: ApiRegisterAfriResponse = yield call(apiRegisterAfri, {
      phoneNumberId: registerState.phoneNumberId!,
      lang: getLocale(),
      ...payload,
    });

    if (success) {
      resolve({});
      yield put(authActionSetToken(data.token));
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.errors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export default function* registerSaga() {
  yield takeLeading(REGISTER_ACTION_ADD_PHONE_NUMBER, registerAddPhoneNumber);
  yield takeLeading(REGISTER_ACTION_VALIDATE_PHONE_NUMBER, registerValidatePhoneNumber);
  yield takeLeading(REGISTER_ACTION_SUBMIT_AFRI, registerSubmitAfri);
}
