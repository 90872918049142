import React, { SelectHTMLAttributes } from 'react';
import Select, { SelectOption } from './Select';
import { getAllCountries } from '../../../base/tools/translate-tools';
import phoneCountryPrefixes from '../../../../resources/data/country-phone-prefixes';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {}

class PhoneCountrySelect extends React.PureComponent<Props> {
  options: SelectOption[] = [];

  constructor(props: Props) {
    super(props);

    Object.entries(getAllCountries()).forEach(([value, label]) => {
      if (value in phoneCountryPrefixes) {
        this.options.push({
          value,
          label: `${label} (+${phoneCountryPrefixes[value]})`,
        });
      }
    });
  }

  render() {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Select options={this.options} {...this.props} />
    );
  }
}

export default PhoneCountrySelect;
