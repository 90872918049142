import i18next from 'i18next';

type Options = { [key: string]: string };

export const trans = (text: string, options: Options = {}) => i18next.t(text, options);

export const transErrorCode = (errorCode: string, options: Options = {}) => trans(`errorCode:${errorCode}`, options);

export const transFormError = (errorCode: string, options: Options = {}) => trans(`formError:${errorCode}`, options);

export const transCountry = (country: string) => trans(`country:${country}`);

export const getAllCountries = (): { [key: string]: string } => i18next.getResourceBundle(i18next.language, 'country');
