import React from 'react';
import { isDomainValid, isInIframe } from '../tools/security';
import ErrorEmbedded from './ErrorEmbedded';
import { trans } from '../tools/translate-tools';

interface Props {
  children: React.ReactNode;
}

interface State {
  isForbidden: boolean;
  forbiddenMessage: string | null;
}

/**
 * Check if the app is allowed to start (within an iframe AND from an allowed domain)
 */
class SecurityGuardComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.securityCheck() || {
      isForbidden: false,
      forbiddenMessage: null,
    };
  }

  securityCheck = (): State | null => {
    // we allow the app to not run in iframe IF we are in development mode
    if (process.env.NODE_ENV === 'development') {
      if (!isInIframe() && !process.env.REACT_APP_TOKEN) {
        return {
          isForbidden: true,
          forbiddenMessage: 'Please define REACT_APP_TOKEN if you want to run it in dev mode without iframe',
        };
      }

      return null;
    }

    if (!isInIframe()) {
      return {
        isForbidden: true,
        forbiddenMessage: trans('security.directAccessForbidden'),
      };
    }

    if (!isDomainValid()) {
      return {
        isForbidden: true,
        forbiddenMessage: trans('security.directAccessForbidden'),
      };
    }

    return null;
  };

  render(): React.ReactNode {
    const { isForbidden, forbiddenMessage } = this.state;
    if (isForbidden && forbiddenMessage) {
      return <ErrorEmbedded title={trans('security.forbidden')} message={forbiddenMessage} />;
    }

    const { children } = this.props;
    return children;
  }
}

export default SecurityGuardComponent;
