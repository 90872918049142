import React from 'react';
import classNames from 'classnames';
import { trans } from '../../base/tools/translate-tools';
import { handleInputChangeEvent } from '../../shared/tools/html-events-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import { filterNotDigit } from '../../shared/tools/input-filters';
import { isCardNumberLikeMastercard, isCardNumberLikeVisa } from '../../shared/tools/card-tools';
import iconVisa from '../../../assets/images/icon-visa.svg';
import iconMastercard from '../../../assets/images/icon-mastercard.svg';

export interface CardFormData {
  label: string;
  cardNumber: string;
  cardCvx: string;
  cardExpirationDate: string;
}

interface Props {
  isProcessing: boolean;
  inputErrors: ApiResponseDataInputErrors | null;
  onChange: (data: CardFormData) => void;
}

interface State extends CardFormData {}

export default class CardForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      label: trans('directPay.CardInfoForm.labelDefault'),
      cardNumber: '',
      cardCvx: '',
      cardExpirationDate: '',
    };
  }

  handleLabel = handleInputChangeEvent((label: string) => this.update({ label }));

  handleCardNumber = handleInputChangeEvent((cardNumber: string) =>
    this.update({ cardNumber: filterNotDigit(cardNumber) }),
  );

  handleCardCvx = handleInputChangeEvent((cardCvx: string) => this.update({ cardCvx: filterNotDigit(cardCvx) }));

  handleCardExpirationDate = handleInputChangeEvent((cardExpirationDate: string) =>
    this.update({ cardExpirationDate: filterNotDigit(cardExpirationDate) }),
  );

  update = <K extends keyof State>(partialState: Pick<State, K>) => {
    this.setState(partialState, () => {
      const { onChange } = this.props;
      const { label, cardNumber, cardCvx, cardExpirationDate } = this.state;

      onChange({
        label,
        cardNumber,
        cardCvx,
        cardExpirationDate,
      });
    });
  };

  renderLabel = (hasError: boolean) => {
    const { isProcessing } = this.props;
    const { label } = this.state;

    return (
      <input
        type="text"
        id="card-label"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={label}
        disabled={isProcessing}
        onChange={this.handleLabel}
        required
      />
    );
  };

  renderCardNumber = (hasError: boolean) => {
    const { isProcessing } = this.props;
    const { cardNumber } = this.state;

    let cardIcon = null;
    if (isCardNumberLikeVisa(cardNumber)) {
      cardIcon = <img className="current" src={iconVisa} height="11" alt="Visa" />;
    } else if (isCardNumberLikeMastercard(cardNumber)) {
      cardIcon = <img className="current" src={iconMastercard} height="13" alt="Mastercard" />;
    }

    return (
      <>
        <input
          type="text"
          id="card-number"
          inputMode="numeric"
          pattern="[0-9]*"
          className={classNames('form-control', { 'is-invalid': hasError })}
          value={cardNumber}
          disabled={isProcessing}
          onChange={this.handleCardNumber}
          required
        />
        <div className="cc-input-brands d-flex align-items-center">{cardIcon}</div>
      </>
    );
  };

  renderCardExpirationDate = (hasError: boolean) => {
    const { isProcessing } = this.props;
    const { cardExpirationDate } = this.state;

    return (
      <input
        type="text"
        id="card-expiration-date"
        placeholder={trans('directPay.CardInfoForm.cardExpirationDatePlaceholder')}
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={cardExpirationDate}
        disabled={isProcessing}
        onChange={this.handleCardExpirationDate}
        maxLength={4}
        required
      />
    );
  };

  renderCardCvx = (hasError: boolean) => {
    const { cardCvx } = this.state;
    const { isProcessing } = this.props;

    return (
      <input
        type="text"
        id="card-cvx"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={cardCvx}
        disabled={isProcessing}
        onChange={this.handleCardCvx}
        maxLength={3}
        required
      />
    );
  };

  render() {
    const { inputErrors } = this.props;

    return (
      <div>
        <div className="mb-3">
          <label htmlFor="card-label" className="form-label">
            {trans('directPay.CardInfoForm.label')}
          </label>
          <InputWithErrors path="label" inputErrors={inputErrors} render={this.renderLabel} />
        </div>

        <div className="mb-3 cc-input-wrapper">
          <label htmlFor="card-number" className="form-label">
            {trans('directPay.CardInfoForm.cardNumber')}
          </label>
          <InputWithErrors path="cardNumber" inputErrors={inputErrors} render={this.renderCardNumber} />
        </div>

        <div className="row g-3 mb-3">
          <div className="col12 col-sm-6">
            <label htmlFor="card-expiration-date" className="form-label">
              {trans('directPay.CardInfoForm.cardExpirationDate')}
            </label>
            <InputWithErrors
              path="cardExpirationDate"
              inputErrors={inputErrors}
              render={this.renderCardExpirationDate}
            />
          </div>
          <div className="col12 col-sm-6">
            <label htmlFor="card-cvx" className="form-label">
              {trans('directPay.CardInfoForm.cardCvx')}
            </label>
            <InputWithErrors path="cardCvx" inputErrors={inputErrors} render={this.renderCardCvx} />
          </div>
        </div>
      </div>
    );
  }
}
