import { ApiDefaultResponse } from './api-typed';
import { apiAuthPost } from './api-call';

// pay third party

export interface ApiNoAccountPaymentThirdPartyPayload {
  id: string;
}

export interface ApiNoAccountPaymentThirdPartyResponseData {
  redirectUrl: string;
}

export interface ApiNoAccountPaymentThirdPartyResponse
  extends ApiDefaultResponse<ApiNoAccountPaymentThirdPartyResponseData> {}

export const ApiNoAccountPaymentThirdParty = ({
  id,
  ...data
}: ApiNoAccountPaymentThirdPartyPayload): Promise<ApiNoAccountPaymentThirdPartyResponse> =>
  apiAuthPost<ApiNoAccountPaymentThirdPartyResponse>(`embedded/v1/no-account/payments/3rd-party/${id}`, data);

// pay with tel

export interface ApiNoAccountPaymentTelPayload {
  id: string;
}

export interface ApiNoAccountPaymentTelResponseData {
  redirectUrl: string;
}

export interface ApiNoAccountPaymentTelResponse extends ApiDefaultResponse<ApiNoAccountPaymentTelResponseData> {}

export const ApiNoAccountPaymentTel = ({
  id,
  ...data
}: ApiNoAccountPaymentTelPayload): Promise<ApiNoAccountPaymentTelResponse> =>
  apiAuthPost<ApiNoAccountPaymentTelResponse>(`embedded/v1/no-account/payments/tel/${id}`, data);
