import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import RootState from '../../../redux/root-state';
import { directPayActionSubmit, DirectPayActionSubmitPayload } from '../redux/direct-pay/direct-pay-actions';
import UserInfoForm, { UserInfoData } from '../components/UserInfoForm';
import CardInfoForm, { CardInfoData } from '../components/CardInfoForm';
import { authSelect } from '../../auth/redux/auth/auth-selectors';
import AuthState from '../../auth/redux/auth/auth-state';
import { getLocale } from '../../../config/init-locale';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import RouteNames from '../../../routes/RouteNames';
import { tradeSelect } from '../../base/redux/trade/trade-selectors';
import { TradeStateNotNull } from '../../base/redux/trade/trade-state';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';

type DirectPaySubmit = (payload: DirectPayActionSubmitPayload) => Promise<{}>;

interface Props extends RouteComponentProps {
  auth: AuthState;
  directPaySubmit: DirectPaySubmit;
  trade: TradeStateNotNull;
}

interface State {
  step: 'user' | 'card';
  userInfo: UserInfoData | null;
  cardInfo: CardInfoData | null;

  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
}

class DirectPayScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'user',
      userInfo: null,
      cardInfo: null,

      inputErrors: null,
      isProcessing: false,
    };
  }

  onUserInfoSubmit = (userInfo: UserInfoData) => {
    this.setState({
      userInfo,
      step: 'card',
    });
  };

  onUserBackClick = () => {
    const { history } = this.props;
    history.replace(RouteNames.authEmail);
  };

  onCardSubmit = (cardInfo: CardInfoData) => {
    const { directPaySubmit, auth } = this.props;
    const { userInfo, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ cardInfo, isProcessing: true, inputErrors: null });

    directPaySubmit({
      email: auth.email!,
      lang: getLocale(),
      ...userInfo!,
      ...cardInfo,
    }).catch(
      actionRejectHandler(arg => {
        const inputErrors = arg.inputErrors || null;
        this.setState({
          isProcessing: false,
          inputErrors,
        });

        if (inputErrors) {
          if (!userInfo || Object.keys(userInfo).find(k => k in inputErrors)) {
            this.setState({ step: 'user' });
          }
        }
      }),
    );
  };

  onCardBackClick = (cardInfo: CardInfoData) => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ cardInfo, step: 'user' });
  };

  renderForm() {
    const { trade } = this.props;
    const { step, userInfo, cardInfo, inputErrors } = this.state;

    if (step === 'user') {
      return (
        <>
          <PaymentStepsComponent step="account" />
          <UserInfoForm
            initialValues={userInfo}
            trade={trade}
            inputErrors={inputErrors}
            onSubmit={this.onUserInfoSubmit}
            onBackClick={this.onUserBackClick}
          />
        </>
      );
    }

    const { isProcessing } = this.state;
    return (
      <>
        <PaymentStepsComponent step="payment" />
        <CardInfoForm
          initialValues={cardInfo}
          isProcessing={isProcessing}
          inputErrors={inputErrors}
          onSubmit={this.onCardSubmit}
          onBackClick={this.onCardBackClick}
        />
      </>
    );
  }

  render() {
    return (
      <>
        <PaymentCartComponent />
        {this.renderForm()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: authSelect(state)!,
  trade: tradeSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  directPaySubmit: DirectPaySubmit;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  directPaySubmit: payload =>
    new Promise((resolve, reject) => dispatch(directPayActionSubmit(payload, resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DirectPayScreen);
