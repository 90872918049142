export default {
  global: {
    loading: 'Chargement',
    back: 'Retour',
  },
  date: {
    months: {
      month01: 'Janvier',
      month02: 'Février',
      month03: 'Mars',
      month04: 'Avril',
      month05: 'Mai',
      month06: 'Juin',
      month07: 'Juillet',
      month08: 'Août',
      month09: 'Septembre',
      month10: 'Octobre',
      month11: 'Novembre',
      month12: 'Décembre',
    },
  },
  format: {
    dateOnly: 'DD MMMM YYYY',
  },
  security: {
    forbidden: 'Accès non autorisé',
    directAccessForbidden: "L'accès direct à cette page n'est pas autorisé",
    domainForbidden: "Cette application n'est pas appelé via le bon domaine",
  },
  form: {
    dateSelect: {
      selectDay: '- Jour -',
      selectMonth: '- Mois -',
      selectYear: '- Année -',
    },
  },
  base: {
    AppInputGateComponent: {
      loadingFailed: 'Echec du chargement des données de la transaction',
      statusNotPending: 'Impossible de traiter ce paiement',
      statusFailed: 'Ce paiement a déjà échoué. Veuillez recommencer le processus de paiement',
      alreadyPaid: 'Ce paiement a déjà été payé',
      statusExpired: 'Ce paiement a expiré',
    },
    RedirectSuccessScreen: {
      title: 'Paiement réussi',
      message: 'Vous allez être redirigé vers le site du marchand',
    },
    Redirect3dSecureScreen: {
      title: 'Paiement sécurisé',
      message: 'Vous allez être redirigé vers le site de votre banque afin de valider votre paiement',
    },
    RedirectFailureScreen: {
      title: 'Paiement échoué',
      message: 'Vous allez être redirigé vers le site du marchand',
    },
    Redirect3rdPartyScreen: {
      title: 'Redirection',
      message: 'Vous allez être redirigé vers une plateforme de paiement',
    },

    PaymentCartComponent: {
      amountToPay: 'Montant à régler :',
    },
    PaymentStepsComponent: {
      cart: 'Panier',
      account: 'Compte',
      payment: 'Paiement',
      confirmation: 'Confirmation',
    },
    CartScreen: {
      amountToPay: 'Montant à régler :',
      cancel: 'Annuler',
      submit: 'Payer',
    },
  },
  auth: {
    saga: {
      usePhoneNumberForRegister: 'Pas de compte trouvé, veuillez utilisé un numéro de téléphone pour créer un compte',
      useEmailForRegister: 'Pas de compte trouvé, veuillez utilisé une adresse email pour créer un compte',
    },
    AuthEmailScreen: {
      byPhone: 'Par téléphone',
      email: 'Adresse email',
      noAccount: 'Je veux payer sans compte Koosmik',
      noAccountOr: 'OU',
      next: 'Suivant',
    },
    AuthPhoneScreen: {
      byEmail: 'Par email',
      phoneCountry: 'Pays',
      phoneNumber: 'Numéro',
      noAccount: 'Je veux payer sans compte Koosmik',
      next: 'Suivant',
    },
    AuthValidationScreen: {
      phoneTitle: 'Confirmation téléphone',
      emailTitle: 'Confirmation email',
      sendByMail: 'Un message a été par envoyé par mail à {{email}}. Veuillez entrer le code inclus.',
      sendBySms: 'Un sms a été envoyé au {{phoneNumber}}. Veuillez entrer le code inclus.',
      code: 'Code',
      submit: 'Valider',
    },
    AuthPasswordScreen: {
      connectedAs: 'Connecté(e) à Koosmik en tant que ',
      notYou: "Ce n'est pas vous ?",
      sendCodeByMail: 'Envoyez-moi plutôt un code de connexion unique par email',
      sendCodeBySms: 'Envoyez-moi plutôt un code de connexion unique par sms',
      password: 'Mot de passe',
      submit: 'Continuer',
    },
  },
  register: {
    RegisterInfoScreen: {
      title: 'Création de compte',
      text: 'Pas de compte relié à ce numéro :',
      submit: 'Créer mon compte',
    },
    RegisterPhoneValidationScreen: {
      title: 'Confirmation de connexion',
      text: 'Un message vous a été envoyé par téléphone au {{phoneNumber}}. Veuillez entrer le code inclus.',
      code: 'Code',
      submit: 'Valider',
    },
    RegisterUserInfoForm: {
      title: 'Création de compte — Infos',
      gender: 'Sexe',
      genderMale: 'Homme',
      genderFemale: 'Femme',
      firstName: 'Prénom',
      lastName: 'Nom',
      countryOfResidence: 'Pays de résidence',
      nationality: 'Nationalité',
      birthday: 'Date de naissance',
      birthplace: 'Lieu de naissance',
      next: 'Suivant',
    },
    RegisterPasswordInfoForm: {
      title: 'Création de compte — Mot de passe',
      password: 'Mot de passe',
      passwordVerify: 'Confirmation',
      passwordDoNotMatch: 'Les mot de passe ne correspondent pas',
      submit: 'Soumettre',
    },
  },
  directPay: {
    UserInfoForm: {
      title: 'Création de compte — Infos',
      firstName: 'Prénom',
      lastName: 'Nom',
      countryOfResidence: 'Pays de résidence',
      nationality: 'Nationalité',
      birthday: 'Date de naissance',
      next: 'Suivant',
    },
    CardInfoForm: {
      title: 'Détails carte bancaire',
      label: 'Label carte',
      labelDefault: 'Ma Carte perso',
      cardNumber: 'Numéro de carte',
      cardCvx: 'Code secret',
      cardExpirationDate: 'Date expiration',
      cardExpirationDatePlaceholder: 'MMAA',
      submit: 'Payer',
    },
  },
  dashboard: {
    HomeScreen: {
      addCard: 'Ajouter une carte',
      thirdParty: 'Payer par carte',
      pay: 'Payer',
    },
    PaymentMethodSelect: {
      chooseMethod: 'Choisissez votre méthode de paiement',
      myWallet: 'Solde disponible',
      myWalletNotEnough: '(Pas assez de fond)',
      miles: 'Utiliser mes koosmiles',
      card: 'Card bancaire',
      thirdParty: 'Payer par carte',
      tel: 'Payer par mobile money',
    },
    PaymentCardSelect: {
      title: 'Quel carte utiliser ?',
      addCard: 'Nouvelle carte',
    },
    PaymentRecap: {
      amount: 'Votre solde',
      amountToPay: 'Montant à régler',
      amountLeft: 'Solde après paiement',
      miles: 'Vos koosmiles',
      milesEquivalent: 'Koosmiles à payer',
      milesRemaining: 'Koosmiles restant',
    },
  },
};
