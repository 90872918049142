import { call, put, takeLeading } from 'redux-saga/effects';
import { notificationsActionAddDanger } from '../../../base/redux/notifications/notifications-actions';
import { transErrorCode } from '../../../base/tools/translate-tools';
import { CARDS_ACTION_ADD, CardsActionAdd } from './cards-actions';
import { apiCardAdd, ApiCardAddResponse } from '../../../../api/cards';

function* cardsAdd({ payload, resolve, reject }: CardsActionAdd) {
  try {
    const { success, data, errorCode }: ApiCardAddResponse = yield call(apiCardAdd, payload);
    if (success) {
      resolve({ data });
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.errors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export default function* cardsSaga() {
  yield takeLeading(CARDS_ACTION_ADD, cardsAdd);
}
