import i18next from 'i18next';
import fr from '../resources/i18n/fr';
import en from '../resources/i18n/en';

export default function initI18nNext() {
  i18next
    .init({
      lng: 'fr',
      whitelist: ['fr', 'en'],
      ns: ['translation', 'errorCode', 'country', 'formError'],
      defaultNS: 'translation',
      debug: process.env.NODE_ENV === 'development',
      resources: {
        fr,
        en,
      },
      fallbackLng: {
        default: ['fr'],
      },
    })
    .then(() => {});
}
