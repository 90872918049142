import phoneCountryPrefixes from '../../../resources/data/country-phone-prefixes';

export function renderPhoneNumber(country: string, number: string) {
  let chunkSize = 3;
  if (country === 'FR') {
    chunkSize = 2;
  }

  const chunks: string[][] = [];
  let lastChunk: string[] | null = null;

  number
    .replace(/\s+/g, '')
    .split('')
    .reverse()
    .forEach(t => {
      if (lastChunk === null) {
        lastChunk = [];
        chunks.push(lastChunk);
      }
      lastChunk.push(t);
      if (lastChunk.length >= chunkSize) {
        lastChunk = null;
      }
    });

  const formatted = chunks
    .reverse()
    .map(a => a.reverse().join(''))
    .join(' ');

  return `(+${phoneCountryPrefixes[country] || country}) ${formatted}`;
}

export function renderPhoneNumberObstructed(country: string, number: string) {
  let newNumber = number.replace(/\s+/g, '');

  const l = newNumber.length;
  if (l > 5) {
    newNumber = newNumber.substr(0, 2) + 'x'.repeat(l - 4) + newNumber.substr(l - 2);
  }

  return renderPhoneNumber(country, newNumber);
}
