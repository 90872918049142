export default {
  isEmpty: 'Value is required here',

  emailAddressInvalid: 'The email address format is invalid',

  dateInvalidDate: 'The date is invalid',
  dateTooYoung: 'You must be an adult to register',

  creditcardPrefix: 'The input contains an invalid amount of digits',
  creditcardLength: 'The card number is too short',
  creditcardContent: 'The input seems to be an invalid credit card number',
  cardExpirationDateInvalid: 'Invalid expiration date',
  notDigits: 'Expected only digits',

  passwordTooSimple: 'This password is too simple. Please use number ans special char',
  passwordTooShort: 'This password is too short',

  stringLengthTooShort: 'Too short',
};
