import React from 'react';
import classNames from 'classnames';
import { getAllCountries, trans } from '../../base/tools/translate-tools';
import {
  handleFormSubmitEvent,
  handleInputChangeEvent,
  handleSelectChangeEvent,
} from '../../shared/tools/html-events-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import Select, { SelectOption } from '../../shared/components/form/Select';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import { TradeStateNotNull } from '../../base/redux/trade/trade-state';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';

export interface UserInfoData {
  firstName: string;
  lastName: string;
  birthday: string;
  countryOfResidence: string;
  nationality: string;
}

interface Props {
  trade: TradeStateNotNull;
  initialValues: UserInfoData | null;
  inputErrors: ApiResponseDataInputErrors | null;
  onSubmit: (data: UserInfoData) => void;
  onBackClick: () => void;
}

interface State extends UserInfoData {
  // birthday invalid
  birthdayErrors: string[];
}

export default class UserInfoForm extends React.PureComponent<Props, State> {
  countries: SelectOption[];

  constructor(props: Props) {
    super(props);

    this.countries = Object.entries(getAllCountries()).map(([value, label]) => ({
      value,
      label,
    }));

    let firstName = '';
    let lastName = '';
    let birthday = '';
    let countryOfResidence = 'FR';
    let nationality = 'FR';

    const { trade, initialValues } = this.props;

    if (trade.type === 'payment') {
      const { payerPrefillInfo } = trade.payment;
      if (payerPrefillInfo) {
        if (payerPrefillInfo.firstName) {
          firstName = payerPrefillInfo.firstName;
        }
        if (payerPrefillInfo.lastName) {
          lastName = payerPrefillInfo.lastName;
        }
        if (payerPrefillInfo.birthday) {
          birthday = payerPrefillInfo.birthday;
        }
        if (payerPrefillInfo.countryOfResidence) {
          countryOfResidence = payerPrefillInfo.countryOfResidence;
        }
        if (payerPrefillInfo.nationality) {
          nationality = payerPrefillInfo.nationality;
        }
      }
    }

    this.state = {
      firstName,
      lastName,
      birthday,
      countryOfResidence,
      nationality,
      birthdayErrors: [],
    };

    if (initialValues) {
      this.state = {
        ...this.state,
        ...initialValues,
      };
    }
  }

  handleFirstName = handleInputChangeEvent((firstName: string) => this.setState({ firstName }));

  handleLastName = handleInputChangeEvent((lastName: string) => this.setState({ lastName }));

  handleBirthday = handleInputChangeEvent((birthday: string) => this.setState({ birthday }));

  handleCountryOfResidence = handleSelectChangeEvent((countryOfResidence: string) =>
    this.setState({ countryOfResidence }),
  );

  handleNationality = handleSelectChangeEvent((nationality: string) => this.setState({ nationality }));

  handleSubmit = handleFormSubmitEvent(() => {
    const { firstName, lastName, birthday, countryOfResidence, nationality } = this.state;
    if (birthday) {
      const { onSubmit } = this.props;

      onSubmit({
        firstName,
        lastName,
        birthday,
        countryOfResidence,
        nationality,
      });
      this.setState({ birthdayErrors: [] });
    } else {
      this.setState({ birthdayErrors: ['dateInvalidDate'] });
    }
  });

  handleBack = () => {
    const { onBackClick } = this.props;
    onBackClick();
  };

  renderFirstName = (hasError: boolean) => {
    const { firstName } = this.state;

    return (
      <input
        type="text"
        id="firstName"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={firstName}
        onChange={this.handleFirstName}
        required
      />
    );
  };

  renderLastName = (hasError: boolean) => {
    const { lastName } = this.state;

    return (
      <input
        type="text"
        id="lastName"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={lastName}
        onChange={this.handleLastName}
        required
      />
    );
  };

  renderBirthday = (hasError: boolean) => {
    const { birthday } = this.state;

    return (
      <input
        type="date"
        id="birthday"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={birthday}
        onChange={this.handleBirthday}
        required
      />
    );
  };

  renderCountryOfResidence = (hasError: boolean) => {
    const { countryOfResidence } = this.state;

    return (
      <Select
        id="countryOfResidence"
        value={countryOfResidence}
        options={this.countries}
        onChange={this.handleCountryOfResidence}
        className={classNames('form-select', { 'is-invalid': hasError })}
      />
    );
  };

  renderNationality = (hasError: boolean) => {
    const { nationality } = this.state;

    return (
      <Select
        id="countryOfResidence"
        value={nationality}
        options={this.countries}
        onChange={this.handleNationality}
        className={classNames('form-select', { 'is-invalid': hasError })}
      />
    );
  };

  render() {
    const { inputErrors } = this.props;
    const { birthdayErrors } = this.state;

    return (
      <>
        <div className="modal-body">
          <h2 className="modal-heading text-center p-2 mb-3">{trans('directPay.UserInfoForm.title')}</h2>
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                <form onSubmit={this.handleSubmit} id="direct-pay-user-info">
                  <div className="row g-3 mb-3">
                    <div className="col12 col-sm-6">
                      <label htmlFor="firstName" className="form-label">
                        {trans('directPay.UserInfoForm.firstName')}
                      </label>
                      <InputWithErrors path="firstName" inputErrors={inputErrors} render={this.renderFirstName} />
                    </div>
                    <div className="col12 col-sm-6">
                      <label htmlFor="firstName" className="form-label">
                        {trans('directPay.UserInfoForm.lastName')}
                      </label>
                      <InputWithErrors path="lastName" inputErrors={inputErrors} render={this.renderLastName} />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="birthday" className="form-label">
                      {trans('directPay.UserInfoForm.birthday')}
                    </label>
                    <InputWithErrors
                      path="birthday"
                      inputErrors={inputErrors}
                      render={this.renderBirthday}
                      customErrors={birthdayErrors}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="countryOfResidence" className="form-label">
                      {trans('directPay.UserInfoForm.countryOfResidence')}
                    </label>
                    <InputWithErrors
                      path="countryOfResidence"
                      inputErrors={inputErrors}
                      render={this.renderCountryOfResidence}
                    />
                  </div>

                  <div className="mb-0">
                    <label htmlFor="nationality" className="form-label">
                      {trans('directPay.UserInfoForm.nationality')}
                    </label>
                    <InputWithErrors path="nationality" inputErrors={inputErrors} render={this.renderNationality} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button type="button" className="btn btn-link w-100" onClick={this.handleBack}>
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button type="submit" form="direct-pay-user-info" className="btn btn-koosmik w-100">
                  {trans('directPay.UserInfoForm.next')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
