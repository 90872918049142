import { CURRENCY_EUR, CURRENCY_XOF } from '../../base/tools/currency-tools';

export const AREA_EURO = 'EURO';
export const AREA_AFRI = 'AFRI';

type TYPE_AREA_EURO = typeof AREA_EURO;
type TYPE_AREA_AFRI = typeof AREA_AFRI;
export type TYPE_AREA = TYPE_AREA_EURO | TYPE_AREA_AFRI;

/**
 * Return supported currency for an area
 */
export function getSupportedCurrency(area: TYPE_AREA) {
  switch (area) {
    case AREA_AFRI:
      return CURRENCY_XOF;
    case AREA_EURO:
    default:
      return CURRENCY_EUR;
  }
}

export function getDefaultCountry(area: TYPE_AREA) {
  switch (area) {
    case AREA_AFRI:
      return 'TG';
    case AREA_EURO:
    default:
      return 'FR';
  }
}

export function doesAreaSupportDirectPay(area: TYPE_AREA) {
  switch (area) {
    case AREA_EURO:
      return true;
    case AREA_AFRI:
    default:
      return false;
  }
}

export function doesAreaSupportRegister(area: TYPE_AREA) {
  switch (area) {
    case AREA_AFRI:
      return true;
    case AREA_EURO:
    default:
      return false;
  }
}

export function doesAreaSupportMilesPayment(area: TYPE_AREA) {
  switch (area) {
    case AREA_AFRI:
      return true;
    case AREA_EURO:
    default:
      return false;
  }
}

export function doesAreaSupportCard(area: TYPE_AREA) {
  switch (area) {
    case AREA_EURO:
      return true;
    case AREA_AFRI:
    default:
      return false;
  }
}

export function doesAreaSupportThirdPartyPayIn(area: TYPE_AREA) {
  switch (area) {
    case AREA_AFRI:
      return true;
    case AREA_EURO:
    default:
      return false;
  }
}

export function doesAreaSupportTelPayIn(area: TYPE_AREA, type: 'payment' | 'request') {
  switch (area) {
    case AREA_AFRI:
      return type === 'payment';
    case AREA_EURO:
    default:
      return false;
  }
}

export function doesAreaSupportNoAccount(area: TYPE_AREA) {
  switch (area) {
    case AREA_AFRI:
      return true;
    case AREA_EURO:
    default:
      return false;
  }
}

export function prioritizeEmailLogin(area: TYPE_AREA) {
  switch (area) {
    case AREA_EURO:
      return true;
    case AREA_AFRI:
    default:
      return false;
  }
}
