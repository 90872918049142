export default {
  REGISTER_NOT_SUPPORTED: "Vous devez vous incrire via l'application Koosmik",

  CREDENTIAL_INVALID: 'Mauvais mot de passe',
  LOGIN_SESSION_EXPIRED: 'Votre session a expiré. Veuillez réouvrir le formulaire de paiement.',
  LOGIN_CODE_WRONG: 'Code incorrect. Assurez-vous que le code saisi est le même que celui reçu',
  SMS_VERIFICATION_WRONG_CODE: 'Code incorrect. Assurez-vous que le code saisi est le même que celui reçu',
  LOGIN_CODE_TOO_MANY_ERRORS: 'Trop de tentatives qui ont échouées.',
  ACCOUNT_DISABLED: 'Ce compte est désactivé',
  INVALID_TOKEN: 'Votre session a expiré',
  INTERNAL_APPLICATION_ERROR: "Une erreur interne s'est produite",
  INPUT_INVALID: 'Le formulaire est invalide',
  ACCOUNT_BANNED: 'Ce compte est banni',
  ACCOUNT_STILL_REGISTERING: "Ce compte est en cours d'inscription. Veillez vérifier votre boîte mail pour valider",
  RESOURCE_NOT_FOUND: 'Ressource non trouvée. Veuillez réessayer après rechargement de la page.',
  MEMBER_NOT_MATCHING: "Votre compte payeur est différent du compte à l'origine de la transaction",
  TRADE_PAYMENT_EXPIRED: 'Ce paiement a expiré',
  AREA_INCOMPATIBLE: "Vous ne pouvez pas effectué de transaction avec un membre d'une autre zone géographique",

  PAYIN_CARD_DOES_NOT_SUPPORT_3D_SECURE:
    'La carte ne support pas le 3D secure. Pour les montants supérieurs à un certain montant, une carte 3D secure est requise',
  NOT_ENOUGH_BALANCE: "Vous n'avez pas assez d'argent dans votre compte pour cette opération",
  PAYIN_CARD_INVALID: 'La carte renseignée est invalide',
  SMS_VERIFICATION_EXPIRED: 'La session de validation par SMS a expiré',
  SMS_VERIFICATION_TOO_MANY_ERRORS: "Le nombre d'essais est dépassé",

  LIMIT_WALLET_MILES_AMOUNT: 'Vous ne pouvez pas effectué de transaction supérieur à {{amount}}',
  LIMIT_WALLET_MILES_WEEK_LIMIT:
    'Vous ne pouvez pas effectué plus de {{limit}} paiement par semaine via le wallet ou par miles',
  LIMIT_WALLET_MILES_MONTH_LIMIT:
    'Vous ne pouvez pas effectué plus de {{limit}} paiement par mois via le wallet ou par miles',
};
