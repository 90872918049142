import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import rootSagas from '../redux/root-sagas';
import rootReducers from '../redux/root-reducers';
import history from './history';

export default function initReduxStore() {
  let sagaMiddleware = null;
  let enhancer = null;

  if (process.env.NODE_ENV !== 'development') {
    sagaMiddleware = createSagaMiddleware();
    enhancer = compose(applyMiddleware(routerMiddleware(history), sagaMiddleware));
  } else {
    // eslint-disable-next-line global-require
    const reactotronConfig = require('../redux/config/reactotron-config').default;
    // eslint-disable-next-line global-require
    const loggerMiddleware = require('../redux/config/logger-config').default;

    const sagaMonitor = reactotronConfig.createSagaMonitor();

    sagaMiddleware = createSagaMiddleware({ sagaMonitor });
    enhancer = compose(
      applyMiddleware(routerMiddleware(history), sagaMiddleware, loggerMiddleware),
      reactotronConfig.createEnhancer(),
    );
  }

  const store = createStore(rootReducers, {}, enhancer);

  sagaMiddleware.run(rootSagas);

  return { store };
}
