import React from 'react';
import classNames from 'classnames';
import CardsState from '../../auth/redux/cards/cards-state';
import { trans } from '../../base/tools/translate-tools';
import { handleRadioChangeEvent } from '../../shared/tools/html-events-handler';
import iconMastercard from '../../../assets/images/icon-mastercard.svg';
import iconVisa from '../../../assets/images/icon-visa.svg';
import iconCc from '../../../assets/images/icon-cc.svg';

type CardId = 'new-card' | string | null;

interface Props {
  cardId: CardId;
  cards: CardsState;
  onCardChange: (cardId: CardId) => void;
  children: React.ReactNode;
}

export default class PaymentCardSelect extends React.PureComponent<Props> {
  handleCardChange = handleRadioChangeEvent(cardId => {
    const { onCardChange } = this.props;
    onCardChange(cardId);
  });

  renderCards() {
    const { cardId, cards } = this.props;

    return cards.map(card => {
      const inputId = `payment-card-${card.id}`;
      const selected = cardId === card.id;

      let cardIcon = null;
      if (card.provider === 'VISA') {
        cardIcon = <img src={iconVisa} width="32" alt="Visa" />;
      } else if (card.provider === 'MASTERCARD') {
        cardIcon = <img src={iconMastercard} width="32" alt="Mastercard" />;
      }

      return (
        <div className="payment-method-item-wrapper" key={card.id}>
          <input
            id={inputId}
            type="radio"
            name="cardId"
            value={card.id}
            checked={selected}
            onChange={this.handleCardChange}
          />
          <label htmlFor={inputId} className="payment-method-item payment-method-cc mb-3">
            <div className="icon">{cardIcon}</div>
            <div className="details">
              <div className="fs-7 mb-1 text-kblue">{card.label}</div>
              <div className="cc-number">{card.alias}</div>
            </div>
          </label>
        </div>
      );
    });
  }

  renderNewCard() {
    const { cardId, children } = this.props;

    return (
      <div className="payment-method-item-wrapper">
        <input
          id="payment-card-new"
          type="radio"
          name="cardId"
          value="new-card"
          checked={cardId === 'new-card'}
          onChange={this.handleCardChange}
        />
        <label
          htmlFor="payment-card-new"
          className="payment-method-item payment-method-add mb-3"
          role="button"
          aria-expanded={cardId === 'new-card'}
          aria-controls="ccFormContainer"
        >
          <div className="icon">
            <img src={iconCc} alt="New card" width="30" />
          </div>
          <div className="details">
            <div className="fs-6 text-kblue">{trans('dashboard.PaymentCardSelect.addCard')}</div>
          </div>
        </label>

        <div
          id="ccFormContainer"
          className={classNames('payment-method-add-cc mt-n3', { collapse: cardId !== 'new-card' })}
        >
          {cardId === 'new-card' ? children : null}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-12 my-3 text-center fw-bolder">{trans('dashboard.PaymentCardSelect.title')}</div>
        <div className="col-12">
          {this.renderCards()}
          {this.renderNewCard()}
        </div>
      </div>
    );
  }
}
