import { UserStateNotNull } from './user-state';

// Action Types

export const USER_ACTION_SET = 'USER_ACTION_SET';
export const USER_ACTION_CLEAR = 'USER_ACTION_CLEAR';

// Action creators definitions

// -- set

export interface UserActionSet {
  type: typeof USER_ACTION_SET;
  payload: UserStateNotNull;
}

// - user clear

export interface UserActionClear {
  type: typeof USER_ACTION_CLEAR;
}

// -- enum

export type UserActions = UserActionSet | UserActionClear;

// Action creators

export const userActionSet = (payload: UserStateNotNull): UserActionSet => ({
  type: USER_ACTION_SET,
  payload,
});

export const userActionClear = (): UserActionClear => ({
  type: USER_ACTION_CLEAR,
});
