import { call, delay, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import { APP_INPUT_ACTION_CLEAR, APP_INPUT_ACTION_SET, AppInputActionSet } from './app-input-actions';
import { apiPaymentDetails, ApiPaymentDetailsResponse } from '../../../../api/payments';
import { messagingService } from '../../services';
import RouteNames from '../../../../routes/RouteNames';
import { convertUnitToCent } from '../../tools/currency-tools';
import { authActionClear } from '../../../auth/redux/auth/auth-actions';
import initLocale from '../../../../config/init-locale';
import { cardsActionClear } from '../../../auth/redux/cards/cards-actions';
import { userActionClear } from '../../../auth/redux/user/user-actions';
import { apiRequestDetails, ApiRequestDetailsResponse } from '../../../../api/requests';
import { PaymentStateNotNull } from '../trade/payment-state';
import { RequestStateNotNull } from '../trade/request-state';
import { tradeActionClear, tradeActionSet } from '../trade/trade-actions';
import { TradeStateNotNull } from '../trade/trade-state';

function* loadPayment({ payload, resolve, reject }: AppInputActionSet) {
  try {
    const { success, data, errorCode }: ApiPaymentDetailsResponse = yield call(apiPaymentDetails, payload.id);
    if (success) {
      const payment: PaymentStateNotNull = { ...data, amount: convertUnitToCent(data.amount) };

      const trade: TradeStateNotNull = {
        type: 'payment',
        payment,
      };
      yield put(tradeActionSet(trade));

      if (data.status === 'pending') {
        yield put(routerActions.replace(RouteNames.cart));

        resolve(trade);
      } else {
        resolve(trade);

        if (data.successRedirectUrl) {
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        if (data.cancelRedirectUrl) {
          yield delay(3000);
          messagingService.notifyRedirect(data.cancelRedirectUrl, 'merchant');
          return;
        }
      }
    } else {
      reject({ errorCode });
    }
  } catch (e) {
    reject(e);
  }
}

function* loadRequest({ payload, resolve, reject }: AppInputActionSet) {
  try {
    const { success, data, errorCode }: ApiRequestDetailsResponse = yield call(apiRequestDetails, payload.id);
    if (success) {
      const request: RequestStateNotNull = { ...data, amount: convertUnitToCent(data.amount) };

      const trade: TradeStateNotNull = {
        type: 'request',
        request,
      };
      yield put(tradeActionSet(trade));

      if (data.status === 'requested') {
        yield put(routerActions.replace(RouteNames.cart));
      }
      resolve(trade);
    } else {
      reject({ errorCode });
    }
  } catch (e) {
    reject(e);
  }
}

function* appInputSet(action: AppInputActionSet) {
  const { payload } = action;
  yield initLocale(payload.locale);
  if (payload.type === 'payment') {
    yield call(loadPayment, action);
  } else {
    yield call(loadRequest, action);
  }
}

function* appInputClear() {
  messagingService.notifyAppClose();

  yield put(routerActions.replace(RouteNames.none));
  yield put(authActionClear());
  yield put(userActionClear());
  yield put(cardsActionClear());
  yield put(tradeActionClear());
}

export default function* appInputSaga() {
  yield takeLeading(APP_INPUT_ACTION_SET, appInputSet);
  yield takeEvery(APP_INPUT_ACTION_CLEAR, appInputClear);
}
