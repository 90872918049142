import RootState from '../../../../redux/root-state';
import TradeState, { TradeInfoData } from './trade-state';

export const tradeSelect = (state: RootState): TradeState => state.trade;

export const tradeInfoSelect = (state: RootState): TradeInfoData | null => {
  const trade = tradeSelect(state);
  if (trade) {
    const { type } = trade;
    if (trade.type === 'payment') {
      const { area, amount, currency, otherMember } = trade.payment;
      return {
        type,
        area,
        amount,
        currency,
        otherMember,
      };
    }

    const { area, amount, currency, otherMember } = trade.request;
    return {
      type,
      area,
      amount,
      currency,
      otherMember,
    };
  }
  return null;
};
