import React from 'react';
import classNames from 'classnames';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import {
  handleFormSubmitEvent,
  handleInputChangeEvent,
  handleSelectChangeEvent,
} from '../../shared/tools/html-events-handler';
import { trans } from '../../base/tools/translate-tools';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import AuthState from '../redux/auth/auth-state';
import RootState from '../../../redux/root-state';
import { authSelect } from '../redux/auth/auth-selectors';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import RouteNames from '../../../routes/RouteNames';
import PhoneCountrySelect from '../../shared/components/form/PhoneCountrySelect';
import { doesAreaSupportNoAccount, getDefaultCountry } from '../../shared/tools/area-tools';
import { tradeSelect } from '../../base/redux/trade/trade-selectors';
import { TradeStateNotNull } from '../../base/redux/trade/trade-state';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import { authActionDirectCheckPhone, AuthActionDirectCheckPhonePayload } from '../redux/auth/auth-direct-actions';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

type AuthDirectCheckPhone = (payload: AuthActionDirectCheckPhonePayload) => Promise<{}>;

interface Props {
  authDirectCheckPhone: AuthDirectCheckPhone;
  auth: AuthState;
  trade: TradeStateNotNull;
}

interface State {
  // form
  phoneCountry: string;
  phoneNumber: string;
  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
}

class AuthPhoneScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { auth, trade } = this.props;

    let phoneCountry = '';
    let phoneNumber = '';
    if (trade.type === 'payment') {
      const { payment } = trade;
      phoneCountry = getDefaultCountry(payment.area);
      if (payment.payerPrefillInfo.countryOfResidence) {
        phoneCountry = payment.payerPrefillInfo.countryOfResidence;
      }
      if (payment.payerPrefillInfo.phoneNumber) {
        phoneNumber = payment.payerPrefillInfo.phoneNumber;
      }
    } else {
      phoneCountry = getDefaultCountry(trade.request.area);
    }
    if (auth.phone) {
      phoneCountry = auth.phone.country;
      phoneNumber = auth.phone.number;
    }

    this.state = {
      phoneCountry,
      phoneNumber,
      inputErrors: null,
      isProcessing: false,
    };
  }

  handlePhoneCountry = handleSelectChangeEvent((phoneCountry: string) => this.setState({ phoneCountry }));

  handlePhoneNumber = handleInputChangeEvent((phoneNumber: string) => this.setState({ phoneNumber }));

  handleSubmit = handleFormSubmitEvent(() => {
    const { authDirectCheckPhone } = this.props;
    const { phoneCountry, phoneNumber, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ isProcessing: true, inputErrors: null });

    authDirectCheckPhone({ phone: { country: phoneCountry, number: phoneNumber } }).catch(
      actionRejectHandler(arg => {
        this.setState({
          isProcessing: false,
          inputErrors: arg.inputErrors || null,
        });
      }),
    );
  });

  renderPhoneCountry = () => {
    const { phoneCountry, isProcessing } = this.state;
    return (
      <PhoneCountrySelect
        id="phoneCountry"
        className="form-select"
        value={phoneCountry}
        disabled={isProcessing}
        onChange={this.handlePhoneCountry}
      />
    );
  };

  renderPhoneNumber = (hasError: boolean) => {
    const { phoneNumber, isProcessing } = this.state;

    return (
      <input
        type="phone"
        id="phoneNumber"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={phoneNumber}
        disabled={isProcessing}
        onChange={this.handlePhoneNumber}
        required
      />
    );
  };

  renderNoAccount() {
    const { trade } = this.props;

    if (trade.type === 'payment' && trade.payment.allowNoAccount && doesAreaSupportNoAccount(trade.payment.area)) {
      return (
        <div className="no-account modal-body">
          <div className="no-account-divider">
            <span className="divider-line" />
            <span className="divider-text">{trans('auth.AuthEmailScreen.noAccountOr')}</span>
            <span className="divider-line" />
          </div>
          <Link to={RouteNames.noAccount} className="btn w-100 btn-primary">
            {trans('auth.AuthPhoneScreen.noAccount')}
          </Link>
        </div>
      );
    }

    return null;
  }

  render() {
    const { isProcessing, inputErrors } = this.state;

    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="account" />
        <div className="modal-body">
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                <div className="d-flex justify-content-end">
                  <Link to={RouteNames.authEmail} replace className="fs-7 link-kblue">
                    {trans('auth.AuthPhoneScreen.byEmail')}
                  </Link>
                </div>
                <form onSubmit={this.handleSubmit} id="auth-phone-form">
                  <div className="mb-3">
                    <label htmlFor="phoneCountry" className="form-label">
                      {trans('auth.AuthPhoneScreen.phoneCountry')}
                    </label>
                    {this.renderPhoneCountry()}
                  </div>
                  <div className="mb-0">
                    <label htmlFor="phoneNumber" className="form-label">
                      {trans('auth.AuthPhoneScreen.phoneNumber')}
                    </label>
                    <InputWithErrors path="phoneNumber" inputErrors={inputErrors} render={this.renderPhoneNumber} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <Link className="btn btn-link w-100" to={RouteNames.cart}>
                  {trans('global.back')}
                </Link>
              </div>
              <div className="col-7 col-sm-6">
                <button type="submit" className="btn btn-koosmik w-100" disabled={isProcessing} form="auth-phone-form">
                  <ButtonSpinner showSpinner={isProcessing}>{trans('auth.AuthPhoneScreen.next')}</ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.renderNoAccount()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: authSelect(state),
  trade: tradeSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  authDirectCheckPhone: AuthDirectCheckPhone;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  authDirectCheckPhone: payload =>
    new Promise((resolve, reject) => dispatch(authActionDirectCheckPhone(payload, resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthPhoneScreen);
