import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ErrorEmbedded from './ErrorEmbedded';
import { appInputActionSet } from '../redux/app-input/app-input-actions';
import { AppInputStateNotNull, AppInputStateOption } from '../redux/app-input/app-input-state';
import { isInIframe } from '../tools/security';
import { messagingService } from '../services';
import { actionRejectHandlerSimple } from '../tools/action-promise-handler';
import { trans } from '../tools/translate-tools';
import { TradeStateNotNull } from '../redux/trade/trade-state';

type AppInputSet = (payload: AppInputStateNotNull) => Promise<TradeStateNotNull>;

interface Props {
  appInputSet: AppInputSet;
  children: React.ReactNode;
}

interface State {
  errorTitle: string;
  errorMessage: string | null;
}

/**
 * Wait for messaging, expect a payment id, and the lang
 */
class AppInputGateComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorTitle: trans('base.AppInputGateComponent.loadingFailed'),
      errorMessage: null,
    };
  }

  processResolve = (payload: TradeStateNotNull) => {
    if (payload.type === 'payment') {
      const { payment } = payload;
      if (payment.status === 'pending') {
        return;
      }

      let errorMessage = trans('base.AppInputGateComponent.statusFailed');

      if (payment.status === 'paid') {
        errorMessage = trans('base.AppInputGateComponent.alreadyPaid');
      } else if (payment.status === 'expired') {
        errorMessage = trans('base.AppInputGateComponent.statusExpired');
      }

      this.setState({
        errorTitle: trans('base.AppInputGateComponent.statusNotPending'),
        errorMessage,
      });
    } else {
      const { request } = payload;
      if (request.status === 'requested') {
        return;
      }

      let errorMessage = trans('base.AppInputGateComponent.statusFailed');

      if (request.status === 'accepted') {
        errorMessage = trans('base.AppInputGateComponent.alreadyPaid');
      }

      this.setState({
        errorTitle: trans('base.AppInputGateComponent.statusNotPending'),
        errorMessage,
      });
    }
  };

  parseOptions = (optionsStr: string): AppInputStateOption => {
    const options: AppInputStateOption = {
      noClose: false,
    };

    if (!optionsStr) {
      return options;
    }

    try {
      JSON.parse(optionsStr, (name, value) => {
        if (name === 'noClose') {
          options.noClose = value || false;
        }
      });
    } catch (e) {
      // do nothing
    }

    return options;
  };

  componentDidMount(): void {
    const { appInputSet } = this.props;

    messagingService.onInitialize(data => {
      const type = data.type || 'payment';
      appInputSet({
        type,
        id: data.id,
        locale: data.locale,
        options: this.parseOptions(data.options),
      })
        .then(this.processResolve)
        .catch(actionRejectHandlerSimple(errorMessage => this.setState({ errorMessage })));
    });

    if (process.env.NODE_ENV === 'development') {
      if (!isInIframe() && process.env.REACT_APP_TOKEN) {
        appInputSet({
          type: process.env.REACT_APP_TYPE === 'request' ? 'request' : 'payment',
          id: process.env.REACT_APP_TOKEN || '',
          locale: process.env.REACT_APP_LOCALE || 'fr',
          options: this.parseOptions('{}'),
        })
          .then(this.processResolve)
          .catch(actionRejectHandlerSimple(errorMessage => this.setState({ errorMessage })));
      }
    }
  }

  render(): React.ReactNode {
    const { errorTitle, errorMessage } = this.state;

    if (errorMessage) {
      return <ErrorEmbedded title={errorTitle} message={errorMessage} />;
    }

    const { children } = this.props;
    return children;
  }
}

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  appInputSet: AppInputSet;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  appInputSet: payload => new Promise((resolve, reject) => dispatch(appInputActionSet(payload, resolve, reject))),
});

export default connect(
  null,
  mapDispatchToProps,
)(AppInputGateComponent);
