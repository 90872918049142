import NotificationsState from './notifications-state';
import { NOTIFICATIONS_ACTION_ADD, NOTIFICATIONS_ACTION_CLEAR, NotificationsActions } from './notifications-actions';

const initialState: NotificationsState = [];

export default function notificationsReducer(
  state: NotificationsState = initialState,
  action: NotificationsActions,
): NotificationsState {
  switch (action.type) {
    case NOTIFICATIONS_ACTION_ADD:
      return [action.payload, ...initialState];
    case NOTIFICATIONS_ACTION_CLEAR:
      return initialState;
    default:
      return state;
  }
}
