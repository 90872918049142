import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { RouteComponentProps } from 'react-router-dom';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import { handleFormSubmitEvent } from '../../shared/tools/html-events-handler';
import { trans } from '../../base/tools/translate-tools';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import RouteNames from '../../../routes/RouteNames';
import {
  registerActionValidatePhoneNumber,
  RegisterActionValidatePhoneNumberPayload,
} from '../redux/register/register-actions';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import RootState from '../../../redux/root-state';
import { registerSelect } from '../redux/register/register-selectors';
import RegisterState from '../redux/register/register-state';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import { renderPhoneNumberObstructed } from '../../shared/tools/phone-tools';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

type RegisterValidatePhoneNumber = (payload: RegisterActionValidatePhoneNumberPayload) => Promise<{}>;

interface Props extends RouteComponentProps {
  register: RegisterState;
  registerValidatePhoneNumber: RegisterValidatePhoneNumber;
}

interface State {
  // form
  code: string;
  // for code input refresh and check
  key: number;
  codeInvalid: boolean;
  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
  // too many error
  tooManyErrors: boolean;
}

class RegisterPhoneValidationScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      code: '',
      codeInvalid: true,
      key: 1,
      inputErrors: null,
      isProcessing: false,
      tooManyErrors: false,
    };
  }

  handleCode = (code: string) =>
    this.setState({
      code,
      codeInvalid: code.replace(/[^0-9]/g, '').length < 6,
    });

  handleSubmit = handleFormSubmitEvent(() => {
    const { registerValidatePhoneNumber } = this.props;
    const { code, key, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ isProcessing: true, inputErrors: null });

    registerValidatePhoneNumber({ code }).catch(
      actionRejectHandler(arg => {
        this.setState({
          isProcessing: false,
          inputErrors: arg.inputErrors || null,
          code: '',
          key: key + 1,
        });

        if (arg.errorCode === 'LOGIN_CODE_TOO_MANY_ERRORS') {
          this.setState({ tooManyErrors: true });
        }
        if (arg.errorCode === 'SMS_VERIFICATION_EXPIRED') {
          this.setState({ tooManyErrors: true });
        }
      }),
    );
  });

  handleBackClick = () => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    const { history } = this.props;
    history.replace(RouteNames.authPhone);
  };

  renderCode = (hasError: boolean) => {
    const { code, key } = this.state;

    return (
      <ReactCodeInput
        fields={6}
        inputMode="numeric"
        name="code"
        pattern="[0-9]*"
        isValid={!hasError}
        onChange={this.handleCode}
        key={key}
        value={code}
      />
    );
  };

  renderContent() {
    const { tooManyErrors } = this.state;
    if (tooManyErrors) {
      return null;
    }

    const { inputErrors } = this.state;
    const { register } = this.props;

    return (
      <div>
        <div className="d-flex flex-column align-items-center help-text mb-3">
          {trans('register.RegisterPhoneValidationScreen.text', {
            phoneNumber: renderPhoneNumberObstructed(register.country || '', register.value || ''),
          })}
        </div>

        <form className="content-area" id="form-register-validation" onSubmit={this.handleSubmit}>
          <fieldset className="number-code">
            <legend>Code de confirmation</legend>
            <div className="my-1 d-flex flex-wrap">
              <InputWithErrors path="code" inputErrors={inputErrors} render={this.renderCode} />
            </div>
          </fieldset>
        </form>
      </div>
    );
  }

  render() {
    const { isProcessing, tooManyErrors, codeInvalid } = this.state;

    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="account" />

        <div className="modal-body">
          <h2 className="modal-heading text-center p-2 mb-3">
            {trans('register.RegisterPhoneValidationScreen.title')}
          </h2>
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                {this.renderContent()}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button type="button" className="btn btn-link w-100" onClick={this.handleBackClick}>
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button
                  type="submit"
                  className="btn btn-koosmik w-100"
                  disabled={isProcessing || tooManyErrors || codeInvalid}
                  form="form-register-validation"
                >
                  <ButtonSpinner showSpinner={isProcessing}>
                    {trans('register.RegisterInfoScreen.submit')}
                  </ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  register: registerSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  registerValidatePhoneNumber: RegisterValidatePhoneNumber;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  registerValidatePhoneNumber: payload =>
    new Promise((resolve, reject) => dispatch(registerActionValidatePhoneNumber(payload, resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterPhoneValidationScreen);
