import React from 'react';
import SuccessEmbedded from '../components/SuccessEmbedded';
import { trans } from '../tools/translate-tools';

export default class RedirectSuccessScreen extends React.PureComponent {
  render() {
    return (
      <SuccessEmbedded
        title={trans('base.RedirectSuccessScreen.title')}
        message={trans('base.RedirectSuccessScreen.message')}
      />
    );
  }
}
