import UserState from './user-state';
import { USER_ACTION_CLEAR, USER_ACTION_SET, UserActions } from './user-actions';

const initialState: UserState = null;

export default function userReducer(state: UserState = initialState, action: UserActions): UserState {
  switch (action.type) {
    case USER_ACTION_SET:
      return action.payload;
    case USER_ACTION_CLEAR:
      return initialState;
    default:
      return state;
  }
}
