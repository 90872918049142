import moment from 'moment';
import reactWidgetsMoment from 'react-widgets-moment';
import i18next from 'i18next';

let currentLocale = '';

export const getLocale = () => currentLocale;

export default function initLocale(l: string) {
  // fallback
  let locale = 'fr';
  if (l.startsWith('en')) {
    locale = 'en';
  }

  currentLocale = locale;
  moment.locale(locale);
  reactWidgetsMoment();
  return i18next.changeLanguage(locale);
}
