import { ApiDefaultResponse, ApiOtherMember } from './api-typed';
import { apiAuthPost, apiGet } from './api-call';
import { TYPE_AREA } from '../modules/shared/tools/area-tools';

// request details

export interface ApiRequestDetailsResponseData {
  id: string;
  amount: string;
  currency: string;
  area: TYPE_AREA;
  label: string;
  otherMember: ApiOtherMember;
  status: 'requested' | 'accepted' | 'refused' | 'processing' | 'cancelled';
}

export interface ApiRequestDetailsResponse extends ApiDefaultResponse<ApiRequestDetailsResponseData> {}

export const apiRequestDetails = (id: string): Promise<ApiRequestDetailsResponse> =>
  apiGet<ApiRequestDetailsResponse>(`embedded/v1/requests/request/${id}`);

// pay with wallet

export interface ApiRequestPayResponseData {
  successRedirectUrl?: string;
  failureRedirectUrl?: string;
}

export interface ApiRequestPayResponse extends ApiDefaultResponse<ApiRequestPayResponseData> {}

export const apiRequestPay = (id: string): Promise<ApiRequestPayResponse> =>
  apiAuthPost<ApiRequestPayResponse>(`embedded/v1/requests/pay/${id}`, {});

// pay with a card

export interface ApiRequestPayWithCardPayload {
  id: string;
  card: string;
  cardPayInAmount: string;
}

export interface ApiRequestPayWithCardResponseData {
  successRedirectUrl?: string;
  failureRedirectUrl?: string;
  redirectUrl?: string;
}

export interface ApiRequestPayWithCardResponse extends ApiDefaultResponse<ApiRequestPayWithCardResponseData> {}

export const apiRequestPayWithCard = ({
  id,
  ...data
}: ApiRequestPayWithCardPayload): Promise<ApiRequestPayWithCardResponse> =>
  apiAuthPost<ApiRequestPayWithCardResponse>(`embedded/v1/requests/pay-in/${id}`, data);

// pay third party

export interface ApiRequestPayWithThirdPartyPayload {
  id: string;
}

export interface ApiRequestPayWithThirdPartyResponseData {
  successRedirectUrl?: string;
  failureRedirectUrl?: string;
  redirectUrl?: string;
}

export interface ApiRequestPayWithThirdPartyResponse
  extends ApiDefaultResponse<ApiRequestPayWithThirdPartyResponseData> {}

export const apiRequestPayWithThirdParty = ({
  id,
  ...data
}: ApiRequestPayWithThirdPartyPayload): Promise<ApiRequestPayWithThirdPartyResponse> =>
  apiAuthPost<ApiRequestPayWithThirdPartyResponse>(`embedded/v1/requests/pay-in/3rd-party/${id}`, data);
