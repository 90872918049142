import { isInIframe } from './security';

/**
 * Post message to parent window
 */
export const postMessage = (data: object): void => {
  if (isInIframe()) {
    window.parent.postMessage(JSON.stringify(data), '*');
  }
};

/**
 * List of message event key
 */
export const MessagingEvents = {
  // receiving
  initialize: 'initialize',

  // sending
  ready: 'ready',
  redirect: 'redirect',
  error: 'error',
  close: 'close',
};

interface MessageType {
  key: string;
  data: { [k: string]: string };
}

/**
 * Add a listener to event sent by parent window
 */
export const addMessageListener = (listener: (data: MessageType) => void): void => {
  window.addEventListener('message', (ev: MessageEvent) => {
    if (ev.source === window.parent && isInIframe()) {
      const message: MessageType = JSON.parse(ev.data);
      listener(message);
    }
  });
};
