export default {
  REGISTER_NOT_SUPPORTED: 'You must register with the mobile application',

  CREDENTIAL_INVALID: 'Password wrong',
  LOGIN_SESSION_EXPIRED: 'Your session has expired. Please try again opening the payment form.',
  LOGIN_CODE_WRONG: 'Invalid code. Please make sure that the code you provided is not wrong',
  SMS_VERIFICATION_WRONG_CODE: 'Invalid code. Please make sure that the code you provided is not wrong',
  LOGIN_CODE_TOO_MANY_ERRORS: 'Too many wrong attempts.',
  ACCOUNT_DISABLED: 'This account is disabled.',
  INVALID_TOKEN: 'Your session have expired',
  INTERNAL_APPLICATION_ERROR: 'An internal error occured. Please try again and contact us if it persists',
  INPUT_INVALID: 'The form input is invalid',
  ACCOUNT_BANNED: 'The account is banned',
  ACCOUNT_STILL_REGISTERING: 'Your account is still in registration. Please check your mailbox to validate',
  RESOURCE_NOT_FOUND: 'The payment you asked does not exists or was already payed',
  MEMBER_NOT_MATCHING: 'Your current account is different from the one that created this transaction',
  TRADE_PAYMENT_EXPIRED: 'This payment has expired',
  AREA_INCOMPATIBLE: 'You can do transaction with someone in another area',

  PAYIN_CARD_DOES_NOT_SUPPORT_3D_SECURE:
    'This card does not support 3D secure. We require a card supporting 3D secure for this amount',
  NOT_ENOUGH_BALANCE: "You don't posses enough money in your wallet",
  PAYIN_CARD_INVALID: 'The card you provided is invalid',
  SMS_VERIFICATION_EXPIRED: 'Your SMS validation session expired',
  SMS_VERIFICATION_TOO_MANY_ERRORS: 'Too many wrong attempts',

  LIMIT_WALLET_MILES_AMOUNT: "You can't do transaction greater than {{amount}} with your miles or wallet",
  LIMIT_WALLET_MILES_WEEK_LIMIT: 'You can only do {{limit}} payment by week with your miles or wallet',
  LIMIT_WALLET_MILES_MONTH_LIMIT: 'You can only do {{limit}} payment by month with your miles or wallet',
};
