import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import RootState from './root-state';
import history from '../config/history';
import appInputReducer from '../modules/base/redux/app-input/app-input-reducer';
import notificationsReducer from '../modules/base/redux/notifications/notifications-reducer';
import authReducer from '../modules/auth/redux/auth/auth-reducer';
import userReducer from '../modules/auth/redux/user/user-reducer';
import registerReducer from '../modules/register/redux/register/register-reducer';
import cardsReducer from '../modules/auth/redux/cards/cards-reducer';
import tradeReducer from '../modules/base/redux/trade/trade-reducer';

export default combineReducers<RootState>({
  router: connectRouter(history),
  appInput: appInputReducer,
  notifications: notificationsReducer,
  auth: authReducer,
  user: userReducer,
  register: registerReducer,
  cards: cardsReducer,
  trade: tradeReducer,
});
