import translation from './translation-en';
import errorCode from './error-code-en';
import formError from './form-error-en';
import country from './country-en';

export default {
  translation,
  errorCode,
  country,
  formError,
};
