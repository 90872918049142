import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import RootState from '../../../redux/root-state';
import { notificationsSelect } from '../redux/notifications/notifications-selectors';
import NotificationsState from '../redux/notifications/notifications-state';

interface Props {
  notifications: NotificationsState;
}

const PaymentErrorsComponent: React.FC<Props> = (props: Props): ReactElement | null => {
  const { notifications } = props;
  if (notifications.length === 0) {
    return null;
  }

  const children = notifications.map(({ type, message }, index) => (
    <div className={`alert alert-${type} py-2 fs-7`} key={String(index)}>
      {message}
    </div>
  ));

  return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
  notifications: notificationsSelect(state),
});

export default connect(mapStateToProps)(PaymentErrorsComponent);
