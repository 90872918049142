// Types

import { ActionReject, ActionResolve } from '../../../base/tools/action-promise-types';

export const DIRECT_PAY_ACTION_SUBMIT = 'DIRECT_PAY_ACTION_SUBMIT';

// Actions creators definitions

// -- Direct pay

export interface DirectPayActionSubmitPayload {
  // userInfo
  email: string;
  firstName: string;
  lastName: string;
  countryOfResidence: string;
  nationality: string;
  birthday: string;
  lang: string;

  // CardInfo
  cardNumber: string;
  cardCvx: string;
  cardExpirationDate: string;
}

export interface DirectPayActionSubmit {
  type: typeof DIRECT_PAY_ACTION_SUBMIT;
  payload: DirectPayActionSubmitPayload;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- enum

export type DirectPayActions = DirectPayActionSubmit;

// Action creators

export const directPayActionSubmit = (
  payload: DirectPayActionSubmitPayload,
  resolve: ActionResolve,
  reject: ActionReject,
): DirectPayActionSubmit => ({
  type: DIRECT_PAY_ACTION_SUBMIT,
  payload,
  resolve,
  reject,
});
