import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteNames from './RouteNames';
import AuthEmailScreen from '../modules/auth/screens/AuthEmailScreen';
import AuthPhoneScreen from '../modules/auth/screens/AuthPhoneScreen';
import AuthValidationScreen from '../modules/auth/screens/AuthValidationScreen';
import CartScreen from '../modules/base/screens/CartScreen';
import HomeScreen from '../modules/dashboard/screens/HomeScreen';
import DirectPayScreen from '../modules/direct-pay/screens/DirectPayScreen';
import RegisterInfoScreen from '../modules/register/screens/RegisterInfoScreen';
import RegisterPhoneValidationScreen from '../modules/register/screens/RegisterPhoneValidationScreen';
import RegisterAfriScreen from '../modules/register/screens/RegisterAfriScreen';
import NoAccountScreen from '../modules/no-account/screens/NoAccountScreen';
import AuthPasswordScreen from '../modules/auth/screens/AuthPasswordScreen';

export default class PaymentRoutesComponent extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={RouteNames.cart} component={CartScreen} />
        <Route exact path={RouteNames.authEmail} component={AuthEmailScreen} />
        <Route exact path={RouteNames.authPhone} component={AuthPhoneScreen} />
        <Route exact path={RouteNames.authValidation} component={AuthValidationScreen} />
        <Route exact path={RouteNames.authPassword} component={AuthPasswordScreen} />
        <Route exact path={RouteNames.register} component={RegisterInfoScreen} />
        <Route exact path={RouteNames.registerPhoneValidation} component={RegisterPhoneValidationScreen} />
        <Route exact path={RouteNames.registerAfri} component={RegisterAfriScreen} />
        <Route exact path={RouteNames.directPay} component={DirectPayScreen} />
        <Route exact path={RouteNames.dashboardHome} component={HomeScreen} />
        <Route exact path={RouteNames.noAccount} component={NoAccountScreen} />
      </Switch>
    );
  }
}
