import React from 'react';
import { UserStateNotNull } from '../../auth/redux/user/user-state';
import { trans } from '../../base/tools/translate-tools';
import { renderCurrency } from '../../base/tools/currency-tools';
import { TradeInfoData } from '../../base/redux/trade/trade-state';

interface Props {
  paymentMethod: 'koosmik' | 'miles' | 'new-card' | string;
  user: UserStateNotNull;
  tradeInfo: TradeInfoData;
}

export default class PaymentRecap extends React.PureComponent<Props> {
  render() {
    const { paymentMethod, user, tradeInfo } = this.props;

    const walletAmount = user.wallets[tradeInfo.currency] || 0;
    const difference = walletAmount - tradeInfo.amount;

    if (paymentMethod === 'koosmik') {
      return (
        <div className="payment-table">
          <div className="payment-table-row d-flex justify-content-between">
            <div className="th">{trans('dashboard.PaymentRecap.amount')}</div>
            <div className="td">{renderCurrency(walletAmount, tradeInfo.currency)}</div>
          </div>
          <div className="payment-table-row d-flex justify-content-between">
            <div className="th">{trans('dashboard.PaymentRecap.amountToPay')}</div>
            <div className="td negative">{renderCurrency(tradeInfo.amount, tradeInfo.currency)}</div>
          </div>
          <div className="payment-table-row d-flex justify-content-between">
            <div className="th">{trans('dashboard.PaymentRecap.amountLeft')}</div>
            <div className="td">{renderCurrency(difference, tradeInfo.currency)}</div>
          </div>
        </div>
      );
    }
    if (paymentMethod === 'miles' && user.miles && user.miles.conversion[tradeInfo.currency]) {
      const conversionRate: [number, number] = user.miles.conversion[tradeInfo.currency];
      const equivalent = Math.ceil((tradeInfo.amount * conversionRate[0]) / conversionRate[1]);

      return (
        <div className="payment-table">
          <div className="payment-table-row d-flex justify-content-between">
            <div className="th">{trans('dashboard.PaymentRecap.amountToPay')}</div>
            <div className="td negative">{renderCurrency(tradeInfo.amount, tradeInfo.currency)}</div>
          </div>
          <div className="payment-table-row d-flex justify-content-between">
            <div className="th">{trans('dashboard.PaymentRecap.miles')}</div>
            <div className="td miles-line">{user.miles.amount}</div>
          </div>
          <div className="payment-table-row d-flex justify-content-between">
            <div className="th">{trans('dashboard.PaymentRecap.milesEquivalent')}</div>
            <div className="td miles-line negative">{equivalent}</div>
          </div>
          <div className="payment-table-row d-flex justify-content-between">
            <div className="th">{trans('dashboard.PaymentRecap.milesRemaining')}</div>
            <div className="td miles-line">{user.miles.amount - equivalent}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="payment-table">
        <div className="payment-table-row d-flex justify-content-between">
          <div className="th">{trans('dashboard.PaymentRecap.amountToPay')}</div>
          <div className="td negative">{renderCurrency(tradeInfo.amount, tradeInfo.currency)}</div>
        </div>
      </div>
    );
  }
}
