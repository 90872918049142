import { put, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { notificationsActionClear } from '../notifications/notifications-actions';

function* locationChanged() {
  yield put(notificationsActionClear());
}

export default function* routerSaga() {
  yield takeEvery(LOCATION_CHANGE, locationChanged);
}
