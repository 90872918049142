import { ActionReject, ActionResolve } from '../../../base/tools/action-promise-types';

// Action Types

export const REGISTER_ACTION_SET_PHONE_NUMBER = 'REGISTER_ACTION_SET_PHONE_NUMBER';
export const REGISTER_ACTION_ADD_PHONE_NUMBER = 'REGISTER_ACTION_ADD_PHONE_NUMBER';
export const REGISTER_ACTION_SET_PHONE_NUMBER_ID = 'REGISTER_ACTION_SET_PHONE_NUMBER_ID';
export const REGISTER_ACTION_VALIDATE_PHONE_NUMBER = 'REGISTER_ACTION_VALIDATE_PHONE_NUMBER';
export const REGISTER_ACTION_SUBMIT_AFRI = 'REGISTER_ACTION_SUBMIT_AFRI';
export const REGISTER_ACTION_CLEAR = 'REGISTER_ACTION_CLEAR';

// Action creators definitions

// -- set phone number

export interface RegisterActionSetPhoneNumberPayload {
  country: string;
  value: string;
}

export interface RegisterActionSetPhoneNumber {
  type: typeof REGISTER_ACTION_SET_PHONE_NUMBER;
  payload: RegisterActionSetPhoneNumberPayload;
}

// -- set phone number

export interface RegisterActionAddPhoneNumber {
  type: typeof REGISTER_ACTION_ADD_PHONE_NUMBER;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- set phone number id

export interface RegisterActionSetPhoneNumberIdPayload {
  phoneNumberId: string;
}

export interface RegisterActionSetPhoneNumberId {
  type: typeof REGISTER_ACTION_SET_PHONE_NUMBER_ID;
  payload: RegisterActionSetPhoneNumberIdPayload;
}

// -- validate phone number

export interface RegisterActionValidatePhoneNumberPayload {
  code: string;
}

export interface RegisterActionValidatePhoneNumber {
  type: typeof REGISTER_ACTION_VALIDATE_PHONE_NUMBER;
  payload: RegisterActionValidatePhoneNumberPayload;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- register submit afri

export interface RegisterActionSubmitAfriPayload {
  gender: 'M' | 'F';
  firstName: string;
  lastName: string;
  password: string;
  birthday: string;
  birthplace: string;
  countryOfResidence: string;
  nationality: string;
}

export interface RegisterActionSubmitAfri {
  type: typeof REGISTER_ACTION_SUBMIT_AFRI;
  payload: RegisterActionSubmitAfriPayload;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- register clear

export interface RegisterActionClear {
  type: typeof REGISTER_ACTION_CLEAR;
}

// -- enum

export type RegisterActions =
  | RegisterActionSetPhoneNumber
  | RegisterActionAddPhoneNumber
  | RegisterActionSetPhoneNumberId
  | RegisterActionValidatePhoneNumber
  | RegisterActionSubmitAfri
  | RegisterActionClear;

// Action creators

export const registerActionSetPhoneNumber = (
  payload: RegisterActionSetPhoneNumberPayload,
): RegisterActionSetPhoneNumber => ({
  type: REGISTER_ACTION_SET_PHONE_NUMBER,
  payload,
});

export const registerActionAddPhoneNumber = (
  resolve: ActionResolve,
  reject: ActionReject,
): RegisterActionAddPhoneNumber => ({
  type: REGISTER_ACTION_ADD_PHONE_NUMBER,
  resolve,
  reject,
});

export const registerActionSetPhoneNumberId = (
  payload: RegisterActionSetPhoneNumberIdPayload,
): RegisterActionSetPhoneNumberId => ({
  type: REGISTER_ACTION_SET_PHONE_NUMBER_ID,
  payload,
});

export const registerActionValidatePhoneNumber = (
  payload: RegisterActionValidatePhoneNumberPayload,
  resolve: ActionResolve,
  reject: ActionReject,
): RegisterActionValidatePhoneNumber => ({
  type: REGISTER_ACTION_VALIDATE_PHONE_NUMBER,
  payload,
  resolve,
  reject,
});

export const registerActionSubmitAfri = (
  payload: RegisterActionSubmitAfriPayload,
  resolve: ActionResolve,
  reject: ActionReject,
): RegisterActionSubmitAfri => ({
  type: REGISTER_ACTION_SUBMIT_AFRI,
  payload,
  resolve,
  reject,
});

export const registerActionClear = (): RegisterActionClear => ({
  type: REGISTER_ACTION_CLEAR,
});
