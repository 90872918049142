import { ActionReject, ActionResolve } from '../../../base/tools/action-promise-types';
import { ApiAuthLoginValidateResponseData } from '../../../../api/auth';
import { AuthValidationState } from './auth-state';
import { AuthActionsDirect } from './auth-direct-actions';

// Action Types

export const AUTH_ACTION_LOGIN_EMAIL = 'AUTH_ACTION_LOGIN_EMAIL';
export const AUTH_ACTION_LOGIN_PHONE = 'AUTH_ACTION_LOGIN_PHONE';
export const AUTH_ACTION_LOGIN_SET_VALIDATE_DATA = 'AUTH_ACTION_LOGIN_SET_VALIDATE_DATA';
export const AUTH_ACTION_LOGIN_VALIDATE = 'AUTH_ACTION_LOGIN_VALIDATE';
export const AUTH_ACTION_SET_TOKEN = 'AUTH_ACTION_SET_TOKEN';
export const AUTH_ACTION_CLEAR = 'AUTH_ACTION_CLEAR';

// Action creators definitions

// -- login email

export interface AuthActionLoginEmailPayload {
  email: string;
}

export interface AuthActionLoginEmailResolvePayload {
  data: {
    next: 'login' | 'direct-pay';
  };
}

export interface AuthActionLoginEmail {
  type: typeof AUTH_ACTION_LOGIN_EMAIL;
  payload: AuthActionLoginEmailPayload;
  resolve: ActionResolve<AuthActionLoginEmailResolvePayload>;
  reject: ActionReject;
}

// -- login phone

export interface AuthActionLoginPhonePayload {
  phone: {
    country: string;
    number: string;
  };
}

export interface AuthActionLoginPhoneResolvePayload {
  data: {
    next: 'login' | 'register' | 'direct-pay';
  };
}

export interface AuthActionLoginPhone {
  type: typeof AUTH_ACTION_LOGIN_PHONE;
  payload: AuthActionLoginPhonePayload;
  resolve: ActionResolve<AuthActionLoginPhoneResolvePayload>;
  reject: ActionReject;
}

// -- login set validate data

export interface AuthActionLoginSetValidateData {
  type: typeof AUTH_ACTION_LOGIN_SET_VALIDATE_DATA;
  payload: AuthValidationState;
}

// -- login validate

export interface AuthActionLoginValidatePayload {
  code: string;
}

export interface AuthActionLoginValidateResolvePayload {
  data: ApiAuthLoginValidateResponseData;
}

export interface AuthActionLoginValidate {
  type: typeof AUTH_ACTION_LOGIN_VALIDATE;
  payload: AuthActionLoginValidatePayload;
  resolve: ActionResolve<AuthActionLoginValidateResolvePayload>;
  reject: ActionReject;
}

// - set token

export interface AuthActionSetToken {
  type: typeof AUTH_ACTION_SET_TOKEN;
  payload: string;
}

// - auth clear

export interface AuthActionClear {
  type: typeof AUTH_ACTION_CLEAR;
}

// -- enum

export type AuthActions =
  | AuthActionLoginEmail
  | AuthActionLoginPhone
  | AuthActionLoginSetValidateData
  | AuthActionLoginValidate
  | AuthActionSetToken
  | AuthActionClear
  | AuthActionsDirect;

// Action creators

export const authActionLoginEmail = (
  payload: AuthActionLoginEmailPayload,
  resolve: ActionResolve<AuthActionLoginEmailResolvePayload>,
  reject: ActionReject,
): AuthActionLoginEmail => ({
  type: AUTH_ACTION_LOGIN_EMAIL,
  payload,
  resolve,
  reject,
});

export const authActionLoginPhone = (
  payload: AuthActionLoginPhonePayload,
  resolve: ActionResolve<AuthActionLoginPhoneResolvePayload>,
  reject: ActionReject,
): AuthActionLoginPhone => ({
  type: AUTH_ACTION_LOGIN_PHONE,
  payload,
  resolve,
  reject,
});

export const authActionLoginSetValidateData = (payload: AuthValidationState): AuthActionLoginSetValidateData => ({
  type: AUTH_ACTION_LOGIN_SET_VALIDATE_DATA,
  payload,
});

export const authActionLoginEmailValidate = (
  payload: AuthActionLoginValidatePayload,
  resolve: ActionResolve<AuthActionLoginValidateResolvePayload>,
  reject: ActionReject,
): AuthActionLoginValidate => ({
  type: AUTH_ACTION_LOGIN_VALIDATE,
  payload,
  resolve,
  reject,
});

export const authActionSetToken = (payload: string): AuthActionSetToken => ({
  type: AUTH_ACTION_SET_TOKEN,
  payload,
});

export const authActionClear = (): AuthActionClear => ({
  type: AUTH_ACTION_CLEAR,
});
