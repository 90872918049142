import AppInputState from './app-input-state';
import { APP_INPUT_ACTION_CLEAR, APP_INPUT_ACTION_SET, AppInputActions } from './app-input-actions';

const initialState: AppInputState = null;

export default function appInputReducer(state: AppInputState = initialState, action: AppInputActions): AppInputState {
  switch (action.type) {
    case APP_INPUT_ACTION_SET:
      return action.payload;
    case APP_INPUT_ACTION_CLEAR:
      return initialState;
    default:
      return state;
  }
}
