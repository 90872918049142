import { ApiDefaultResponse, ApiResponseDataInputErrors } from './api-typed';
import { apiAuthGet, apiAuthPost } from './api-call';

// Get cards list

export interface ApiCard {
  id: string;
  label: string;
  alias: string;
  expiration: string;
  provider: string;
  type: string;
  currency: string;
  validity: string;
}

export interface ApiCardsResponseData {
  results: ApiCard[];
}

export interface ApiCardsResponse extends ApiDefaultResponse<ApiCardsResponseData> {}

export const apiCards = (): Promise<ApiCardsResponse> => apiAuthGet<ApiCardsResponse>('embedded/v1/cards');

// Add card

export interface ApiCardAddPayload {
  currency: string;
  cardNumber: string;
  cardCvx: string;
  cardExpirationDate: string;
  label: string;
}

export interface ApiCardAddResponseData {
  id: string;

  // input errors
  errors: ApiResponseDataInputErrors; // @watch : may be replaced by inputErrors in v2 api
}

export interface ApiCardAddResponse extends ApiDefaultResponse<ApiCardAddResponseData> {}

export const apiCardAdd = (payload: ApiCardAddPayload): Promise<ApiCardAddResponse> =>
  apiAuthPost<ApiCardAddResponse>('embedded/v1/cards/card', payload);
