import { all, call, delay, put, select, takeLeading } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import {
  AUTH_ACTION_LOGIN_EMAIL,
  AUTH_ACTION_LOGIN_PHONE,
  AUTH_ACTION_LOGIN_VALIDATE,
  AUTH_ACTION_SET_TOKEN,
  AuthActionLoginEmail,
  AuthActionLoginPhone,
  authActionLoginSetValidateData,
  AuthActionLoginValidate,
  AuthActionSetToken,
  authActionSetToken,
} from './auth-actions';
import {
  apiAuthLoginEmail,
  ApiAuthLoginEmailResponse,
  apiAuthLoginEmailValidate,
  apiAuthLoginPhone,
  ApiAuthLoginPhoneResponse,
  apiAuthLoginPhoneValidate,
  ApiAuthLoginValidateResponse,
} from '../../../../api/auth';
import { authSelect } from './auth-selectors';
import RouteNames from '../../../../routes/RouteNames';
import AuthState from './auth-state';
import { setAuthToken } from '../../../../api/api-call';
import { apiInfo, ApiInfoResponse } from '../../../../api/info';
import { userActionSet } from '../user/user-actions';
import { UserStateNotNull } from '../user/user-state';
import { convertUnitToCent } from '../../../base/tools/currency-tools';
import { notificationsActionAddDanger } from '../../../base/redux/notifications/notifications-actions';
import { trans, transErrorCode } from '../../../base/tools/translate-tools';
import { apiCards, ApiCardsResponse } from '../../../../api/cards';
import { cardsActionSet } from '../cards/cards-actions';
import { doesAreaSupportDirectPay, doesAreaSupportRegister } from '../../../shared/tools/area-tools';
import { registerActionSetPhoneNumber } from '../../../register/redux/register/register-actions';
import { TradeInfoData } from '../../../base/redux/trade/trade-state';
import { tradeInfoSelect } from '../../../base/redux/trade/trade-selectors';
import {
  AUTH_ACTION_DIRECT_CHECK_EMAIL,
  AUTH_ACTION_DIRECT_CHECK_PHONE,
  AUTH_ACTION_DIRECT_LOGIN_EMAIL,
  AUTH_ACTION_DIRECT_LOGIN_PHONE,
} from './auth-direct-actions';
import {
  authDirectCheckEmail,
  authDirectCheckPhone,
  authDirectLoginEmail,
  authDirectLoginPhone,
} from './auth-saga-direct';

function* authLoginEmail({ payload, resolve, reject }: AuthActionLoginEmail) {
  try {
    const { area }: TradeInfoData = yield select(tradeInfoSelect);

    const { success, data, errorCode }: ApiAuthLoginEmailResponse = yield call(apiAuthLoginEmail, {
      ...payload,
    });

    if (success) {
      yield put(authActionLoginSetValidateData(data));
      resolve({ data: { next: 'login' } });
      yield put(routerActions.replace(RouteNames.authValidation));
      return;
    }

    if (errorCode === 'ACCOUNT_NOT_EXISTS') {
      if (doesAreaSupportDirectPay(area)) {
        resolve({ data: { next: 'direct-pay' } });
        yield put(routerActions.replace(RouteNames.directPay));
        return;
      }

      if (doesAreaSupportRegister(area)) {
        yield put(routerActions.replace(RouteNames.authPhone));
        yield delay(500);
        yield put(notificationsActionAddDanger(trans('auth.saga.usePhoneNumberForRegister')));
        reject({
          errorCode: 'REGISTER_NOT_SUPPORTED',
        });
        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode('REGISTER_NOT_SUPPORTED')));
      reject({
        errorCode: 'REGISTER_NOT_SUPPORTED',
      });
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* authLoginPhone({ payload, resolve, reject }: AuthActionLoginPhone) {
  try {
    const { area }: TradeInfoData = yield select(tradeInfoSelect);

    const { success, data, errorCode }: ApiAuthLoginPhoneResponse = yield call(apiAuthLoginPhone, {
      phoneCountry: payload.phone.country,
      phoneNumber: payload.phone.number,
    });

    if (success) {
      yield put(authActionLoginSetValidateData(data));
      resolve({ data: { next: 'login' } });
      yield put(routerActions.replace(RouteNames.authValidation));
      return;
    }

    if (errorCode === 'ACCOUNT_NOT_EXISTS') {
      if (doesAreaSupportRegister(area)) {
        resolve({ data: { next: 'register' } });
        yield put(
          registerActionSetPhoneNumber({
            country: payload.phone.country,
            value: payload.phone.number,
          }),
        );
        yield put(routerActions.replace(RouteNames.register));
        return;
      }

      if (doesAreaSupportDirectPay(area)) {
        yield put(routerActions.replace(RouteNames.authEmail));
        yield delay(500);
        yield put(notificationsActionAddDanger(trans('auth.saga.useEmailForRegister')));
        reject({
          errorCode: 'REGISTER_NOT_SUPPORTED',
        });
        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode('REGISTER_NOT_SUPPORTED')));
      reject({
        errorCode: 'REGISTER_NOT_SUPPORTED',
      });
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* authLoginValidate({ payload, resolve, reject }: AuthActionLoginValidate) {
  try {
    const auth: AuthState = yield select(authSelect);

    if (auth.type === 'email') {
      const { success, data, errorCode }: ApiAuthLoginValidateResponse = yield call(apiAuthLoginEmailValidate, {
        ...payload,
        email: auth.email!,
      });

      if (success) {
        resolve({ data });
        yield put(authActionSetToken(data.token));
        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
        inputErrors: data.inputErrors,
      });
      return;
    }

    if (auth.type === 'phone') {
      const { success, data, errorCode }: ApiAuthLoginValidateResponse = yield call(apiAuthLoginPhoneValidate, {
        ...payload,
        phoneCountry: auth.phone!.country,
        phoneNumber: auth.phone!.number,
      });

      if (success) {
        resolve({ data });
        yield put(authActionSetToken(data.token));
        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
        inputErrors: data.inputErrors,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

function* loadUser() {
  const { success, data, errorCode }: ApiInfoResponse = yield call(apiInfo);

  if (success) {
    const wallets: { [key: string]: number } = {};
    data.wallets.forEach(wallet => {
      wallets[wallet.currency] = convertUnitToCent(wallet.amount);
    });

    const user: UserStateNotNull = {
      displayName: data.displayName,
      email: data.email,
      image: data.image,
      miles: data.miles,
      wallets,
    };
    yield put(userActionSet(user));

    return null;
  }

  return errorCode;
}

function* loadCards() {
  const { success, data, errorCode }: ApiCardsResponse = yield call(apiCards);

  if (success) {
    yield put(cardsActionSet(data.results));
    return null;
  }

  return errorCode;
}

function* authSetToken({ payload }: AuthActionSetToken) {
  yield put(routerActions.replace(RouteNames.none));

  setAuthToken(payload);

  const [errorCode1, errorCode2]: [string | null, string | null] = yield all([call(loadUser), call(loadCards)]);

  const errorCode = errorCode1 || errorCode2;
  if (!errorCode) {
    yield put(routerActions.replace(RouteNames.dashboardHome));
    return;
  }

  yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
  yield put(routerActions.replace(RouteNames.authEmail));
}

export default function* authSaga() {
  yield takeLeading(AUTH_ACTION_LOGIN_EMAIL, authLoginEmail);
  yield takeLeading(AUTH_ACTION_LOGIN_PHONE, authLoginPhone);
  yield takeLeading(AUTH_ACTION_LOGIN_VALIDATE, authLoginValidate);
  yield takeLeading(AUTH_ACTION_SET_TOKEN, authSetToken);
  yield takeLeading(AUTH_ACTION_DIRECT_CHECK_EMAIL, authDirectCheckEmail);
  yield takeLeading(AUTH_ACTION_DIRECT_LOGIN_EMAIL, authDirectLoginEmail);
  yield takeLeading(AUTH_ACTION_DIRECT_CHECK_PHONE, authDirectCheckPhone);
  yield takeLeading(AUTH_ACTION_DIRECT_LOGIN_PHONE, authDirectLoginPhone);
}
