import { ApiDefaultResponse, ApiResponseDataInputErrors } from './api-typed';
import { apiPost } from './api-call';

// Add phone number

export interface ApiRegisterPhoneNumberAddPayload {
  country: string;
  value: string;
}

export interface ApiRegisterPhoneNumberAddResponseData {
  id: string;
  obstructedValue: string;
  country: string;
  countryCode: string;
  isVerified: boolean;
  errors?: ApiResponseDataInputErrors; // @watch : may be replaced by inputErrors in v2 api
}

export interface ApiRegisterPhoneNumberAddResponse extends ApiDefaultResponse<ApiRegisterPhoneNumberAddResponseData> {}

export const apiRegisterPhoneNumberAdd = (
  data: ApiRegisterPhoneNumberAddPayload,
): Promise<ApiRegisterPhoneNumberAddResponse> =>
  apiPost<ApiRegisterPhoneNumberAddResponse>('embedded/v1/phonenumbers/phonenumber', data);

// Verify phone number

export interface ApiRegisterPhoneNumberVerifyPayload {
  phoneNumberId: string;
  code: string;
}

export interface ApiRegisterPhoneNumberVerifyResponse extends ApiDefaultResponse {}

export const apiRegisterPhoneNumberVerify = ({
  phoneNumberId,
  ...data
}: ApiRegisterPhoneNumberVerifyPayload): Promise<ApiRegisterPhoneNumberVerifyResponse> =>
  apiPost<ApiRegisterPhoneNumberVerifyResponse>(`embedded/v1/phonenumbers/phonenumber/${phoneNumberId}/verify`, data);

// Register afri

export interface ApiRegisterAfriPayload {
  gender: 'M' | 'F';
  firstName: string;
  lastName: string;
  password: string;
  birthday: string;
  birthplace: string;
  countryOfResidence: string;
  nationality: string;
  phoneNumberId: string;
  lang: string;
}

export interface ApiRegisterAfriResponseData {
  token: string;
  // input errors
  errors?: ApiResponseDataInputErrors; // @watch : may be replaced by inputErrors in v2 api
}

export interface ApiRegisterAfriResponse extends ApiDefaultResponse<ApiRegisterAfriResponseData> {}

export const apiRegisterAfri = (data: ApiRegisterAfriPayload): Promise<ApiRegisterAfriResponse> =>
  apiPost<ApiRegisterAfriResponse>('embedded/v1/register/afri', data);
