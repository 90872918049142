import { ApiDefaultResponse, ApiResponseDataInputErrors } from './api-typed';
import { apiPost } from './api-call';

// Direct pay payment

export interface ApiDirectPayPayload {
  paymentId: string; // paymentId from embed

  // user info
  email: string;
  firstName: string;
  lastName: string;
  countryOfResidence: string;
  nationality: string;
  birthday: string;
  lang: string;

  // card info
  cardNumber: string;
  cardCvx: string;
  cardExpirationDate: string;
}

export interface ApiDirectPayResponseData {
  // payment successful
  successRedirectUrl?: string;

  // payment failed
  failureRedirectUrl?: string;

  // payment cancel
  cancelRedirectUrl?: string;

  // 3d secure
  redirectUrl: string;

  // input errors
  errors: ApiResponseDataInputErrors; // @watch : may be replaced by inputErrors in v2 api
}

export interface ApiDirectPayResponse extends ApiDefaultResponse<ApiDirectPayResponseData> {}

export const apiDirectPay = ({ paymentId, ...payload }: ApiDirectPayPayload): Promise<ApiDirectPayResponse> =>
  apiPost<ApiDirectPayResponse>(`embedded/v1/payments/direct-pay/${paymentId}`, payload);

// Request direct pay

export interface ApiRequestDirectPayPayload {
  requestId: string; // requestId from embed

  // user info
  email: string;
  firstName: string;
  lastName: string;
  countryOfResidence: string;
  nationality: string;
  birthday: string;
  lang: string;

  // card info
  cardNumber: string;
  cardCvx: string;
  cardExpirationDate: string;
}

export interface ApiRequestDirectPayResponseData {
  successRedirectUrl?: string;
  failureRedirectUrl?: string;
  redirectUrl?: string;

  // input errors
  errors: ApiResponseDataInputErrors; // @watch : may be replaced by inputErrors in v2 api
}

export interface ApiRequestDirectPayResponse extends ApiDefaultResponse<ApiRequestDirectPayResponseData> {}

export const apiRequestDirectPay = ({
  requestId,
  ...payload
}: ApiRequestDirectPayPayload): Promise<ApiRequestDirectPayResponse> =>
  apiPost<ApiRequestDirectPayResponse>(`embedded/v1/requests/direct-pay/${requestId}`, payload);
