export default {
  global: {
    loading: 'Loading',
    back: 'Back',
  },
  date: {
    months: {
      month01: 'January',
      month02: 'February',
      month03: 'Mars',
      month04: 'April',
      month05: 'May',
      month06: 'June',
      month07: 'July',
      month08: 'August',
      month09: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'Décember',
    },
  },
  format: {
    dateOnly: 'MMMM DD, YYYY',
  },
  security: {
    forbidden: 'Access forbidden',
    directAccessForbidden: 'Direct access to this page is forbidden',
    domainForbidden: 'This application is not called with the correct domain',
  },
  form: {
    dateSelect: {
      selectDay: '- Day- ',
      selectMonth: '- Month -',
      selectYear: '- Year -',
    },
  },
  base: {
    AppInputGateComponent: {
      loadingFailed: 'Failure while loading transaction data',
      statusNotPending: 'Impossible to process this payment',
      statusFailed: 'This payment already failed. Please retry the process.',
      alreadyPaid: 'This payment is already paid',
      statusExpired: 'This payment has expired',
    },
    RedirectSuccessScreen: {
      title: 'Payment successful',
      message: "You will be redirected to the merchant's website",
    },
    Redirect3dSecureScreen: {
      title: 'Secure payment',
      message: "You will be redirected to your bank's website in order to validate your payment",
    },
    RedirectFailureScreen: {
      title: 'Payment failure',
      message: "You will be redirected to the merchant's website",
    },
    Redirect3rdPartyScreen: {
      title: 'Redirect',
      message: 'You will be redirect to a payment platform',
    },

    PaymentCartComponent: {
      amountToPay: 'Amount to pay :',
    },
    PaymentStepsComponent: {
      cart: 'Cart',
      account: 'Account',
      payment: 'Payment',
      confirmation: 'Confirmation',
    },
    CartScreen: {
      amountToPay: 'Amount to pay :',
      cancel: 'Cancel',
      submit: 'Pay',
    },
  },
  auth: {
    saga: {
      usePhoneNumberForRegister: 'No account found, please use a phone number to create an account',
    },
    AuthEmailScreen: {
      byPhone: 'By phone number',
      email: 'Email address',
      noAccount: 'I want to pay without creation a Koosmik account',
      noAccountOr: 'OR',
      next: 'Next',
    },
    AuthPhoneScreen: {
      byEmail: 'By email address',
      phoneCountry: 'Country',
      phoneNumber: 'Number',
      noAccount: 'I want to pay without creation a Koosmik account',
      next: 'Next',
    },
    AuthValidationScreen: {
      phoneTitle: 'Phone confirmation',
      emailTitle: 'Email confirmation',
      sendByMail: 'A mail was sent to {{email}}. Please enter the code within.',
      sendBySms: 'A sms was sent by to {{phoneNumber}}. Please enter the code within.',
      code: 'Code',
      submit: 'Submit',
    },
    AuthPasswordScreen: {
      connectedAs: 'Connect to Koosmik as ',
      notYou: "It's not you ?",
      sendCodeByMail: 'Send me an login code by mail',
      sendCodeBySms: 'Send me a login code by sms',
      password: 'Password',
      submit: 'Continue',
    },
  },
  register: {
    RegisterInfoScreen: {
      title: 'Account creation',
      text: 'No account linked to this number :',
      submit: 'Create my account',
    },
    RegisterPhoneValidationScreen: {
      title: 'Phone confirmation',
      text: 'A sms was sent by to {{phoneNumber}}. Please enter the code within.',
      code: 'Code',
      submit: 'Submit',
    },
    RegisterUserInfoForm: {
      title: 'Account creation — Infos',
      gender: 'Gender',
      genderMale: 'Male',
      genderFemale: 'Female',
      firstName: 'First name',
      lastName: 'Last name',
      countryOfResidence: 'Country of residence',
      nationality: 'Nationality',
      birthday: 'Birthday',
      birthplace: 'Birth place',
      next: 'Next',
    },
    RegisterPasswordInfoForm: {
      title: 'Account creation — Password',
      password: 'Password',
      passwordVerify: 'Confirmation',
      passwordDoNotMatch: 'Passwords does not match',
      submit: 'Submit',
    },
  },
  directPay: {
    UserInfoForm: {
      title: 'Account creation — Infos',
      firstName: 'First name',
      lastName: 'Last name',
      countryOfResidence: 'Country of residence',
      nationality: 'Nationality',
      birthday: 'Birthday',
      next: 'Next',
    },
    CardInfoForm: {
      title: 'Bank card details',
      labelDefault: 'My personal card',
      cardNumber: 'Card number',
      cardCvx: 'Secret code',
      cardExpirationDate: 'Expirate date',
      cardExpirationDatePlaceholder: 'MMYY',
      submit: 'Pay',
    },
  },
  dashboard: {
    HomeScreen: {
      addCard: 'Add a new card',
      thirdParty: 'Pay by card',
      pay: 'Pay',
    },
    PaymentMethodSelect: {
      chooseMethod: 'Choose your payment method',
      myWallet: 'My Wallet',
      myWalletNotEnough: '(Not enough funds)',
      miles: 'Use my Koosmiles',
      card: 'Bank card',
      thirdParty: 'Pay by card',
      tel: 'Pay by FLOOZ',
    },
    PaymentCardSelect: {
      title: 'Which card to use ?',
      addCard: 'New card',
    },
    PaymentRecap: {
      amount: 'Your wallet',
      amountToPay: 'Amount to pay',
      amountLeft: 'Wallet after payment',
      miles: 'Your koosmiles',
      milesEquivalent: 'Koosmiles to pay',
      milesRemaining: 'Koosmiles remaining',
    },
  },
};
