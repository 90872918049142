import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import RootState from '../../../redux/root-state';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import RouteNames from '../../../routes/RouteNames';
import { registerActionSubmitAfri, RegisterActionSubmitAfriPayload } from '../redux/register/register-actions';
import RegisterUserInfoForm, { RegisterUserInfoData } from '../components/RegisterUserInfoForm';
import { registerSelect } from '../redux/register/register-selectors';
import RegisterPasswordInfoForm, { RegisterPasswordInfoData } from '../components/RegisterPasswordInfoForm';
import RegisterState from '../redux/register/register-state';
import { TradeStateNotNull } from '../../base/redux/trade/trade-state';
import { tradeSelect } from '../../base/redux/trade/trade-selectors';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';

type RegisterSubmitAfri = (payload: RegisterActionSubmitAfriPayload) => Promise<{}>;

interface Props extends RouteComponentProps {
  registerSubmitAfri: RegisterSubmitAfri;
  register: RegisterState;
  trade: TradeStateNotNull;
}

interface State {
  step: 'user' | 'password';
  userInfo: RegisterUserInfoData | null;
  passwordInfo: RegisterPasswordInfoData | null;

  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
}

class RegisterAfriScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 'user',
      userInfo: null,
      passwordInfo: null,

      inputErrors: null,
      isProcessing: false,
    };
  }

  onUserInfoSubmit = (userInfo: RegisterUserInfoData) => {
    this.setState({
      userInfo,
      step: 'password',
    });
  };

  onUserBackClick = () => {
    const { history } = this.props;
    history.replace(RouteNames.authPhone);
  };

  onPasswordSubmit = (passwordInfo: RegisterPasswordInfoData) => {
    const { registerSubmitAfri } = this.props;
    const { userInfo, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ passwordInfo, isProcessing: true, inputErrors: null });

    registerSubmitAfri({
      password: passwordInfo.password,
      ...userInfo!,
    }).catch(
      actionRejectHandler(arg => {
        const inputErrors = arg.inputErrors || null;
        this.setState({
          isProcessing: false,
          inputErrors,
        });

        if (inputErrors) {
          if (!userInfo || Object.keys(userInfo).find(k => k in inputErrors)) {
            this.setState({ step: 'user' });
          }
        }
      }),
    );
  };

  onPasswordBackClick = (passwordInfo: RegisterPasswordInfoData) => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ passwordInfo, step: 'user' });
  };

  renderForm() {
    const { trade } = this.props;
    const { step, userInfo, passwordInfo, inputErrors } = this.state;

    if (step === 'user') {
      return (
        <RegisterUserInfoForm
          initialValues={userInfo}
          trade={trade}
          inputErrors={inputErrors}
          onSubmit={this.onUserInfoSubmit}
          onBackClick={this.onUserBackClick}
        />
      );
    }

    const { isProcessing } = this.state;
    return (
      <RegisterPasswordInfoForm
        initialValues={passwordInfo}
        isProcessing={isProcessing}
        inputErrors={inputErrors}
        onSubmit={this.onPasswordSubmit}
        onBackClick={this.onPasswordBackClick}
      />
    );
  }

  render() {
    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="account" />
        {this.renderForm()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  register: registerSelect(state)!,
  trade: tradeSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  registerSubmitAfri: RegisterSubmitAfri;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  registerSubmitAfri: payload =>
    new Promise((resolve, reject) => dispatch(registerActionSubmitAfri(payload, resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterAfriScreen);
