import React from 'react';
import classNames from 'classnames';
import { getAllCountries, trans } from '../../base/tools/translate-tools';
import {
  handleFormSubmitEvent,
  handleInputChangeEvent,
  handleRadioChangeEvent,
  handleSelectChangeEvent,
} from '../../shared/tools/html-events-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import Select, { SelectOption } from '../../shared/components/form/Select';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import { TradeStateNotNull } from '../../base/redux/trade/trade-state';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';

export interface RegisterUserInfoData {
  gender: 'M' | 'F';
  firstName: string;
  lastName: string;
  birthday: string;
  birthplace: string;
  countryOfResidence: string;
  nationality: string;
}

interface Props {
  trade: TradeStateNotNull;
  initialValues: RegisterUserInfoData | null;
  inputErrors: ApiResponseDataInputErrors | null;
  onSubmit: (data: RegisterUserInfoData) => void;
  onBackClick: () => void;
}

interface State extends RegisterUserInfoData {
  // birthday invalid
  birthdayErrors: string[];
}

export default class RegisterUserInfoForm extends React.PureComponent<Props, State> {
  countries: SelectOption[];

  constructor(props: Props) {
    super(props);

    this.countries = Object.entries(getAllCountries()).map(([value, label]) => ({
      value,
      label,
    }));

    let firstName = '';
    let lastName = '';
    let birthday = '';
    let countryOfResidence = 'FR';
    let nationality = 'FR';

    const { trade, initialValues } = this.props;

    if (trade.type === 'payment') {
      const { payerPrefillInfo } = trade.payment;
      if (payerPrefillInfo) {
        if (payerPrefillInfo.firstName) {
          firstName = payerPrefillInfo.firstName;
        }
        if (payerPrefillInfo.lastName) {
          lastName = payerPrefillInfo.lastName;
        }
        if (payerPrefillInfo.birthday) {
          birthday = payerPrefillInfo.birthday;
        }
        if (payerPrefillInfo.countryOfResidence) {
          countryOfResidence = payerPrefillInfo.countryOfResidence;
        }
        if (payerPrefillInfo.nationality) {
          nationality = payerPrefillInfo.nationality;
        }
      }
    }

    this.state = {
      gender: 'M',
      firstName,
      lastName,
      birthday,
      birthplace: '',
      countryOfResidence,
      nationality,
      birthdayErrors: [],
    };

    if (initialValues) {
      this.state = {
        ...this.state,
        ...initialValues,
      };
    }
  }

  handleGender = handleRadioChangeEvent((gender: string) => {
    if (gender === 'F' || gender === 'M') {
      // pass typescript checker
      this.setState({ gender });
    }
  });

  handleFirstName = handleInputChangeEvent((firstName: string) => this.setState({ firstName }));

  handleLastName = handleInputChangeEvent((lastName: string) => this.setState({ lastName }));

  handleBirthday = handleInputChangeEvent((birthday: string) => this.setState({ birthday }));

  handleBirthplace = handleInputChangeEvent((birthplace: string) => this.setState({ birthplace }));

  handleCountryOfResidence = handleSelectChangeEvent((countryOfResidence: string) =>
    this.setState({ countryOfResidence }),
  );

  handleNationality = handleSelectChangeEvent((nationality: string) => this.setState({ nationality }));

  handleSubmit = handleFormSubmitEvent(() => {
    const { gender, firstName, lastName, birthday, birthplace, countryOfResidence, nationality } = this.state;
    if (birthday) {
      const { onSubmit } = this.props;

      onSubmit({
        gender,
        firstName,
        lastName,
        birthday,
        birthplace,
        countryOfResidence,
        nationality,
      });
      this.setState({ birthdayErrors: [] });
    } else {
      this.setState({ birthdayErrors: ['dateInvalidDate'] });
    }
  });

  handleBack = () => {
    const { onBackClick } = this.props;
    onBackClick();
  };

  renderGender = (hasError: boolean) => {
    const { gender } = this.state;

    return (
      <>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            className={classNames('form-check-input', { 'is-invalid': hasError })}
            name="gender"
            id="genderM"
            value="M"
            checked={gender === 'M'}
            onChange={this.handleGender}
          />
          <label className="form-check-label" htmlFor="genderM">
            {trans('register.RegisterUserInfoForm.genderMale')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            className={classNames('form-check-input', { 'is-invalid': hasError })}
            name="gender"
            id="genderF"
            value="F"
            checked={gender === 'F'}
            onChange={this.handleGender}
          />
          <label className="form-check-label" htmlFor="genderF">
            {trans('register.RegisterUserInfoForm.genderFemale')}
          </label>
        </div>
      </>
    );
  };

  renderFirstName = (hasError: boolean) => {
    const { firstName } = this.state;

    return (
      <input
        type="text"
        id="firstName"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={firstName}
        onChange={this.handleFirstName}
        required
      />
    );
  };

  renderLastName = (hasError: boolean) => {
    const { lastName } = this.state;

    return (
      <input
        type="text"
        id="lastName"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={lastName}
        onChange={this.handleLastName}
        required
      />
    );
  };

  renderBirthday = (hasError: boolean) => {
    const { birthday } = this.state;

    return (
      <input
        type="date"
        id="birthday"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={birthday}
        onChange={this.handleBirthday}
        required
      />
    );
  };

  renderBirthplace = (hasError: boolean) => {
    const { birthplace } = this.state;

    return (
      <input
        type="text"
        id="birthplace"
        className={classNames('form-control', { 'is-invalid': hasError })}
        value={birthplace}
        onChange={this.handleBirthplace}
        required
      />
    );
  };

  renderCountryOfResidence = (hasError: boolean) => {
    const { countryOfResidence } = this.state;

    return (
      <Select
        id="countryOfResidence"
        value={countryOfResidence}
        options={this.countries}
        onChange={this.handleCountryOfResidence}
        className={classNames('form-select', { 'is-invalid': hasError })}
      />
    );
  };

  renderNationality = (hasError: boolean) => {
    const { nationality } = this.state;

    return (
      <Select
        id="countryOfResidence"
        value={nationality}
        options={this.countries}
        onChange={this.handleNationality}
        className={classNames('form-select', { 'is-invalid': hasError })}
      />
    );
  };

  render() {
    const { inputErrors } = this.props;
    const { birthdayErrors } = this.state;

    return (
      <>
        <div className="modal-body">
          <h2 className="modal-heading text-center p-2 mb-3">{trans('register.RegisterUserInfoForm.title')}</h2>
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                <form onSubmit={this.handleSubmit} id="register-user-info">
                  <div>
                    <label className="form-label" htmlFor="gender">
                      {trans('register.RegisterUserInfoForm.gender')}
                    </label>
                    <div className="mb-3">
                      <InputWithErrors path="gender" inputErrors={inputErrors} render={this.renderGender} />
                    </div>
                  </div>

                  <div className="row g-3 mb-3">
                    <div className="col12 col-sm-6">
                      <label htmlFor="firstName" className="form-label">
                        {trans('register.RegisterUserInfoForm.firstName')}
                      </label>
                      <InputWithErrors path="firstName" inputErrors={inputErrors} render={this.renderFirstName} />
                    </div>
                    <div className="col12 col-sm-6">
                      <label htmlFor="firstName" className="form-label">
                        {trans('register.RegisterUserInfoForm.lastName')}
                      </label>
                      <InputWithErrors path="lastName" inputErrors={inputErrors} render={this.renderLastName} />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="birthday" className="form-label">
                      {trans('register.RegisterUserInfoForm.birthday')}
                    </label>
                    <InputWithErrors
                      path="birthday"
                      inputErrors={inputErrors}
                      render={this.renderBirthday}
                      customErrors={birthdayErrors}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="birthplace" className="form-label">
                      {trans('register.RegisterUserInfoForm.birthplace')}
                    </label>
                    <InputWithErrors path="birthplace" inputErrors={inputErrors} render={this.renderBirthplace} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="countryOfResidence" className="form-label">
                      {trans('register.RegisterUserInfoForm.countryOfResidence')}
                    </label>
                    <InputWithErrors
                      path="countryOfResidence"
                      inputErrors={inputErrors}
                      render={this.renderCountryOfResidence}
                    />
                  </div>

                  <div className="mb-0">
                    <label htmlFor="nationality" className="form-label">
                      {trans('register.RegisterUserInfoForm.nationality')}
                    </label>
                    <InputWithErrors path="nationality" inputErrors={inputErrors} render={this.renderNationality} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button type="button" className="btn btn-link w-100" onClick={this.handleBack}>
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button type="submit" form="register-user-info" className="btn btn-koosmik w-100">
                  {trans('register.RegisterUserInfoForm.next')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
