import React from 'react';

interface Props {
  title: string;
  message: string;
}

const SuccessEmbedded: React.FC<Props> = ({ title, message }: Props): React.ReactElement => (
  <div className="modal fade show" id="main-modal" tabIndex={-1} aria-labelledby="Embedded">
    <div className="modal-dialog">
      <div className="modal-content success">
        <div className="modal-body">
          <h1>{title}</h1>
          <p>{message}</p>
        </div>
      </div>
    </div>
  </div>
);

export default SuccessEmbedded;
