import RegisterState from './register-state';
import {
  REGISTER_ACTION_CLEAR,
  REGISTER_ACTION_SET_PHONE_NUMBER,
  REGISTER_ACTION_SET_PHONE_NUMBER_ID,
  RegisterActions,
} from './register-actions';

const initialState: RegisterState = {
  phoneNumberId: null,
  country: null,
  value: null,
};

export default function registerReducer(state: RegisterState = initialState, action: RegisterActions): RegisterState {
  switch (action.type) {
    case REGISTER_ACTION_SET_PHONE_NUMBER:
      return {
        ...state,
        ...action.payload,
      };
    case REGISTER_ACTION_SET_PHONE_NUMBER_ID:
      return {
        ...state,
        ...action.payload,
      };
    case REGISTER_ACTION_CLEAR:
      return initialState;
    default:
      return state;
  }
}
