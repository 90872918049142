import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import RootState from '../../../redux/root-state';
import { trans } from '../../base/tools/translate-tools';
import { handleFormSubmitEvent } from '../../shared/tools/html-events-handler';
import { tradeInfoSelect } from '../../base/redux/trade/trade-selectors';
import { TradeInfoData } from '../../base/redux/trade/trade-state';
import { renderCurrency } from '../../base/tools/currency-tools';
import PaymentMethodSelect from '../components/PaymentMethodSelect';
import { noAccountPayActionWithTel, noAccountPayActionWithThirdParty } from '../redux/pay/no-account-pay-actions';
import RouteNames from '../../../routes/RouteNames';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

type PayWithThirdParty = () => Promise<{}>;
type PayWithTel = () => Promise<{}>;

interface Props {
  tradeInfo: TradeInfoData;
  state: RootState;
  payWithThirdParty: PayWithThirdParty;
  payWithTel: PayWithTel;
}

interface State {
  paymentMethod: 'third-party' | 'tel';
  isProcessing: boolean;
}

class NoAccountScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      paymentMethod: 'third-party',
      isProcessing: false,
    };
  }

  handleMethodChange = (paymentMethod: 'third-party' | 'tel') => {
    this.setState({ paymentMethod });
  };

  handleSubmit = handleFormSubmitEvent(() => {
    const { paymentMethod } = this.state;

    if (paymentMethod === 'third-party') {
      const { payWithThirdParty } = this.props;
      this.setState({ isProcessing: true });
      payWithThirdParty().catch(() => {
        this.setState({ isProcessing: false });
      });
      return;
    }

    if (paymentMethod === 'tel') {
      const { payWithTel } = this.props;
      this.setState({ isProcessing: true });
      payWithTel().catch(() => {
        this.setState({ isProcessing: false });
      });
    }
  });

  render() {
    const { paymentMethod, isProcessing } = this.state;
    const { tradeInfo } = this.props;

    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="payment" />
        <div className="modal-body">
          <div className="payment-table">
            <div className="payment-table-row d-flex justify-content-between">
              <div className="th">{trans('dashboard.PaymentRecap.amountToPay')}</div>
              <div className="td negative">{renderCurrency(tradeInfo.amount, tradeInfo.currency)}</div>
            </div>
          </div>

          <PaymentErrorsComponent />

          <form onSubmit={this.handleSubmit} id="no-account-form">
            <div className="container p-0">
              <PaymentMethodSelect
                paymentMethod={paymentMethod}
                onMethodChange={this.handleMethodChange}
                tradeInfo={tradeInfo}
              />
            </div>
          </form>
        </div>

        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <Link className="btn btn-link w-100" to={RouteNames.authPhone}>
                  {trans('global.back')}
                </Link>
              </div>
              <div className="col-7 col-sm-6">
                <button type="submit" className="btn btn-koosmik w-100" disabled={isProcessing} form="no-account-form">
                  <ButtonSpinner showSpinner={isProcessing}>{trans('dashboard.HomeScreen.pay')}</ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  tradeInfo: tradeInfoSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  payWithThirdParty: PayWithThirdParty;
  payWithTel: PayWithTel;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  payWithThirdParty: () =>
    new Promise((resolve, reject) => dispatch(noAccountPayActionWithThirdParty(resolve, reject))),
  payWithTel: () => new Promise((resolve, reject) => dispatch(noAccountPayActionWithTel(resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoAccountScreen);
