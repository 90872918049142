import React, { SelectHTMLAttributes } from 'react';

export interface SelectOption {
  value: string;
  label: string;
}

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[];
}

class Select extends React.PureComponent<Props> {
  render() {
    const { options, ...props } = this.props;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <select {...props}>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    );
  }
}

export default Select;
