import { call, delay, put, select } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import { authActionSetToken } from './auth-actions';
import RouteNames from '../../../../routes/RouteNames';
import { notificationsActionAddDanger } from '../../../base/redux/notifications/notifications-actions';
import { trans, transErrorCode } from '../../../base/tools/translate-tools';
import { doesAreaSupportDirectPay, doesAreaSupportRegister } from '../../../shared/tools/area-tools';
import { TradeInfoData } from '../../../base/redux/trade/trade-state';
import { tradeInfoSelect } from '../../../base/redux/trade/trade-selectors';
import {
  AuthActionDirectCheckEmail,
  AuthActionDirectCheckPhone,
  AuthActionDirectLoginEmail,
  AuthActionDirectLoginPhone,
} from './auth-direct-actions';
import {
  apiAuthDirectCheckEmail,
  ApiAuthDirectCheckResponse,
  apiAuthDirectCheckPhone,
  apiAuthDirectLoginEmail,
  ApiAuthDirectLoginResponse,
  apiAuthDirectLoginPhone,
} from '../../../../api/auth-direct';
import { registerActionSetPhoneNumber } from '../../../register/redux/register/register-actions';

export function* authDirectCheckEmail({ payload, resolve, reject }: AuthActionDirectCheckEmail) {
  try {
    const { success, data, errorCode }: ApiAuthDirectCheckResponse = yield call(apiAuthDirectCheckEmail, {
      ...payload,
    });

    if (success) {
      resolve({});
      yield put(routerActions.replace(RouteNames.authPassword));
      return;
    }

    const { area }: TradeInfoData = yield select(tradeInfoSelect);

    if (errorCode === 'ACCOUNT_NOT_EXISTS') {
      if (doesAreaSupportDirectPay(area)) {
        resolve({});
        yield put(routerActions.replace(RouteNames.directPay));
        return;
      }

      if (doesAreaSupportRegister(area)) {
        yield put(routerActions.replace(RouteNames.authPhone));
        yield delay(500);
        yield put(notificationsActionAddDanger(trans('auth.saga.usePhoneNumberForRegister')));
        reject({
          errorCode: 'REGISTER_NOT_SUPPORTED',
        });
        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode('REGISTER_NOT_SUPPORTED')));
      reject({
        errorCode: 'REGISTER_NOT_SUPPORTED',
      });
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export function* authDirectCheckPhone({ payload, resolve, reject }: AuthActionDirectCheckPhone) {
  try {
    const { success, data, errorCode }: ApiAuthDirectCheckResponse = yield call(apiAuthDirectCheckPhone, {
      phoneCountry: payload.phone.country,
      phoneNumber: payload.phone.number,
    });

    if (success) {
      resolve({});
      yield put(routerActions.replace(RouteNames.authPassword));
      return;
    }

    const { area }: TradeInfoData = yield select(tradeInfoSelect);

    if (errorCode === 'ACCOUNT_NOT_EXISTS') {
      if (doesAreaSupportRegister(area)) {
        resolve({ data: { next: 'register' } });
        yield put(
          registerActionSetPhoneNumber({
            country: payload.phone.country,
            value: payload.phone.number,
          }),
        );
        yield put(routerActions.replace(RouteNames.register));
        return;
      }

      if (doesAreaSupportDirectPay(area)) {
        yield put(routerActions.replace(RouteNames.authEmail));
        yield delay(500);
        yield put(notificationsActionAddDanger(trans('auth.saga.useEmailForRegister')));
        reject({
          errorCode: 'REGISTER_NOT_SUPPORTED',
        });
        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode('REGISTER_NOT_SUPPORTED')));
      reject({
        errorCode: 'REGISTER_NOT_SUPPORTED',
      });
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export function* authDirectLoginEmail({ payload, resolve, reject }: AuthActionDirectLoginEmail) {
  try {
    const { success, data, errorCode }: ApiAuthDirectLoginResponse = yield call(apiAuthDirectLoginEmail, {
      ...payload,
    });

    if (success) {
      resolve({ data });
      yield put(authActionSetToken(data.token));
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export function* authDirectLoginPhone({ payload, resolve, reject }: AuthActionDirectLoginPhone) {
  try {
    const { success, data, errorCode }: ApiAuthDirectLoginResponse = yield call(apiAuthDirectLoginPhone, {
      phoneCountry: payload.phone.country,
      phoneNumber: payload.phone.number,
      password: payload.password,
    });

    if (success) {
      resolve({ data });
      yield put(authActionSetToken(data.token));
      return;
    }

    yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
    reject({
      errorCode,
      inputErrors: data.inputErrors,
    });
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}
