import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RootState from '../../../redux/root-state';
import { UserStateNotNull } from '../../auth/redux/user/user-state';
import CardsState from '../../auth/redux/cards/cards-state';
import { trans } from '../../base/tools/translate-tools';
import { userSelect } from '../../auth/redux/user/user-selectors';
import { cardsSelect } from '../../auth/redux/cards/cards-selectors';
import CardForm, { CardFormData } from '../components/CardForm';
import { handleFormSubmitEvent } from '../../shared/tools/html-events-handler';
import PaymentMethodSelect from '../components/PaymentMethodSelect';
import PaymentRecap from '../components/PaymentRecap';
import {
  payActionWithCard,
  PayActionWithCardPayload,
  payActionWithMiles,
  payActionWithTel,
  payActionWithThirdParty,
  payActionWithWallet,
} from '../redux/pay/pay-actions';
import {
  cardsActionAdd,
  CardsActionAddPayload,
  CardsActionAddResolvePayload,
} from '../../auth/redux/cards/cards-actions';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import { doesAreaSupportCard } from '../../shared/tools/area-tools';
import { tradeInfoSelect } from '../../base/redux/trade/trade-selectors';
import { TradeInfoData } from '../../base/redux/trade/trade-state';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import PaymentCardSelect from '../components/PaymentCardSelect';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

type PayWithWallet = () => Promise<{}>;
type PayWithMiles = () => Promise<{}>;
type PayWithCard = (payload: PayActionWithCardPayload) => Promise<{}>;
type PayWithThirdParty = () => Promise<{}>;
type PayWithTel = () => Promise<{}>;
type CardsAdd = (payload: CardsActionAddPayload) => Promise<CardsActionAddResolvePayload>;

interface Props {
  user: UserStateNotNull;
  cards: CardsState;
  tradeInfo: TradeInfoData;
  state: RootState;
  payWithWallet: PayWithWallet;
  payWithMiles: PayWithMiles;
  payWithCard: PayWithCard;
  payWithThirdParty: PayWithThirdParty;
  payWithTel: PayWithTel;
  cardsAdd: CardsAdd;
}

type PaymentMethod = 'koosmik' | 'miles' | 'card' | 'third-party' | 'tel';
type CardId = 'new-card' | string | null;

interface State {
  step: 'main' | 'card';
  paymentMethod: PaymentMethod;
  cardId: CardId;
  isProcessing: boolean;
  cardFormData: CardFormData | null;
  addCardInputErrors: ApiResponseDataInputErrors | null;
}

class HomeScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user, tradeInfo, cards } = props;

    let paymentMethod: PaymentMethod = doesAreaSupportCard(tradeInfo.area) ? 'card' : 'third-party';
    const walletAmount = user.wallets[tradeInfo.currency] || 0;
    if (tradeInfo.amount < walletAmount) {
      paymentMethod = 'koosmik';
    }

    let cardId = 'new-card';
    if (cards.length > 0) {
      cardId = cards[0].id;
    }

    this.state = {
      paymentMethod,
      cardId,
      step: 'main',
      isProcessing: false,
      cardFormData: null,
      addCardInputErrors: null,
    };
  }

  handleMethodChange = (paymentMethod: PaymentMethod) => {
    this.setState({ paymentMethod });
  };

  handleCardChange = (cardId: CardId) => {
    this.setState({ cardId });
  };

  handleSubmit = handleFormSubmitEvent(() => {
    const { tradeInfo } = this.props;
    const { step, paymentMethod, cardId, cardFormData } = this.state;

    if (paymentMethod === 'koosmik') {
      const { payWithWallet } = this.props;
      this.setState({ isProcessing: true, addCardInputErrors: null });
      payWithWallet().catch(() => {
        this.setState({ isProcessing: false });
      });
      return;
    }
    if (paymentMethod === 'miles') {
      const { payWithMiles } = this.props;
      this.setState({ isProcessing: true, addCardInputErrors: null });
      payWithMiles().catch(() => {
        this.setState({ isProcessing: false });
      });
      return;
    }

    if (paymentMethod === 'third-party') {
      const { payWithThirdParty } = this.props;
      this.setState({ isProcessing: true, addCardInputErrors: null });
      payWithThirdParty().catch(() => {
        this.setState({ isProcessing: false });
      });
      return;
    }

    if (paymentMethod === 'tel') {
      const { payWithTel } = this.props;
      this.setState({ isProcessing: true, addCardInputErrors: null });
      payWithTel().catch(() => {
        this.setState({ isProcessing: false });
      });
      return;
    }

    if (paymentMethod === 'card') {
      if (step === 'main') {
        this.setState({ step: 'card' });
        return;
      }

      if (step === 'card') {
        if (cardId === 'new-card') {
          if (!cardFormData) {
            return;
          }
          const { payWithCard, cardsAdd } = this.props;
          this.setState({ isProcessing: true, addCardInputErrors: null });
          cardsAdd({
            ...cardFormData,
            currency: tradeInfo.currency,
          })
            .then(({ data }) => {
              payWithCard({ card: data.id, cardPayInAmount: tradeInfo.amount }).catch(() => {
                this.setState({ isProcessing: false });
              });
            })
            .catch(
              actionRejectHandler(e => {
                this.setState({
                  addCardInputErrors: e.inputErrors || null,
                  isProcessing: false,
                });
              }),
            );

          return;
        }

        if (cardId) {
          const { payWithCard } = this.props;
          this.setState({ isProcessing: true, addCardInputErrors: null });
          payWithCard({ card: cardId, cardPayInAmount: tradeInfo.amount }).catch(() => {
            this.setState({ isProcessing: false });
          });
        }
      }
    }
  });

  onNewCardChange = (cardFormData: CardFormData) => {
    this.setState({ cardFormData });
  };

  handleBackClick = () => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ step: 'main' });
  };

  renderFormContent() {
    const { step, paymentMethod, cardId, isProcessing, addCardInputErrors } = this.state;
    const { user, tradeInfo, cards } = this.props;

    if (step === 'card') {
      return (
        <PaymentCardSelect cardId={cardId} cards={cards} onCardChange={this.handleCardChange}>
          <CardForm isProcessing={isProcessing} inputErrors={addCardInputErrors} onChange={this.onNewCardChange} />
        </PaymentCardSelect>
      );
    }

    return (
      <PaymentMethodSelect
        paymentMethod={paymentMethod}
        onMethodChange={this.handleMethodChange}
        user={user}
        tradeInfo={tradeInfo}
      />
    );
  }

  render() {
    const { step, paymentMethod, isProcessing } = this.state;
    const { user, tradeInfo } = this.props;

    let back = null;
    if (step !== 'main') {
      back = (
        <button type="button" className="btn btn-link w-100" onClick={this.handleBackClick} disabled={isProcessing}>
          {trans('global.back')}
        </button>
      );
    }

    return (
      <>
        <PaymentCartComponent user={user} />
        <PaymentStepsComponent step="payment" />
        <div className="modal-body">
          <PaymentRecap paymentMethod={paymentMethod} user={user} tradeInfo={tradeInfo} />
          <PaymentErrorsComponent />
          <form onSubmit={this.handleSubmit} id="home-screen-form">
            <div className="container p-0">{this.renderFormContent()}</div>
          </form>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">{back}</div>
              <div className="col-7 col-sm-6">
                <button type="submit" className="btn btn-koosmik w-100" disabled={isProcessing} form="home-screen-form">
                  <ButtonSpinner showSpinner={isProcessing}>{trans('dashboard.HomeScreen.pay')}</ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: userSelect(state)!,
  cards: cardsSelect(state),
  tradeInfo: tradeInfoSelect(state)!,
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  payWithWallet: PayWithWallet;
  payWithMiles: PayWithMiles;
  payWithCard: PayWithCard;
  payWithThirdParty: PayWithThirdParty;
  payWithTel: PayWithTel;
  cardsAdd: CardsAdd;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  payWithWallet: () => new Promise((resolve, reject) => dispatch(payActionWithWallet(resolve, reject))),
  payWithMiles: () => new Promise((resolve, reject) => dispatch(payActionWithMiles(resolve, reject))),
  payWithCard: payload => new Promise((resolve, reject) => dispatch(payActionWithCard(payload, resolve, reject))),
  payWithThirdParty: () => new Promise((resolve, reject) => dispatch(payActionWithThirdParty(resolve, reject))),
  payWithTel: () => new Promise((resolve, reject) => dispatch(payActionWithTel(resolve, reject))),
  cardsAdd: payload => new Promise((resolve, reject) => dispatch(cardsActionAdd(payload, resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeScreen);
