import CardsState from './cards-state';
import { CARDS_ACTION_CLEAR, CARDS_ACTION_SET, CardsActions } from './cards-actions';

const initialState: CardsState = [];

export default function cardsReducer(state: CardsState = initialState, action: CardsActions): CardsState {
  switch (action.type) {
    case CARDS_ACTION_SET:
      return action.payload;
    case CARDS_ACTION_CLEAR:
      return initialState;
    default:
      return state;
  }
}
