export const CURRENCY_EUR = 'EUR';
export const CURRENCY_XOF = 'XOF';

type TYPE_CURRENCY_EUR = typeof CURRENCY_EUR;
type TYPE_CURRENCY_XOF = typeof CURRENCY_XOF;
type TYPE_CURRENCY = TYPE_CURRENCY_EUR | TYPE_CURRENCY_XOF;

export const ALL_CURRENCIES: TYPE_CURRENCY[] = [CURRENCY_EUR, CURRENCY_XOF];

/**
 * Convert a currency value in unit to cent ( example : 10.00 => 1000 )
 */
export const convertUnitToCent = (unit: string): number => {
  return Math.round(parseFloat(unit) * 100);
};

/**
 * Convert a currency value in cent to unit (example : 1000 => 10.00 )
 */
export const convertCentToUnit = (cent: number): string => {
  const unit = cent / 100;
  return unit.toFixed(2);
};

/**
 * Render an amount with currency
 */
export const renderCurrency = (amount: number, currency: string): string => {
  return `${convertCentToUnit(amount)} ${currency}`;
};
