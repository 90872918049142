/**
 * Check if we are in a iframe
 */
export const isInIframe = (): boolean => window.self !== window.parent;

/**
 * Check if the app is running in a valid domain
 */
export const isDomainValid = (): boolean => {
  if (process.env.REACT_APP_ENV === 'staging') {
    return document.location.hostname.endsWith('.koosmiklab.com');
  }
  return document.location.hostname.endsWith('.koosmik.com');
};
