import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteNames from './RouteNames';
import RedirectSuccessScreen from '../modules/base/screens/RedirectSuccessScreen';
import RedirectFailureScreen from '../modules/base/screens/RedirectFailureScreen';
import Redirect3dSecureScreen from '../modules/base/screens/Redirect3dSecureScreen';
import Redirect3rdPartyScreen from '../modules/base/screens/Redirect3rdPartyScreen';
import PaymentModalComponent from '../modules/base/components/PaymentModalComponent';
import AppLoadingIndicator from '../modules/base/components/AppLoadingIndicator';

export default class RootRoutesComponent extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={RouteNames.none}>
          <AppLoadingIndicator />
        </Route>
        <Route exact path={RouteNames.redirectSuccess} component={RedirectSuccessScreen} />
        <Route exact path={RouteNames.redirectFailure} component={RedirectFailureScreen} />
        <Route exact path={RouteNames.redirect3dSecure} component={Redirect3dSecureScreen} />
        <Route exact path={RouteNames.redirect3rdParty} component={Redirect3rdPartyScreen} />
        <Route component={PaymentModalComponent} />
      </Switch>
    );
  }
}
