import { call, delay, put, select, takeLeading } from 'redux-saga/effects';
import { routerActions } from 'connected-react-router';
import { DIRECT_PAY_ACTION_SUBMIT, DirectPayActionSubmit } from './direct-pay-actions';
import {
  apiDirectPay,
  ApiDirectPayResponse,
  apiRequestDirectPay,
  ApiRequestDirectPayResponse,
} from '../../../../api/direct-pay';
import { notificationsActionAddDanger } from '../../../base/redux/notifications/notifications-actions';
import { transErrorCode } from '../../../base/tools/translate-tools';
import { messagingService } from '../../../base/services';
import RouteNames from '../../../../routes/RouteNames';
import { tradeSelect } from '../../../base/redux/trade/trade-selectors';
import { TradeStateNotNull } from '../../../base/redux/trade/trade-state';

function* directPaySubmit({ payload, resolve, reject }: DirectPayActionSubmit) {
  try {
    const trade: TradeStateNotNull = yield select(tradeSelect);
    if (trade.type === 'payment') {
      // type is payment
      const { success, data, errorCode }: ApiDirectPayResponse = yield call(apiDirectPay, {
        paymentId: trade.payment.id,
        ...payload,
      });
      if (success) {
        resolve({});

        if (data.successRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectSuccess));
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        if (data.cancelRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.cancelRedirectUrl, 'merchant');
          return;
        }

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3dSecure));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3Dsecure');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
        inputErrors: data.errors,
      });
    } else {
      // type is request
      const { success, data, errorCode }: ApiRequestDirectPayResponse = yield call(apiRequestDirectPay, {
        requestId: trade.request.id,
        ...payload,
      });
      if (success) {
        resolve({});

        if (data.successRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectSuccess));
          yield delay(3000);
          messagingService.notifyRedirect(data.successRedirectUrl, 'merchant');
          return;
        }

        if (data.failureRedirectUrl) {
          yield put(routerActions.replace(RouteNames.redirectFailure));
          yield delay(3000);
          messagingService.notifyRedirect(data.failureRedirectUrl, 'merchant');
          return;
        }

        if (data.redirectUrl) {
          yield put(routerActions.replace(RouteNames.redirect3dSecure));
          yield delay(3000);
          messagingService.notifyRedirect(data.redirectUrl, '3Dsecure');
          return;
        }

        return;
      }

      yield put(notificationsActionAddDanger(transErrorCode(errorCode)));
      reject({
        errorCode,
        inputErrors: data.errors,
      });
    }
  } catch (e) {
    yield put(notificationsActionAddDanger(e.message));
    reject(e);
  }
}

export default function* directPaySaga() {
  yield takeLeading(DIRECT_PAY_ACTION_SUBMIT, directPaySubmit);
}
