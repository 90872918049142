import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import initReduxStore from './config/init-redux-store';
import SecurityGuardComponent from './modules/base/components/SecurityGuardComponent';
import RootRoutesComponent from './routes/RootRoutesComponent';
import initI18nNext from './config/init-i18n-next';
import AppInputGateComponent from './modules/base/components/AppInputGateComponent';
import history from './config/history';

const { store } = initReduxStore();
initI18nNext();

const App: React.FC = (): React.ReactElement => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <SecurityGuardComponent>
        <AppInputGateComponent>
          <RootRoutesComponent />
        </AppInputGateComponent>
      </SecurityGuardComponent>
    </ConnectedRouter>
  </Provider>
);

export default App;
