import { ActionRejectDefaultArgument } from './action-promise-types';
import { transErrorCode } from './translate-tools';

/**
 * Expect an api call error, and call the callback with a string message
 */
export function actionRejectHandlerSimple<T extends ActionRejectDefaultArgument>(callback: (msg: string) => void) {
  return (rejectArgument: T) => {
    const { error, errorCode } = rejectArgument;
    if (errorCode) {
      callback(transErrorCode(errorCode));
    } else if (error) {
      callback(error.message);
    }
  };
}

/**
 * Just an adapter for catch because it use reason: any
 */
export function actionRejectHandler<T extends ActionRejectDefaultArgument>(callback: (rejectArgument: T) => void) {
  return callback;
}
