// Types

import { ActionReject, ActionResolve } from '../../../base/tools/action-promise-types';

export const PAY_ACTION_WITH_WALLET = 'PAY_ACTION_WITH_WALLET';
export const PAY_ACTION_WITH_MILES = 'PAY_ACTION_WITH_MILES';
export const PAY_ACTION_WITH_CARD = 'PAY_ACTION_WITH_CARD';
export const PAY_ACTION_WITH_THIRD_PARTY = 'PAY_ACTION_WITH_THIRD_PARTY';
export const PAY_ACTION_WITH_TEL = 'PAY_ACTION_WITH_TEL';

// Actions creators definitions

// -- Pay

export interface PayActionWithWallet {
  type: typeof PAY_ACTION_WITH_WALLET;
  resolve: ActionResolve;
  reject: ActionReject;
}
// -- Pay with miles

export interface PayActionWithMiles {
  type: typeof PAY_ACTION_WITH_MILES;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- Pay with card

export interface PayActionWithCardPayload {
  card: string;
  cardPayInAmount: number;
}

export interface PayActionWithCard {
  type: typeof PAY_ACTION_WITH_CARD;
  payload: PayActionWithCardPayload;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- Pay with third party

export interface PayActionWithThirdParty {
  type: typeof PAY_ACTION_WITH_THIRD_PARTY;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- Pay with tel

export interface PayActionWithTel {
  type: typeof PAY_ACTION_WITH_TEL;
  resolve: ActionResolve;
  reject: ActionReject;
}

// -- enum

export type PayActions =
  | PayActionWithWallet
  | PayActionWithMiles
  | PayActionWithCard
  | PayActionWithThirdParty
  | PayActionWithTel;

// Action creators

export const payActionWithWallet = (resolve: ActionResolve, reject: ActionReject): PayActionWithWallet => ({
  type: PAY_ACTION_WITH_WALLET,
  resolve,
  reject,
});

export const payActionWithMiles = (resolve: ActionResolve, reject: ActionReject): PayActionWithMiles => ({
  type: PAY_ACTION_WITH_MILES,
  resolve,
  reject,
});

export const payActionWithCard = (
  payload: PayActionWithCardPayload,
  resolve: ActionResolve,
  reject: ActionReject,
): PayActionWithCard => ({
  type: PAY_ACTION_WITH_CARD,
  payload,
  resolve,
  reject,
});

export const payActionWithThirdParty = (resolve: ActionResolve, reject: ActionReject): PayActionWithThirdParty => ({
  type: PAY_ACTION_WITH_THIRD_PARTY,
  resolve,
  reject,
});

export const payActionWithTel = (resolve: ActionResolve, reject: ActionReject): PayActionWithTel => ({
  type: PAY_ACTION_WITH_TEL,
  resolve,
  reject,
});
