import translation from './translation-fr';
import errorCode from './error-code-fr';
import formError from './form-error-fr';
import country from './country-fr';

export default {
  translation,
  errorCode,
  country,
  formError,
};
