import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { TradeInfoData } from '../redux/trade/trade-state';
import RootState from '../../../redux/root-state';
import { tradeInfoSelect } from '../redux/trade/trade-selectors';
import { renderCurrency } from '../tools/currency-tools';
import { trans } from '../tools/translate-tools';
import { UserStateNotNull } from '../../auth/redux/user/user-state';

interface Props {
  tradeInfo: TradeInfoData;
  user?: UserStateNotNull;
}

const PaymentCartComponent = (props: Props) => {
  const { tradeInfo, user } = props;

  if (user) {
    return (
      <div className="modal-user-cart d-flex justify-content-between">
        <div className="user">
          <FontAwesomeIcon icon={faUserCircle} />
          <span className="ml-text">{user.displayName}</span>
        </div>
        <div className="amount-wrapper d-sm-flex align-items-center justify-content-end text-end">
          <div className="label">{trans('base.PaymentCartComponent.amountToPay')}</div>
          <div className="amount">{renderCurrency(tradeInfo.amount, tradeInfo.currency)}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-cart">
      <div className="label">{trans('base.PaymentCartComponent.amountToPay')}</div>
      <div className="amount">{renderCurrency(tradeInfo.amount, tradeInfo.currency)}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  tradeInfo: tradeInfoSelect(state)!,
});

export default connect(mapStateToProps)(PaymentCartComponent);
