import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ReactCodeInput, { InputModeTypes } from 'react-code-input';
import { ApiResponseDataInputErrors } from '../../../api/api-typed';
import { handleFormSubmitEvent } from '../../shared/tools/html-events-handler';
import { trans } from '../../base/tools/translate-tools';
import {
  authActionLoginEmailValidate,
  AuthActionLoginValidatePayload,
  AuthActionLoginValidateResolvePayload,
} from '../redux/auth/auth-actions';
import { actionRejectHandler } from '../../base/tools/action-promise-handler';
import AuthState from '../redux/auth/auth-state';
import RootState from '../../../redux/root-state';
import { authSelect } from '../redux/auth/auth-selectors';
import InputWithErrors from '../../shared/components/form/InputWithErrors';
import RouteNames from '../../../routes/RouteNames';
import { renderPhoneNumber } from '../../shared/tools/phone-tools';
import PaymentCartComponent from '../../base/components/PaymentCartComponent';
import PaymentStepsComponent from '../../base/components/PaymentStepsComponent';
import PaymentErrorsComponent from '../../base/components/PaymentErrorsComponent';
import ButtonSpinner from '../../shared/components/form/ButtonSpinner';

const codeInputStyle: React.CSSProperties = {
  fontFamily: 'monospace',
  MozAppearance: 'textfield',
  borderRadius: '6px',
  border: '1px solid',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
  margin: '4px',
  paddingLeft: '6px',
  paddingRight: 0,
  paddingTop: 0,
  width: '36px',
  height: '44px',
  fontSize: '32px',
  boxSizing: 'border-box',
};

type AuthLoginValidate = (payload: AuthActionLoginValidatePayload) => Promise<AuthActionLoginValidateResolvePayload>;

interface Props extends RouteComponentProps {
  authLoginValidate: AuthLoginValidate;
  auth: AuthState;
}

interface State {
  // form
  code: string;
  // for code input refresh and check
  key: number;
  codeInvalid: boolean;
  // error
  inputErrors: ApiResponseDataInputErrors | null;
  // processing
  isProcessing: boolean;
  // too many error
  tooManyErrors: boolean;
}

class AuthValidationScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      code: '',
      key: 1,
      codeInvalid: false,
      inputErrors: null,
      isProcessing: false,
      tooManyErrors: false,
    };
  }

  handleCode = (code: string) => {
    let codeInvalid = true;

    const { auth } = this.props;
    if (auth.validation) {
      if (auth.validation.sendBy === 'sms') {
        codeInvalid = code.replace(/[^0-9]/g, '').length < 6;
      } else {
        codeInvalid = code.replace(/[^A-Z0-9]/g, '').length < 8;
      }
    }

    this.setState({
      code,
      codeInvalid,
    });
  };

  handleSubmit = handleFormSubmitEvent(() => {
    const { authLoginValidate } = this.props;
    const { code, key, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    this.setState({ isProcessing: true, inputErrors: null });

    authLoginValidate({ code }).catch(
      actionRejectHandler(arg => {
        this.setState({
          isProcessing: false,
          inputErrors: arg.inputErrors || null,
          code: '',
          key: key + 1,
        });

        if (arg.errorCode === 'LOGIN_CODE_TOO_MANY_ERRORS') {
          this.setState({ tooManyErrors: true });
        }
      }),
    );
  });

  handleBackClick = () => {
    const { isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    const { auth, history } = this.props;
    if (auth.type === 'email') {
      history.replace(RouteNames.authEmail);
    } else {
      history.replace(RouteNames.authPhone);
    }
  };

  renderCode = (hasError: boolean) => {
    const { code, key } = this.state;
    const { auth } = this.props;

    if (!auth.validation) {
      return null;
    }

    let inputMode: InputModeTypes = 'numeric';
    let pattern = '[0-9]*';
    let fields = 6;

    if (auth.validation.sendBy === 'mail') {
      inputMode = 'latin';
      pattern = '[A-Z0-9]*';
      fields = 8;
    }

    return (
      <ReactCodeInput
        fields={fields}
        inputMode={inputMode}
        name="code"
        pattern={pattern}
        isValid={!hasError}
        onChange={this.handleCode}
        key={key}
        value={code}
        inputStyle={codeInputStyle}
      />
    );
  };

  renderContent() {
    const { tooManyErrors } = this.state;
    if (tooManyErrors) {
      return null;
    }

    const { inputErrors } = this.state;
    const { auth } = this.props;

    let text = '';
    if (auth.validation) {
      if (auth.validation.sendBy === 'mail') {
        text = trans('auth.AuthValidationScreen.sendByMail', {
          email: auth.validation.mail,
        });
      } else {
        const { phoneNumber } = auth.validation;
        text = trans('auth.AuthValidationScreen.sendBySms', {
          phoneNumber: renderPhoneNumber(phoneNumber.countryCode, phoneNumber.obstructedValue),
        });
      }
    }

    return (
      <div>
        <div className="d-flex flex-column align-items-center help-text mb-3">{text}</div>

        <form className="content-area" id="auth-validation" onSubmit={this.handleSubmit}>
          <fieldset className="number-code">
            <legend>{trans('auth.AuthValidationScreen.code')}</legend>
            <div className="my-1 d-flex flex-wrap">
              <InputWithErrors path="code" inputErrors={inputErrors} render={this.renderCode} />
            </div>
          </fieldset>
        </form>
      </div>
    );
  }

  render() {
    const { auth } = this.props;
    const { isProcessing, tooManyErrors, codeInvalid } = this.state;

    const title =
      auth.type === 'email'
        ? trans('auth.AuthValidationScreen.emailTitle')
        : trans('auth.AuthValidationScreen.phoneTitle');

    return (
      <>
        <PaymentCartComponent />
        <PaymentStepsComponent step="account" />

        <div className="modal-body">
          <h2 className="modal-heading text-center p-2 mb-3">{title}</h2>
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12">
                <PaymentErrorsComponent />
                {this.renderContent()}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2 p-sm-3">
          <div className="container p-0">
            <div className="row g-0 g-sm-3">
              <div className="col-5 col-sm-6">
                <button type="button" className="btn btn-link w-100" onClick={this.handleBackClick}>
                  {trans('global.back')}
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button
                  type="submit"
                  className="btn btn-koosmik w-100"
                  disabled={isProcessing || tooManyErrors || codeInvalid}
                  form="auth-validation"
                >
                  <ButtonSpinner showSpinner={isProcessing}>{trans('auth.AuthValidationScreen.submit')}</ButtonSpinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: authSelect(state),
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  authLoginValidate: AuthLoginValidate;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  authLoginValidate: payload =>
    new Promise((resolve, reject) => dispatch(authActionLoginEmailValidate(payload, resolve, reject))),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthValidationScreen);
