import React from 'react';
import ErrorEmbedded from '../components/ErrorEmbedded';
import { trans } from '../tools/translate-tools';

export default class RedirectFailureScreen extends React.PureComponent {
  render() {
    return (
      <ErrorEmbedded
        title={trans('base.RedirectFailureScreen.title')}
        message={trans('base.RedirectFailureScreen.message')}
      />
    );
  }
}
