import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import RootState from '../../../redux/root-state';
import { appInputActionClear } from '../redux/app-input/app-input-actions';
import { notificationsSelect } from '../redux/notifications/notifications-selectors';
import NotificationsState from '../redux/notifications/notifications-state';
import { tradeInfoSelect } from '../redux/trade/trade-selectors';
import { TradeInfoData } from '../redux/trade/trade-state';
import { AppInputStateNotNull } from '../redux/app-input/app-input-state';
import { appInputSelect } from '../redux/app-input/app-input-selectors';
import closeSvg from '../../../assets/images/icon-close.svg';
import koosmikPayImage from '../../../assets/images/koosmik-pay.png';
import shopSvg from '../../../assets/images/shop.svg';
import PaymentRoutesComponent from '../../../routes/PaymentRoutesComponent';

type AppInputClear = () => void;

interface Props extends RouteComponentProps {
  appInputState: AppInputStateNotNull;
  appInputClear: AppInputClear;
  tradeInfo: TradeInfoData;
  notifications: NotificationsState;
}

class PaymentModalComponent extends React.PureComponent<Props> {
  onClose = () => {
    const { appInputClear } = this.props;
    appInputClear();
  };

  renderMerchant() {
    const { tradeInfo } = this.props;

    let img = <img src={shopSvg} alt="Merchant logo" />;
    if (tradeInfo.otherMember.cover) {
      img = <img src={tradeInfo.otherMember.cover.small} alt="Merchant name" />;
    }

    return (
      <div className="header-merchant">
        {img}
        <span className="merchant-name">{tradeInfo.otherMember.displayName}</span>
      </div>
    );
  }

  renderClose() {
    const { appInputState } = this.props;

    if (appInputState.options.noClose) {
      return null;
    }

    return (
      <button type="button" className="btn-dismiss" data-bs-dismiss="modal" aria-label="Close" onClick={this.onClose}>
        <img src={closeSvg} alt="Close" />
      </button>
    );
  }

  render() {
    return (
      <div className="modal fade show" id="main-modal" tabIndex={-1} aria-labelledby="Embedded">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-start">
              <h5 className="modal-title" id="modalLabel">
                <img src={koosmikPayImage} alt="Koosmik Pay" />
              </h5>
              {this.renderMerchant()}
              {this.renderClose()}
            </div>
            <PaymentRoutesComponent />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  appInputState: appInputSelect(state)!,
  tradeInfo: tradeInfoSelect(state)!,
  notifications: notificationsSelect(state),
});

type MapDispatchToProps = (
  dispatch: Dispatch,
) => {
  appInputClear: AppInputClear;
};
const mapDispatchToProps: MapDispatchToProps = dispatch => ({
  appInputClear: () => dispatch(appInputActionClear()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentModalComponent);
