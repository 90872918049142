import { NotificationState } from './notifications-state';

// Action Types

export const NOTIFICATIONS_ACTION_ADD = 'NOTIFICATIONS_ACTION_ADD';
export const NOTIFICATIONS_ACTION_CLEAR = 'NOTIFICATIONS_ACTION_CLEAR';

// Action creators definitions

export interface NotificationsActionAdd {
  type: typeof NOTIFICATIONS_ACTION_ADD;
  payload: NotificationState;
}

export interface NotificationsActionClear {
  type: typeof NOTIFICATIONS_ACTION_CLEAR;
}

export type NotificationsActions = NotificationsActionAdd | NotificationsActionClear;

// Action creators

export const notificationsActionAdd = (payload: NotificationState): NotificationsActionAdd => ({
  type: NOTIFICATIONS_ACTION_ADD,
  payload,
});

export const notificationsActionAddDanger = (message: string) =>
  notificationsActionAdd({
    type: 'danger',
    message,
  });

export const notificationsActionAddWarning = (message: string) =>
  notificationsActionAdd({
    type: 'warning',
    message,
  });

export const notificationsActionClear = (): NotificationsActionClear => ({
  type: NOTIFICATIONS_ACTION_CLEAR,
});
