const format = (number: string) => number.replace(/\s/g, '');

export function isCardNumberVisa(number: string) {
  return /^4[0-9]{12}(?:[0-9]{3})?$/.test(format(number));
}

export function isCardNumberLikeVisa(number: string) {
  return /^4[0-9]{3}/.test(format(number));
}

export function isCardNumberMastercard(number: string) {
  return /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(format(number));
}

export function isCardNumberLikeMastercard(number: string) {
  return /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)/.test(format(number));
}
